<app-info-banner></app-info-banner>
<div class="container-fluid bg1">
    <div class="container bg2 py-5 px-0 px-lg-5">
        <div class="row mt-2">
            <div class="col-12">
                <p class="text-justify mt-2">
                    O Clube Desportivo de Tondela é a entidade titular do website <a
                        href="https://www.cdtondela.pt">cdtondela.pt</a>. Nesse sentido, o Clube Desportivo de Tondela
                    tem a preocupação de garantir aos Utilizadores do website o integral cumprimento de todas as
                    obrigações legais como também a total privacidade dos seus dados pessoais e a segurança das
                    transações efetuadas através da Loja Online.
                </p>
            </div>

        </div>
        <div class="row mt-2">
            <div class="col-12">

                <p class="text-justify mt-2">
                    Para os devidos e legais efeitos, pode encontrar a identificação completa e contactos da entidade
                    Responsável pelo Tratamento de dados pessoais realizado através do website:
                </p>
                <ul>
                    <li class="text-justify">Denominação: Clube Desportivo de Tondela
                    </li>
                    <li class="text-justify">Número de Identificação de Pessoa Coletiva / Registo Comercial: 505 212
                        722;
                    </li>
                    <li class="text-justify">Sede e endereço postal: Complexo Desportivo Estádio João Cardoso, Rua Dr.
                        Eurico José Gouveia, n.º 365, 3460-580 Tondela
                    </li>
                    <li class="text-justify">Endereço eletrónico: geral@cdtondela.pt
                    </li>
                    <li class="text-justify">Contacto telefónico: (+351) 232 821 447*<br>
                        *(Chamada para a rede fixa nacional)
                    </li>
                    <li class="text-justify">Contacto do Encarregado da Proteção de Dados: [...].
                    </li>
                </ul>
                <p class="text-justify">O tratamento de dados pessoais através do website será feito nos seguintes termos:</p>
            </div>

        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3> RESPONSÁVEL PELO TRATAMENTO DE DADOS PESSOAIS<span></span></h3>
                <p class="text-justify mt-2">
                    O Clube Desportivo de Tondela é a entidade Responsável pelo Tratamento dos dados pessoais fornecidos
                    pelos utilizadores do website.
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3 class="uppercase mt-2">Finalidades e fundamentos jurídicos do tratamento dos dados
                    pessoais<span></span></h3>
                    <p class="text-justify">
                        Consoante as funcionalidades do website que utilizar os seus dados pessoais
                        poderão ser tratados para diferentes
                        finalidades. Em seguida indicamos essas finalidades e quais os fundamentos jurídicos aplicáveis:
                    </p>
                <ul>
                    <li class="text-justify">
                        Caso ainda não seja Cliente da nossa Loja Online, para podermos enviar-lhe comunicações
                        comerciais através de email,
                        correio e SMS relacionadas com a nossa atividade terá que nos fornecer o seu consentimento para
                        tal. Conforme referido,
                        neste caso o fundamento jurídico aplicável ao tratamento dos seus dados pessoais será o seu
                        consentimento. Este
                        consentimento poderá ser revogado a qualquer momento, sem que tal, no entanto, torne ilícito o
                        tratamento de dados
                        pessoais realizado com base nesse consentimento até à data em que o mesmo seja revogado. Neste
                        caso, a não prestação do
                        seu consentimento irá impedir-nos de lhe enviar comunicações comerciais relacionadas com a nossa
                        atividade.</li>
                    <li class="text-justify">Caso já seja Cliente da nossa Loja Online, pretendemos mantê-lo atualizado
                        a todo o tempo quanto às novidades relacionadas com os nossos produtos e serviços. Assim, a não
                        ser que se oponha a tal, iremos enviar-lhe comunicações comerciais através de email, correio e
                        SMS relacionadas com os nossos produtos e serviços. O fundamento jurídico aplicável ao
                        tratamento dos seus dados pessoais é o nosso interesse legítimo em mantê-lo atualizado quanto às
                        últimas novidades dos nossos produtos e serviços e neste sentido poder fornecer-lhe um serviço
                        de qualidade.</li>
                </ul>
                <p class="text-justify mt-2">
                    No âmbito do funcionamento da Loja Online, por forma podermos fornecer-lhe os nossos produtos e
                    serviços, iremos tratar os seus dados para as seguintes finalidades:
                </p>
                <ul>
                    <li class="text-justify">Execução do contrato de compra-venda celebrado com o titular dos dados;
                    </li>
                    <li class="text-justify">Faturação;</li>
                    <li class="text-justify">Processamento de pagamentos.</li>
                    <li class="text-justify">Envio de encomendas.</li>
                </ul>
                <p class="text-justify">
                    Relativamente às finalidades aqui referidas, o fundamento jurídico aplicável ao tratamento dos seus
dados pessoais é a execução de um contrato no qual o titular dos dados é parte e o cumprimento
de obrigações legais a que estamos adstritos, sendo que os seus dados pessoais são essenciais para
a execução do contrato aqui em causa.
                </p>
                <ul>
                    <li class="text-justify">
                        Realização de inquéritos de satisfação com base nas vendas realizadas. Neste caso o fundamento
jurídico aplicável ao tratamento dos seus dados pessoais será o nosso interesse legítimo em
conhecer a sua opinião quanto às funcionalidades do nosso website e melhorar o mesmo tendo em
vista ir ao encontro das suas necessidades.
                    </li>
                    <li class="text-justify">
                        Realização de estudo de mercado com bases nas vendas realizadas. Neste caso o fundamento
jurídico aplicável ao tratamento dos seus dados pessoais será o nosso interesse legítimo em conhecer a sua opinião quanto às funcionalidades do nosso website e melhorar o mesmo tendo em vista ir ao encontro das suas necessidades.
                    </li>
                </ul>
            </div>
        </div>
      
        <div class="row mt-2">
            <div class="col-12">
                <h3 class="uppercase">Tempo de conservação dos dados pessoais<span></span></h3>
                <p class="text-justify mt-2">
                    O prazo pelo qual conservamos os seus dados pessoais poderá variar consoante a finalidade para a qual estes são tratados. Assim, iremos conservar os seus dados pessoais durante os seguintes prazos:
                </p>
                <ul>
                    <li class="text-justify">Envio de comunicações comerciais: até que exerça o seu direito de oposição ou revogue o seu consentimento, consoante o que for aplicável.

                    </li>
                    <li class="text-justify">Para as finalidades associadas à Loja Online:
                        <p>- durante o tempo necessário para assegurar o integral cumprimento do contrato celebrado consigo, incluindo, sem limitar, para efeitos de garantia e assistência pós-venda;</p>
                        <p>- durante os prazos legais aplicáveis.</p></li>
                    <li class="text-justify">Realização de inquéritos de satisfação com base nas vendas realizadas: […].

                    </li>
                    <li class="text-justify">Realização de estudo de mercado com bases nas vendas realizadas: […].

                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3 class="uppercase">Transmissão de dados pessoais<span></span></h3>
                <p class="text-justify mt-2">
                    Poderemos comunicar os seus dados pessoais a entidades terceiras para efeitos de cumprimento das obrigações legais que em cada caso sejam aplicáveis ou nos casos em que tal se revele necessário à prestação dos serviços a contratar.
                    Sempre que o tratamento de dados pessoais seja realizada através de prestadores de serviços externos, que atuam como subcontratantes, seguimos critérios rigorosos na seleção de prestadores de serviços, a fim de cumprir com as suas obrigações de proteção de dados, comprometendo-nos a subscrever com os mesmos um acordo de tratamento de dados.
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3 class="uppercase">Direitos do titular dos dados<span></span></h3>
                <p class="text-justify mt-2">
                    Como titular dos dados tem os seguintes direitos relativamente ao tratamento dos seus dados pessoais:
                </p>
                <ul>
                    <li class="text-justify">Acesso: Poderá obter informação se estamos a tratar os seus dados pessoais, assim como consultar os seus dados pessoais incluídos em arquivos desta sociedade.
                    </li>
                    <li class="text-justify">Retificação: Poderá alterar os seus dados pessoais quando estejam incorretos assim como completar aqueles que estejam incompletos.
                    </li>
                    <li class="text-justify">Apagamento: Poderá solicitar o apagamento dos seus dados pessoais quando, entre outras razões, os dados pessoais já não sejam necessários para as finalidades para os quais foram recolhidos.
                    </li>
                    <li class="text-justify">Oposição: Em determinadas situações poderá requerer que os seus dados pessoais deixem de ser tratados. Iremos cessar o tratamento dos dados pessoais, exceto se existirem motivos legítimos ou para a declaração, exercício ou defesa de direitos num processo administrativo, judicial ou extrajudicial.
                    </li>
                    <li class="text-justify">Limitação do tratamento: Poderá requerer a limitação do tratamento dos seus dados pessoais nas seguintes situações: (a) durante a contestação da exatidão dos seus dados pessoais; (b) quando o tratamento for ilícito e se tenha oposto ao mesmo e solicitado a limitação do uso dos dados pessoais; (c) quando já não precisemos de tratar os seus dados pessoais, mas precise dos mesmos para o exercício ou defesa de direitos num processo administrativo, judicial ou extrajudicial; (d) quando se tenha oposto ao tratamento dos seus dados pessoais para o cumprimento de uma obrigação de interesse público ou para a satisfação de um interesse legítimo, enquanto se verificar que esses motivos legítimos para o tratamento prevalecem sobre os seus motivos.
                    </li>
                    <li class="text-justify">Portabilidade: Em determinadas situações terá direito a receber, num formato estruturado, de uso corrente e leitura automática, os dados pessoais que nos tenha disponibilizado e aqueles que se tenham obtido a partir da sua relação connosco, assim como a transmiti-los a outra entidade.
                    </li>
                </ul>
                <p class="text-justify mt-2">
                    Se tiver qualquer questão ou pretender exercer os referidos direitos, poderá enviar um e-mail para geral@cdtondela.pt.
                    Para o exercício dos seus direitos, caso seja necessário, poderemos solicitar o envio de um documento que comprove a sua
                    identidade. Deverá também indicar o direito que pretende exercer. O exercício dos direitos é gratuito, exceto se se
                    tratar de um pedido manifestamente infundado, excessivo ou reiterado.
                    Por último, fazemos notar que pode apresentar uma reclamação junto da autoridade de controlo competente (Comissão
                    Nacional de Proteção de Dados) através do site <a href="www.cnpd.pt">cnpd.pt</a>.
                </p>
            </div>
        </div>
    </div>
</div>