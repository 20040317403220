import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {Interceptor} from '../app/http-interceptor/http.interceptor';
import { TransferState } from '@angular/platform-browser';
import { SocialLoginModule, SocialAuthServiceConfig , FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

import { ClubeComponent } from './components/clube/clube.component';
import { FutebolComponent } from './components/futebol/futebol.component';
import { SociosComponent } from './components/socios/socios.component';
import { StoreComponent } from './components/store/store.component';
import { InformacaoComponent } from './components/informacao/informacao.component';
import { HistoriaComponent } from './components/clube/historia/historia.component';
import { PalmaresComponent } from './components/clube/palmares/palmares.component';
import { PresidenteComponent } from './components/clube/presidente/presidente.component';
import { EstadioComponent } from './components/clube/estadio/estadio.component';
import { EquipaPrincipalComponent } from './components/futebol/equipa-principal/equipa-principal.component';
import { Sub19Component } from './components/futebol/formacao/sub19/sub19.component';
import { VantagensComponent } from './components/socios/vantagens/vantagens.component';
import { QuotasComponent } from './components/socios/quotas/quotas.component';
import { DescontosComponent } from './components/socios/descontos/descontos.component';
import { SerSocioComponent } from './components/socios/ser-socio/ser-socio.component';
import { InstitucionalComponent } from './components/informacao/institucional/institucional.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ClubeNavbarComponent } from './components/clube/clube-navbar/clube-navbar.component';
import { FutebolNavbarComponent } from './components/futebol/futebol-navbar/futebol-navbar.component';
import { EquipaPrincipalResultadosComponent } from './components/futebol/equipa-principal/equipa-principal-resultados/equipa-principal-resultados.component';
import { EquipaPrincipalClassificacaoComponent } from './components/futebol/equipa-principal/equipa-principal-classificacao/equipa-principal-classificacao.component';
import { EquipaPrincipalCalendarioComponent } from './components/futebol/equipa-principal/equipa-principal-calendario/equipa-principal-calendario.component';
import { SociosSidebarComponent } from './components/socios/socios-sidebar/socios-sidebar.component';
import { SocioHeaderComponent } from './components/socios/socio-header/socio-header.component';
import { EquipaPrincipalBannerComponent } from './components/futebol/equipa-principal/equipa-principal-banner/equipa-principal-banner.component'
import { TermosComponent } from './components/footer/termos/termos.component';
import { ContactosComponent } from './components/footer/contactos/contactos.component';
import { PrivacidadeComponent } from './components/footer/privacidade/privacidade.component';
import { CookiesComponent } from './components/footer/cookies/cookies.component';
import { ComoChegarComponent } from './components/informacao/como-chegar/como-chegar.component';
import { InfoBannerComponent } from './components/informacao/info-banner/info-banner.component';
import { InfoNavbarComponent } from './components/informacao/info-navbar/info-navbar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EquipaPrincipalHighlightsComponent } from './components/futebol/equipa-principal/equipa-principal-highlights/equipa-principal-highlights.component';
import { AgendaEventosComponent } from './components/homepage/agenda-eventos/agenda-eventos.component';
import { AcademiaComponent } from './components/clube/academia/academia.component';
import { ModalidadesComponent } from './components/modalidades/modalidades.component';
import { TenisComponent } from './components/modalidades/tenis/tenis.component';
import { HipismoComponent } from './components/modalidades/hipismo/hipismo.component';
import { EsportsComponent } from './components/modalidades/esports/esports.component';
import { VeteranosComponent } from './components/modalidades/veteranos/veteranos.component';
import { FormacaoComponent } from './components/futebol/formacao/formacao.component';
import { Sub19ResultadosComponent } from './components/futebol/formacao/sub19/sub19-resultados/sub19-resultados.component';
import { Sub19CalendarioComponent } from './components/futebol/formacao/sub19/sub19-calendario/sub19-calendario.component';
import { Sub19ClassificacaoComponent } from './components/futebol/formacao/sub19/sub19-classificacao/sub19-classificacao.component';
import { FormacaoBannerComponent } from './components/futebol/formacao/formacao-banner/formacao-banner.component';
import { Sub17Component } from './components/futebol/formacao/sub17/sub17.component';
import { Sub15Component } from './components/futebol/formacao/sub15/sub15.component';
import { Sub14Component } from './components/futebol/formacao/sub14/sub14.component';
import { Sub13Component } from './components/futebol/formacao/sub13/sub13.component';
import { Sub11Component } from './components/futebol/formacao/sub11/sub11.component';
import { Sub17CalendarioComponent } from './components/futebol/formacao/sub17/sub17-calendario/sub17-calendario.component';
import { Sub17ClassificacaoComponent } from './components/futebol/formacao/sub17/sub17-classificacao/sub17-classificacao.component';
import { Sub17ResultadosComponent } from './components/futebol/formacao/sub17/sub17-resultados/sub17-resultados.component';
import { Sub15CalendarioComponent } from './components/futebol/formacao/sub15/sub15-calendario/sub15-calendario.component';
import { Sub15ClassificacaoComponent } from './components/futebol/formacao/sub15/sub15-classificacao/sub15-classificacao.component';
import { Sub15ResultadosComponent } from './components/futebol/formacao/sub15/sub15-resultados/sub15-resultados.component';
import { Sub14CalendarioComponent } from './components/futebol/formacao/sub14/sub14-calendario/sub14-calendario.component';
import { Sub14ClassificacaoComponent } from './components/futebol/formacao/sub14/sub14-classificacao/sub14-classificacao.component';
import { Sub14ResultadosComponent } from './components/futebol/formacao/sub14/sub14-resultados/sub14-resultados.component';
import { Sub13CalendarioComponent } from './components/futebol/formacao/sub13/sub13-calendario/sub13-calendario.component';
import { Sub13ClassificacaoComponent } from './components/futebol/formacao/sub13/sub13-classificacao/sub13-classificacao.component';
import { Sub13ResultadosComponent } from './components/futebol/formacao/sub13/sub13-resultados/sub13-resultados.component';
import { Sub11CalendarioComponent } from './components/futebol/formacao/sub11/sub11-calendario/sub11-calendario.component';
import { Sub11ClassificacaoComponent } from './components/futebol/formacao/sub11/sub11-classificacao/sub11-classificacao.component';
import { Sub11ResultadosComponent } from './components/futebol/formacao/sub11/sub11-resultados/sub11-resultados.component';
import { EquipaPrincipalStaffComponent } from './components/futebol/equipa-principal/equipa-principal-staff/equipa-principal-staff.component';
import { ModalidadesBannerComponent } from './components/modalidades/modalidades-banner/modalidades-banner.component';
import { ModalidadesNavbarComponent } from './components/modalidades/modalidades-navbar/modalidades-navbar.component';
import { PaginaSemDadosComponent } from './components/futebol/formacao/pagina-sem-dados/pagina-sem-dados.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NoticiaComponent } from './components/noticia/noticia.component';
import { ClubeHeaderComponent } from './components/clube/clube-header/clube-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EsportsNavbarComponent } from './components/modalidades/esports/esports-navbar/esports-navbar.component';
import { EsportsHeaderComponent } from './components/modalidades/esports/esports-header/esports-header.component';
import { EsportsProclubsComponent } from './components/modalidades/esports/esports-proclubs/esports-proclubs.component';
import { EsportsOneoneComponent } from './components/modalidades/esports/esports-oneone/esports-oneone.component';
import { EsportsStaffComponent } from './components/modalidades/esports/esports-staff/esports-staff.component';
import { EsportsStreamComponent } from './components/modalidades/esports/esports-stream/esports-stream.component';
import { MissaoComponent } from './components/futebol/formacao/missao/missao.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PetizesComponent } from './components/futebol/formacao/petizes/petizes.component';
import { TraquinasComponent } from './components/futebol/formacao/traquinas/traquinas.component';
import { CertificacaoComponent } from './components/futebol/formacao/certificacao/certificacao.component';
import { NoticiasFiltradasComponent } from './components/futebol/noticias-filtradas/noticias-filtradas.component';
import { PopupInitComponent } from './components/popup-init/popup-init.component';
import { CookieService } from 'ngx-cookie-service';
import { MediaComponent } from './components/noticias/media/media.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { EstruturaComponent } from './components/estrutura/estrutura.component';
import { BasquetebolComponent } from './components/modalidades/basquetebol/basquetebol.component';
import { FaqsComponent } from './components/bilheteira/faqs/faqs.component';
import {CustomBilheteiraComponent} from './components/bilheteira/bilheteira.module';

import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { FormacaoEstruturaComponent } from './components/futebol/formacao/formacao-estrutura/formacao-estrutura.component';
import { CoordenacaoTecnicaComponent } from './components/futebol/formacao/formacao-estrutura/coordenacao-tecnica/coordenacao-tecnica.component';
import { DepartamentoDePedagogiaComponent } from './components/futebol/formacao/formacao-estrutura/departamento-de-pedagogia/departamento-de-pedagogia.component';
import { DepartamentoMedicoComponent } from './components/futebol/formacao/formacao-estrutura/departamento-medico/departamento-medico.component';
import { StaffDeApoioComponent } from './components/futebol/formacao/formacao-estrutura/staff-de-apoio/staff-de-apoio.component';
import { AtletismoComponent } from './components/modalidades/atletismo/atletismo.component';
import { UpperCasePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    ClubeComponent,
    FutebolComponent,
    SociosComponent,
    StoreComponent,
    InformacaoComponent,
    HistoriaComponent,
    PalmaresComponent,
    PresidenteComponent,
    EstadioComponent,
    EquipaPrincipalComponent,
    Sub19Component,
    VantagensComponent,
    QuotasComponent,
    DescontosComponent,
    SerSocioComponent,
    InstitucionalComponent,
    NoticiasComponent,
    SidebarComponent,
    FooterComponent,
    HomepageComponent,
    ClubeNavbarComponent,
    FutebolNavbarComponent,
    EquipaPrincipalResultadosComponent,
    EquipaPrincipalClassificacaoComponent,
    EquipaPrincipalCalendarioComponent,
    SociosSidebarComponent,
    SocioHeaderComponent,
    EquipaPrincipalBannerComponent,
    TermosComponent,
    ContactosComponent,
    PrivacidadeComponent,
    CookiesComponent,
    ComoChegarComponent,
    InfoBannerComponent,
    InfoNavbarComponent,
    SpinnerComponent,
    EquipaPrincipalHighlightsComponent,
    AgendaEventosComponent,
    AcademiaComponent,
    ModalidadesComponent,
    TenisComponent,
    HipismoComponent,
    EsportsComponent,
    AtletismoComponent,
    VeteranosComponent,
    FormacaoComponent,
    FormacaoEstruturaComponent,
    CoordenacaoTecnicaComponent,
    DepartamentoDePedagogiaComponent,
    DepartamentoMedicoComponent,
    StaffDeApoioComponent,
    Sub19ResultadosComponent,
    Sub19CalendarioComponent,
    Sub19ClassificacaoComponent,
    FormacaoBannerComponent,
    Sub17Component,
    Sub15Component,
    Sub14Component,
    Sub13Component,
    Sub11Component,
    Sub17CalendarioComponent,
    Sub17ClassificacaoComponent,
    Sub17ResultadosComponent,
    Sub15CalendarioComponent,
    Sub15ClassificacaoComponent,
    Sub15ResultadosComponent,
    Sub14CalendarioComponent,
    Sub14ClassificacaoComponent,
    Sub14ResultadosComponent,
    Sub13CalendarioComponent,
    Sub13ClassificacaoComponent,
    Sub13ResultadosComponent,
    Sub11CalendarioComponent,
    Sub11ClassificacaoComponent,
    Sub11ResultadosComponent,
    EquipaPrincipalStaffComponent,
    ModalidadesBannerComponent,
    ModalidadesNavbarComponent,
    PaginaSemDadosComponent,
    NotFoundComponent,
    NoticiaComponent,
    ClubeHeaderComponent,
    EsportsNavbarComponent,
    EsportsHeaderComponent,
    EsportsProclubsComponent,
    EsportsOneoneComponent,
    EsportsStaffComponent,
    EsportsStreamComponent,
    MissaoComponent,
    PetizesComponent,
    TraquinasComponent,
    CertificacaoComponent,
    NoticiasFiltradasComponent,
    PopupInitComponent,
    MediaComponent,
    EstruturaComponent,
    BasquetebolComponent,
    FaqsComponent,
  ],
  imports: [
    SocialLoginModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgbAlertModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    CustomBilheteiraComponent,
  ],
  providers: [ {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true}, CookieService,
    GoogleAnalyticsService, TransferState,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '828005879832-l5h246ln3mbird04aaf1ai3ail4t03ei.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('360494772203825')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    UpperCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
