<app-modalidades-banner></app-modalidades-banner>
<app-modalidades-navbar></app-modalidades-navbar>
<div @fade class="container-fluid bg1 pt-5 pb-3">
  <div class="container">
      <p class="titleEst">BASQUETEBOL - CD TONDELA</p>
      <p class="descEst text-justify">
        O Mundo Tondela não pára de crescer!
        É com um sorriso na cara que anunciamos a chegada do Basquetebol ao nosso clube.
        Em mais um passo que damos na cada vez maior diversidade das modalidades auriverdes, termos como o "passe picado, bloqueio de lançamento, ressalto ou triplo-duplo" passam a fazer parte do léxico tondelense.
        Em breve traremos mais novidades, mas já com esta certeza -
        Estamos cada vez mais ecléticos. Agora também somos Basquetebol.
      </p>
  </div>
</div>

<div class="container-fluid bg_3 mb-lg-5">
  <div class="container fix-mobile-padding">
      <div class="row">
          <div class="col-12 col-lg-9">
                  <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                      <div class="carousel-inner">

                      <div class="carousel-item active">
                          <img class="d-block w-100" src="assets/img/basquetebol.webp" alt="Ténis">
                      </div>

                      </div>
                     <!--  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                      </a> -->
              </div>

          </div>
          <div class="col-12 col-lg-3 mb-5 mb-lg-3 centerNumbers">

              <p class="numbers">basquetebol@cdtondela.pt</p>

              <p class="desc">Contactos</p>


          </div>
      </div>
  </div>
</div>

<div *ngIf="news.length > 0" class="container py-5">
  <h1 class="titleEst">NOTÍCIAS</h1>
  <div class="container bg-news">
      <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
          <div class="col-lg-5">
              <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                  <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
              </div>
              <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
          </div>
          <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
              <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                  <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
              </div>

              <h2 class="news-title">{{new.titulo}}</h2>
              <p class="news-date">{{new.data}}</p>
              <p class="news-subtitle">{{new.subtitulo}}
              </p>
              <div class="button arrow">Ler Notícia</div>
          </div>
      </div>
  </div>
</div>

