import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {BasicMetaTags} from '../interfaces/BasicMetaTags'

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  basicKeywords = ['cdtondela', 'tondela', 'cdt', 'resultados', 'notícias', 'classificação', 'eventos', 'loja', 'bilheteira', 'modalidades', 'área de sócio', 'liga portuguesa', 'liga nos', 'portugal', 'futebol', 'hipismo', 'ténis', 'esports', 'orgulho beirão', 'febre amarela', 'clube']

  constructor( 
    private meta: Meta
  ) {}

  public setBasicMetaTags(content: BasicMetaTags): void {
    this.meta.updateTag({name: 'og:title', content: content.title})
    this.meta.updateTag({name: 'og:description', content: content.description})
    this.meta.updateTag({name: 'og:author', content: content.author})
    this.meta.updateTag({name: 'keywords', content: content.keywords.concat(this.basicKeywords).join(',')}) 
  }
}
