import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-ser-socio',
  templateUrl: './ser-socio.component.html',
  styleUrls: ['./ser-socio.component.scss']
})
export class SerSocioComponent implements OnInit, OnDestroy {

  socioForm = new FormGroup({
    nome: new FormControl(null, [Validators.required]),
    data: new FormControl(null, Validators.required),
    nif: new FormControl(null, Validators.required),
    telefone: new FormControl(null, Validators.required),
    morada: new FormControl(null, Validators.required),
    cidade: new FormControl(null, Validators.required),
    cod_postal: new FormControl(null, Validators.required),
    pais: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    plano: new FormControl(null, Validators.required),
    duracao: new FormControl(null, Validators.required),
    termos: new FormControl(null, Validators.requiredTrue),
  })

  sub: Subscription[] = []

  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }
  onSubmit() {
    if(this.socioForm.valid) {
        this.sub.push(this.data.postSerSocio(this.socioForm.value).subscribe(res => {
      }))
      this.socioForm.reset()
    } else {
      Object.keys(this.socioForm.controls).forEach(field => {
        const control = this.socioForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
}
