import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-formacao-banner',
  templateUrl: './formacao-banner.component.html',
  styleUrls: ['./formacao-banner.component.scss']
})
export class FormacaoBannerComponent implements OnInit {

  @Input('equipa') public equipa: string = "";

  constructor(
    private router: Router
  ) { 
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        let url = event.urlAfterRedirects 
        if(url.includes('sub-19')) this.equipa = 'sub-19'
        if(url.includes('sub-17')) this.equipa = 'sub-17'
        if(url.includes('sub-15')) this.equipa = 'sub-15'
        if(url.includes('pestinhas')) this.equipa = 'pestinhas'
        if(url.includes('sub-13')) this.equipa = 'sub-13'
        if(url.includes('sub-11')) this.equipa = 'sub-11'
        if(url.includes('missao')) this.equipa = 'missão'
        if(url.includes('certificacao')) this.equipa = 'certificação'
        if(url.includes('petizes')) this.equipa = 'petizes'
        if(url.includes('traquinas')) this.equipa = 'traquinas'
        if(url.includes('equipa-principal/noticias')) this.equipa = 'equipa principal'
      }
    })

  
  }

  ngOnInit(): void {
  }

}
