<div class="container pb-5 bg1 position-relative">
    <div class="row">
        <div class="col-12 text-center justify-content-center d-block d-lg-none pt-5">
            <p class="labelPositions">STREAM</p>
        </div>
        <div class="d-none d-lg-block pos-label">
            <p class="labelPositions">STREAM</p>
        </div>
    </div>

    <!-- DESKTOP -->
    <div class="col-12 flex-column">
        <div class="row d-flex justify-content-center ml-xl-5">
            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>
                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>



            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>

                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>

                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>

                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>

                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="../../../../../assets/img/esports/no-photo.png" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="../../../../../assets/img/esports/no-photo.png" />
                    <p class="img__description mb-0 d-flex align-items-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. </p>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Pessoa Exemplo</p>
                    </div>

                    <div class="tecnico-label shadow">
                        <p class="tecnico-nome m-0">Treinador</p>
                    </div>
                </div>
                <div class="col-12 py-3 d-block d-md-none">
                    <p class="texto-main">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in nisl nunc. Mauris sed lorem libero. Etiam blandit pellentesque turpis eget feugiat. Aenean tempus feugiat augue in posuere. Donec nec lacus quis enim fermentum vehicula. Proin felis augue, auctor eu faucibus ut, sodales vel tortor. 
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
