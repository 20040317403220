import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-departamento-de-pedagogia',
  templateUrl: './departamento-de-pedagogia.component.html',
  styleUrls: ['./departamento-de-pedagogia.component.scss']
})
export class DepartamentoDePedagogiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
