<app-equipa-principal-banner></app-equipa-principal-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>

<div @fade *ngIf="!state.loading" class="container py-lg-5 mt-4 mt-lg-0">
    <div class="container">
        <div class="row">
            <div class="col-12 p-0 mb-3 topTable title">CLASSIFICAÇÃO</div>
        </div>

        <div class="row">
            <div class="col-12 table-responsive p-0">
                <table class="col-12 table minWidth table-striped table-borderless">
                    <thead class="bgTable">
                        <tr class="d-flex align-items-center hRow">
                            <th class="col-1 text-center">POS</th>
                            <th class="col-4">CLUBE</th>
                            <th class="col-1 text-center">J</th>
                            <th class="col-1 text-center">V</th>
                            <th class="col-1 text-center">E</th>
                            <th class="col-1 text-center">D</th>
                            <th class="col-1 text-center">GM</th>
                            <th class="col-1 text-center">GS</th>
                            <th class="col-1 text-center">PTs</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-flex align-items-center" *ngFor="let class of classificacao"
                            [ngClass]="[class.clube == 'CD Tondela' ? 'bgTableCDT' : '', class.posicao == 16 && class.clube != 'CD Tondela' ? 'bgTableSmallRed' : '', class.posicao == 17 && class.clube != 'CD Tondela' ? 'bgTableRed' : '', class.posicao == 18 && class.clube != 'CD Tondela' ? 'bgTableBigRed' : '']">
                            <td class="col-1 text-center">{{class.posicao}}º</td>
                            <td class="col-4"><img src="assets/img/clubes/{{class.logo}}" alt="" class="img-fluid mr-1 mr-lg-3 imgFit"><span>{{class.clube | uppercase}}</span></td>
                            <td class="col-1 text-center">{{class.jogos}}</td>
                            <td class="col-1 text-center">{{class.vitorias}}</td>
                            <td class="col-1 text-center">{{class.empates}}</td>
                            <td class="col-1 text-center">{{class.derrotas}}</td>
                            <td class="col-1 text-center">{{class.golos_marcados}}</td>
                            <td class="col-1 text-center">{{class.golos_sofridos}}</td>
                            <td class="col-1 text-center">{{class.pontos}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
