import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipa-principal-banner',
  templateUrl: './equipa-principal-banner.component.html',
  styleUrls: ['./equipa-principal-banner.component.scss']
})
export class EquipaPrincipalBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
