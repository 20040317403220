<app-modalidades-banner></app-modalidades-banner>
<app-modalidades-navbar></app-modalidades-navbar>
<div @fade class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">VETERANOS CDT</p>

        <p class="descEst text-justify">
            A secção de Veteranos do Clube Desportivo de Tondela foi fundada no ano de 1989, por força de um conjunto de ex-atletas do clube que quiseram continuar ativos no mundo do futebol, após terem terminado as suas carreiras desportivas.
        </p>

        <p class="descEst text-justify">
            O marco do início de atividade das velhas glórias do Desportivo, remonta à data de 29 de abril de 1989, com a realização do seu primeiro jogo em Tondela com as Velhas Guardas do Sporting CP. Gravado na história ficam os jogadores que alinharam neste primeiro de inúmeros jogos até agora realizados pelo país.  Os Veteranos do CDT alinharam com: Zé Claro, José Silva, Cílio Matos, Pedro Milhães, Eduardo Henriques, Carlos Marta “Águas”, Jorge Brás, Salomão Santos, Alfredo Alexandre, António Sales e Víctor Neves.  Foram ainda utilizados: João Maia, Arménio Gonçalves, Élio Antunes, Fernando Rei, Armando Almeida, Carlos Maneira, Virgílio, Eduardo Pizarro, Avelar Coimbra, Germano Maneira, Fernando Rebelo e Júlio Carreira.  Este jogo ditou uma vitória forasteira por 4-3. Os marcadores dos golos auriverdes neste jogo histórico foram Alfredo Alexandre (2) e Carlos Maneira.
        </p>

        <p class="descEst text-justify">
            Mais tarde, a 6 de junho de 1992, por altura das comemorações de mais um aniversário do Clube Desportivo de Tondela, a secção de Veteranos do CDT realiza mais um jogo marcante com a receção às Velhas Glórias do Sport Lisboa e Benfica no estádio João Cardoso que trouxe na sua equipa dois ex- campeões europeus dos encarnados: Mário João e Artur Santos.  Os “encarnados” venceram pela margem mínima (1-0). 
        </p>

        <p class="descEst text-justify">
            Já no ano de 1995, os Veteranos do CDT, foram a primeira equipa a pisar o novo relvado do Estádio João Cardoso, inaugurando-o num jogo amigável com os Veteranos do Clube Atlético de Molelos. Tantos outros jogos se realizaram em vários estádios do país, estabelecendo o Clube Desportivo de Tondela relações históricas de amizade com os veteranos de outros clubes, onde em muitas ocasiões o melhor está guardado para as “terceiras partes” com os jantares convívios depois dos jogos entre veteranos.
        </p>
        
        <p class="descEst text-justify">
            Destaque para o emblemático Victor Correia, apelidado por muitos por “Sr. Perna” ou “Grande Chefe” que esteve 20 anos consecutivos como treinador desta secção auriverde desde a sua fundação. 
        </p>

        <p class="descEst text-justify">
            Longa já é a vida dos veteranos CD Tondela que por aqui e por ali fazem do desporto rei um veículo de promoção da amizade e companheirismo.
            Em 2015 os veteranos CD Tondela após um pequeno período de interregno e por iniciativa de ex-atletas (António Carmo, Renato Albernaz, Francisco Sousa, Rui Ramos e Jorge Silva) reiniciaram as suas atividades convidando todos os antigos atletas que até então representaram os veteranos e integraram novos elementos.
        </p>
        <p class="descEst text-justify">
            Com maiores ou menores sacrifícios e com o indispensável apoio de alguns organismos e empresas, temos tido possibilidade de efetuar anualmente em média 20 jogos, extravasando geograficamente a região a que pertencemos (centro) através de inúmeras deslocações ao Norte e ao Sul do país.
        </p>
    </div>
</div>


<div class="container-fluid bg_3 mb-lg-5">
    <div class="container fix-mobile-padding">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img/veteranos/veteranos.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item ">
                            <img class="d-block w-100" src="assets/img/veteranos/veteranos2.jpg" alt="Ténis">
                        </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                        </a>
                </div>
                  
            </div>
            <div class="col-12 col-lg-3 mb-5 mb-lg-3 centerNumbers">
                <p class="numbers">1989</p>
                <p class="desc">ANO DE FUNDAÇÃO</p>

                <p class="numbers">Veteranos CDT vs Velhas Guardas Sporting CP 29/04/1989</p>
                <p class="desc">1º Jogo </p>

                <p class="numbers">António Carmo</p>
                <p class="desc">Presidente da Direção</p>

                <a class="email" href="https://c-d-tondela-veteranos.webnode.pt/" target="_blank"><p class="numbers">VETERANOS - CDT</p></a>
                <p class="desc">SITE OFICIAL</p>
            </div>
        </div>
    </div>
</div>


<!--
    <div class="container-fluid bg1">

    <div class="container py-5">
        <h1 class="titleEst">NOTÍCIAS</h1>
        <div class="container bg-news">
            <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
                <div class="col-lg-5">
                    <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                    <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
                </div>
                <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
                    <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
    
                    <h2 class="news-title">{{new.titulo}}</h2>
                    <p class="news-date">{{new.data}}</p>
                    <p class="news-subtitle">{{new.subtitulo}}
                    </p>
                    <div class="button arrow">Ler Notícia</div>
                </div>
            </div>
        </div>
    </div>    
</div>
-->

