<app-formacao-banner equipa="estrutura"></app-formacao-banner>
<app-spinner @fade *ngIf="loading"></app-spinner>
<div @fade *ngIf="!loading">
    <h4 class="text-center" *ngIf="!loading && departamentos.length == 0"><i>Sem resultados.</i></h4>
    <div class="container-fluid py-5" [class.bg1]="i % 2 == 0"
        *ngFor="let departamento of departamentos; let i = index;">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions text-center"
                    [innerHTML]="departamento.nomeInnerHTML ? departamento.nomeInnerHTML : departamento.nome"></p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">{{departamento.nome | uppercase }}</p>
            </div>

            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3">
                <div class="row imgFit" *ngFor="let tecnico of departamento.tecnicos">
                    <div class="col-6 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{tecnico.nome | uppercase}}</p>
                        <p class="posJog m-0" *ngIf="tecnico.funcao">{{tecnico.funcao | uppercase}}</p>
                    </div>
                    <div class="col-6 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75"
                            [src]="tecnico.foto">
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center" [class.mb-4]="!tecnico.funcao"
                        *ngFor="let tecnico of departamento.tecnicos">
                        <div class="bgJogador">
                            <img class="img-fluid" [src]="tecnico.foto" alt="">
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0" [class.pb-2]="tecnico.funcao">{{tecnico.nome | uppercase}}
                                </p>
                            </div>
                            <div class="tecnico-label shadow" *ngIf="tecnico.funcao">
                                <p class="tecnico-nome m-0">{{tecnico.funcao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>