<app-modalidades-banner></app-modalidades-banner>
<app-modalidades-navbar></app-modalidades-navbar>
<div @fade class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">HIPISMO CDT</p>
        <p class="descEst text-justify">
            O CDTondela Hipismo surge de um Protocolo de Cooperação realizado entre o CDTondela e a Associação Hípica e
            Psicomotora de Viseu (AHPV), a 28 de novembro de 2019, um projeto que visa facilitar o desenvolvimento de atividades
            que façam uma combinação harmoniosa e equilibrada entre a Natureza, o Desporto e a Cultura.
        </p>
        <p class="descEst text-justify">
            Sedeado no Centro Hípico Montebelo entre as localidades de São Miguel do Outeiro e Farminhão, este espaço equestre
            classificado pela Federação Equestre Portuguesa com três estrelas, constitui-se como uma referência nacional em
            termos de instalações para a prática da modalidade, onde se destacam o campo de obstáculos, dois picadeiros
            exteriores, um picadeiro coberto, dezenas de boxes e clusters para alojamento de cavalos.
            Uma aposta vencedora na região de Viseu, com projeção nacional, o CDT Hipismo dinamiza uma escola de hipismo de
            referência no distrito, formando atletas desde as camadas jovens até à sua projeção profissional em competições
            nacionais e internacionais na modalidade de saltos de obstáculos.
        </p>
        <p class="descEst text-justify">
            A cumplicidade do cavalo e do Homem têm sido uma realidade permanente desde a sua génese. O Hipismo é o exemplo mais
            claro dessa relação, que vale a pena experienciar.
        </p>
        <p class="descEst text-justify">
            No hipismo, a idade não é um limite, a compleição física não é uma condicionante, o género é apenas uma palavra!
        </p>
        <p class="descEst text-justify">
            Desafie-se! CDTHipismo, desporto para todos.</p>
    </div>
</div>


<div class="container-fluid bg_3 mb-lg-5">
    <div class="container fix-mobile-padding">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                        <div class="carousel-item ">
                            <img class="d-block w-100" src="assets/img/hipismo/2.jpg" alt="Hipismo">
                        </div>
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img//hipismo/1.jpg" alt="Hipismo">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img//hipismo/3.jpg" alt="Hipismo">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img//hipismo/4.jpg" alt="Hipismo">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img//hipismo/5.jpg" alt="Hipismo">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img//hipismo/6.jpg" alt="Hipismo">
                        </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                        </a>
                </div>
                  
            </div>
            <div class="col-12 col-lg-3 mb-3 mb-lg-3 centerNumbers">
                <p class="numbers">962 106 928</p>
                <p class="desc">TELEFONE</p>

                <p class="numbers">cdt.hipismo@cdtondela.pt</p>
                <p class="desc">EMAIL</p>

            </div>
        </div>
    </div>
</div>

<!--
<div class="container-fluid bg1">
    <div class="container py-5">
        <h1 class="titleEst">NOTÍCIAS</h1>
        <div class="container bg-news">
            <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
                <div class="col-lg-5">
                    <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                    <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
                </div>
                <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
                    <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
    
                    <h2 class="news-title">{{new.titulo}}</h2>
                    <p class="news-date">{{new.data}}</p>
                    <p class="news-subtitle">{{new.subtitulo}}
                    </p>
                    <div class="button arrow">Ler Notícia</div>
                </div>
            </div>
        </div>
    </div>
</div>
-->

<div class="container-fluid bgTenis d-none d-lg-block">
    <div class="container">
        <div class="row alt-banner">
            <div class="col-6 align-self-center pos-textleft">
                <h2 class="title-tenis">JUNTA-TE A NÓS</h2>
                <h3 class="desc-tenis">- Aulas Individuais</h3>
            </div>
            <div class="col-6 align-self-center pos-textleft text-right">
                <h2 class="inscrever">INSCREVE-TE JÁ!</h2>
                <a class="email" href="mailto:cdthipismo@gmail.com" target="_blank">cdt.hipismo@cdtondela.pt</a>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bgTenis d-block d-lg-none">
    <div class="container">
        <div class="row alt-banner ">
            <div class="col-12 align-self-center pos-textleft">
                <h2 class="inscrever mb-0">INSCREVE-TE JÁ!</h2>
                <a class="email" href="mailto:cdthipismo@gmail.com" target="_blank">cdt.hipismo@cdtondela.pt</a>
            </div>
        </div>
    </div>
</div>