<app-modalidades-banner></app-modalidades-banner>
<app-modalidades-navbar></app-modalidades-navbar>
<div @fade class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">ATLETISMO - CD TONDELA</p>
        <p class="descEst text-justify">
            O Atletismo é uma das modalidades desportivas mais antigas no Mundo. Em Portugal, começou a ser praticado no
            século XIX e, até à data, conta com quatro campeões olímpicos, sendo uma fonte inesgotável de conquistas e
            orgulho nacional.
        </p>
        <p class="descEst text-justify">
            A equipa Auriverde, CDTondela, e a SPMAQ decidiram, a 1 de novembro de 2023, apostar nesta modalidade, dando
            assim mais um passo para o ecletismo do Clube. Os atletas que representam, agora, esta equipa deram os seus
            “primeiros passos” no CAT elevando e enobrecendo o seu bom nome, bem como o da Cidade, Clube e patrocinador
            que representam, pelo país e além-fronteiras.</p>
        <p class="descEst text-justify">
            A equipa é representada por dez atletas, na sua maioria “gentes da terra”, sendo André Pais, Carlos Ribeiro,
            João Alves, João Lourosa, Luís Martins, Luís Simões, Rafael Pais, Rui Coimbra, Rui Rodrigues e Silvino
            Pombinho.
        </p>
        <p class="descEst text-justify">
            Almejam-se bons resultados, quer a título individual quer coletivo!
        </p>
    </div>
</div>

<div class="container-fluid bg_3 mb-lg-5">
    <div class="container fix-mobile-padding">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img/atletismo/atletismo-2024.jpeg" alt="Atletas Atlétismo">
                        </div>
                        </div>
                </div>
                  
            </div>
            <div class="col-12 col-lg-3 mb-5 mb-lg-3 centerNumbers">
                <p class="numbers">Atletas:</p>
                <p class="desc">
                    André Pais<br>
                    Carlos Ribeiro<br>
                    João Alves<br>
                    João Lourosa<br>
                    Luís Martins<br>
                    Luís Simões<br>
                    Rafael Pais<br>
                    Rui Coimbra<br>
                    Rui Rodrigues<br>
                    Silvino Pombinho</p>
            </div>
        </div>
    </div>
</div>


<div class="container py-5">
    <h1 class="titleEst">NOTÍCIAS</h1>
    <div class="container bg-news">
        <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
            <div class="col-lg-5">
                <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>
                <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>

                <h2 class="news-title">{{new.titulo}}</h2>
                <p class="news-date">{{new.data}}</p>
                <p class="news-subtitle">{{new.subtitulo}}
                </p>
                <div class="button arrow">Ler Notícia</div>
            </div>
        </div>
    </div>
</div>
