<app-clube-header></app-clube-header>


<app-clube-navbar></app-clube-navbar>

<div @fade>
    <div class="container-fluid bg1 py-3 py-md-5">
        <div class="container py-3 px-3 py-md-5 px-md-5 bg2">
            <div class="row">
                <div class="col-12">
                    <img src="assets/img/presidentes/gilberto.jpg" class="img-fluid">
                    <div class="col-12 py-5 presi text-justify">
                        <p>O Clube Desportivo de Tondela participa hoje, pela sexta época consecutiva, na Liga NOS,
                            encontrando-se assim no mais alto nível do futebol Português.
                            Posso assim dizer que a consolidação profissional do clube é hoje uma realidade. </p>
                        <p>Nestes 18 anos, em que esta Direção está em exercício, em conjunto com todos os tondelenses e
                            associados, levámos os rumos do CDTondela a patamares diferenciadores e que criam na nossa
                            Região Centro uma referência desportiva. Construímos um clube abrangente e diversificado, em
                            que além do futebol, hoje o Clube Desportivo de Tondela é também Ténis, Esports e Hipismo.
                        </p>
                        <p> A visão daqui em diante é solidificar a nossa aposta na formação desportiva. Hoje o CDT tem
                            os seus escalões nos patamares superiores da formação do futebol nacional, sendo agora tempo
                            de darmos início à construção da nossa anunciada ACADEMIA CDT. Este será um projeto que dará
                            aos jovens do concelho e da região todas as condições para se formarem enquanto atletas,
                            sempre com os valores assentes do CDT.
                        </p>
                        <p> Ao longo dos últimos anos ajudámos a colocar Tondela no mapa do futebol, a lenda da cidade é
                            agora conhecida além-fronteiras e o amarelo e verde, que tão bem nos caracteriza, já é um
                            padrão reconhecido e apreciado por todos.
                            Criámos uma dinâmica de humildade, profissionalismo, competência e rigor, mas o CD Tondela é
                            e continuará a ser sempre dos seus associados pelo que é deles que esperamos agora o maior
                            contributo para uma nova aventura na nossa já tão rica história de mais de oito décadas.
                            Contamos com o seu apoio, mas também com o apoio de todo, e tão importante, tecido
                            empresarial, nunca esquecendo a sempre presente Câmara Municipal de Tondela.</p>
                        <p> Teremos sempre de ser Tondela na hora da vitória ou da derrota e, passando esta mensagem aos
                            mais novos, construiremos o nosso futuro.</p>
                        <p>O CD Tondela é de primeira! Sejamos também nós todos de primeira!</p>
                        <p> Bem haja, </p>
                        <b> O Presidente do Clube,</b> <br>
                        <p style="font-style: italic;"> Gilberto Coimbra</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>