<div class="container-fluid py-md-2 py-md-5 bgCont">
    <div class="container px-0 py-5 p-md-5 bg1">

      <label class="mb-5">Se és sócio do CDT e queres criar conta no nosso site para usufruíres de todos os teus benefícios, envia um email (com os dados: Nome, nº sócio, Email e Telemóvel) para loja.cdt@cdtondela.pt</label>

        <form [formGroup]="socioForm" (ngSubmit)="onSubmit()">


            <div class="row align-items-center">
                <div class="col-12">
                    <h2 class="tituloForm m-0">DADOS PESSOAIS <span></span></h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label for="nome-completo" class="pt-3 nameLabel">Nome Completo</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Ex: João Pedro" [class.is-invalid]="socioForm.get('nome').invalid && (socioForm.get('nome').dirty || socioForm.get('nome').touched)" formControlName="nome" required>
                    </div>
                    <div *ngIf="socioForm.get('nome').invalid && (socioForm.get('nome').dirty || socioForm.get('nome').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">Data de Nascimento</label>
                    <div class="input-group ">
                        <input type="date" class="form-control" [class.is-invalid]="socioForm.get('data').invalid && (socioForm.get('data').dirty || socioForm.get('data').touched)" formControlName="data">
                    </div>
                    <div *ngIf="socioForm.get('data').invalid && (socioForm.get('data').dirty || socioForm.get('data').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">NIF</label>
                    <div class="input-group ">
                        <input type="text" maxlength="9" placeholder="Ex: 4xxxxxxxx" class="form-control" [class.is-invalid]="socioForm.get('nif').invalid && (socioForm.get('nif').dirty || socioForm.get('nif').touched)" formControlName="nif">
                    </div>
                    <div *ngIf="socioForm.get('nif').invalid && (socioForm.get('nif').dirty || socioForm.get('nif').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">Contacto Telefónico</label>
                    <div class="input-group ">
                        <input type="text" class="form-control" [class.is-invalid]="socioForm.get('telefone').invalid && (socioForm.get('telefone').dirty || socioForm.get('telefone').touched)" placeholder="Ex: +351 9xxxxxxxx" formControlName="telefone">
                    </div>
                    <div *ngIf="socioForm.get('telefone').invalid && (socioForm.get('telefone').dirty || socioForm.get('telefone').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label for="nome-completo" class="pt-3 nameLabel">Morada</label>
                    <div class="input-group ">
                        <input type="text" placeholder="Ex: Rua das Flores, 2º Esq" class="form-control " [class.is-invalid]="socioForm.get('morada').invalid && (socioForm.get('morada').dirty || socioForm.get('morada').touched)" formControlName="morada">
                    </div>
                    <div *ngIf="socioForm.get('morada').invalid && (socioForm.get('morada').dirty || socioForm.get('morada').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">Cidade</label>
                    <div class="input-group ">
                        <input type="text" maxlength="50" placeholder="Ex: Lisboa" class="form-control " [class.is-invalid]="socioForm.get('cidade').invalid && (socioForm.get('cidade').dirty || socioForm.get('cidade').touched)" formControlName="cidade">
                    </div>
                    <div *ngIf="socioForm.get('cidade').invalid && (socioForm.get('cidade').dirty || socioForm.get('cidade').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">Código Postal</label>
                    <div class="input-group ">
                        <input type="text" class="form-control" [class.is-invalid]="socioForm.get('cod_postal').invalid && (socioForm.get('cod_postal').dirty || socioForm.get('cod_postal').touched)" placeholder="Ex: xxxx-xxx" formControlName="cod_postal">
                    </div>
                    <div *ngIf="socioForm.get('cod_postal').invalid && (socioForm.get('cod_postal').dirty || socioForm.get('cod_postal').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
                <div class="col-md-4">
                    <label for="nome-completo" class="pt-3 nameLabel">País</label>
                    <div class="input-group ">
                        <input type="text" class="form-control" [class.is-invalid]="socioForm.get('pais').invalid && (socioForm.get('pais').dirty || socioForm.get('pais').touched)" placeholder="Ex: Portugal" formControlName="pais">
                    </div>
                    <div *ngIf="socioForm.get('pais').invalid && (socioForm.get('pais').dirty || socioForm.get('pais').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <label for="nome-completo" class="pt-3 nameLabel">E-mail</label>
                    <div class="input-group ">
                        <input type="text" class="form-control " [class.is-invalid]="socioForm.get('email').invalid && (socioForm.get('email').dirty || socioForm.get('email').touched)" placeholder="Ex: example@email.com" formControlName="email">
                    </div>
                    <div *ngIf="socioForm.get('email').invalid && (socioForm.get('email').dirty || socioForm.get('email').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>




            <div class="row align-items-center pt-5">
                <div class="col-12">
                    <h2 class="tituloForm m-0">QUOTAS<span></span></h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label for="nome-completo" class="pt-3 nameLabel">Planos de Sócio</label>
                    <div class="input-group fix-seta">
                        <select class="form-control selectColor"  formControlName="plano" [class.is-invalid]="socioForm.get('plano').invalid && (socioForm.get('plano').dirty || socioForm.get('plano').touched)">
                            <option value="" disabled selected>Escolha o seu Plano</option>
                            <option value="Jovem">Jovem - 10€</option>
                            <option value="Adulto">Adulto - 60€</option>
                        </select>
                    </div>
                    <div *ngIf="socioForm.get('plano').invalid && (socioForm.get('plano').dirty || socioForm.get('plano').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
                <div class="col-md-6">
                    <label for="nome-completo" class="pt-3 nameLabel">Duração</label>
                    <div class="input-group fix-seta">
                        <select class="form-control selectColor" formControlName="duracao" [class.is-invalid]="socioForm.get('duracao').invalid && (socioForm.get('duracao').dirty || socioForm.get('duracao').touched)">
                            <option value="" disabled selected>Plano de pagamento</option>
                            <option value="12 meses">12 meses</option>
                        </select>
                    </div>
                    <div *ngIf="socioForm.get('duracao').invalid && (socioForm.get('duracao').dirty || socioForm.get('duracao').touched)" class="text-invalid">Preenchimento obrigatório.</div>
                </div>
            </div>

        <div class="row pt-4">
            <div class="col-12">
                <div class="form-check">
                    <input class="form-check-input sizeCheckBox" type="checkbox" value="" id="defaultCheck1" formControlName="termos" [class.is-invalid]="socioForm.get('termos').invalid && (socioForm.get('termos').dirty || socioForm.get('termos').touched)">
                    <label class="form-check-label textLabel ml-2" for="defaultCheck1">
                        Autorizo a consulta e utilização dos dados pessoais disponibilizados sob regime de absoluta
                        confidencialidade, ao CD TONDELA, designadamente para o envio de futuras campanhas de marketing,
                        de
                        publicidade e de informação sobre produtos e serviços.
                    </label>
                </div>
                <!-- Modal HTML -->
                <div *ngIf="socioForm.valid" id="confirmacao-modal" class="modal fade">
                    <div class="modal-dialog modal-confirm">
                        <div class="modal-content">
                            <div class="modal-header justify-content-center">
                                <div class="icon-box">
                                    <i class="material-icons">&#xE876;</i>
                                </div>
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            </div>
                            <div class="modal-body text-center">
                                <h4>Pedido Registado</h4>
                                <p>Aguarde a confirmação do seu pedido. Será enviado um email com a confirmação do seu pedido.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row pt-5">
            <div class="col-12 d-flex justify-content-center">
                <button class="btn btn--skew btn-default yellow-button" >
                    <div data-target="#confirmacao-modal" class="button arrow d-flex align-items-center" data-toggle="modal">CONFIRMAR DADOS</div>
                </button>
            </div>
        </div>
        </form>
    </div>
</div>
