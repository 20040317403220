import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';
import { MetaService } from 'src/app/services/meta.service';



@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class NoticiaComponent implements OnInit, OnDestroy {

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1,
        margin: 15
      },
      740: {
        items: 2,
        margin:15
      },
      940: {
        items: 3,
        margin:15
      }
    },
    nav: false
  }

  arrayFilter: string[] = [
    'Equipa Principal',
    'Sub-19',
    'Sub-17',
    'Sub-15',
    'Sub-13',
    'Sub-14',
    'Sub-11',
    'Traquinas',
    'Petizes',
    'Pestinhas',
    'Ténis',
    'Hipismo',
    'Veteranos',
    'Esports Proclubs',
    'Esports 1v1',
    'Outro',
    'Basquetebol'
  ]

  sub: Subscription[] = []
  idNoticia: number

  noticia: any = {}
  ultimasNoticias: any = []

  urlSafe: SafeResourceUrl

  constructor(
    private data: DataService,
    private route: ActivatedRoute,
    public state: StateService,
    private meta: Meta,
    private title: Title,
    public metaTags: MetaService,
    private router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe(params => {
      this.idNoticia = params['id']
      this.getNoticia();
    })


   }

  ngOnInit(): void {
    this.getNoticia()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticia() {
    this.sub.push(this.data.getNoticia(this.idNoticia).subscribe((res: any) => {
      if(res.noticia === this.idNoticia) {
        res.escalao = this.arrayFilter[res.escalao-1]
        this.noticia = res
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.noticia.video)
      } else {
        this.router.navigate(['/404'])
      }
     /* this.metaTags.setBasicMetaTags({
        title: this.noticia.titulo,
        description: this.noticia.subtitulo,
        author: '2play',
        keywords: []
      }) */
   }))

   this.getUltimasNoticias()
  }


  getUltimasNoticias() {
    this.sub.push(this.data.getNoticias(1).subscribe((res: any) => {
      res.noticias.forEach(el => {
        el.escalao = this.arrayFilter[el.escalao-1]
      });
      this.ultimasNoticias = res.noticias.filter((res: any) => res.noticia !== this.idNoticia).slice(0,3)
    }))
    window.scroll(0,0)
  }


}
