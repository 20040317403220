<div class="container-fluid bg fix-padding">
    <div class="container">
        <div class="row d-flex align-items-end filterIndex">
            <div class="col-md-8 order-2 order-md-1">
                <h2 class="socioTitulo d-none d-md-block">FAZ PARTE DA NOSSA EQUIPA TORNA-TE SÓCIO!</h2>
                <h2 class="socioTitulo d-block d-md-none">TORNA-TE SÓCIO!</h2>
                <p class="socioDescricao ">Com o teu cartão de Sócio tens inúmeras vantagens, desde descontos exclusivos
                    em restaurantes, combustíveis, hóteis, serviços e muito mais.</p>
            </div>
        </div>
    </div>
</div>