import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagina-sem-dados',
  templateUrl: './pagina-sem-dados.component.html',
  styleUrls: ['./pagina-sem-dados.component.scss']
})
export class PaginaSemDadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
