<div class="container pb-5 bg1 position-relative">
    <div class="row">
        <div class="col-12 text-center justify-content-center d-block d-lg-none pt-5">
            <p class="labelPositions">JOGADORES</p>
        </div>
        <div class="d-none d-lg-block pos-label">
            <p class="labelPositions">JOGADORES</p>
        </div>
    </div>

    <!-- DESKTOP -->
    <div class="col-12 flex-column">
        <div class="row d-flex justify-content-center ml-xl-5">
            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/guilhermesousa.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/guilhermesousa.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Guilherme Sousa
                            <br>
                            <span class="yellow-text">"GUIMMY P"</span></p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/martimcoelho.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/martimcoelho.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Martim Coelho<br>
                            <span class="yellow-text">"COELHO"</span></p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/lucasgranja.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/lucasgranja.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Lucas Granja<br>
                            <span class="yellow-text">"GRANJINHA89"</span></p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/guilhermealexandre.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/guilhermealexandre.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Guilherme Alexandre <br>
                            <span class="yellow-text">"GUIALEX"</span></p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/raquelmartinho.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/raquelmartinho.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Raquel Martinho<br>
                            <span class="yellow-text">"RAQUELTY"</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg1">

    <div class="container py-5">
        <h1 class="tit">NOTÍCIAS</h1>
    
        <div class="container bg-news">
        
            <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
                <div class="col-lg-5">
                    <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                   <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
                </div>
                <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-4">
                    <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                    
                    <h2 class="news-title">{{new.titulo}}</h2>
                    <p class="news-date">{{new.data}}</p>
                    <p class="news-subtitle">{{new.subtitulo}}
                    </p>
                    <div class="button arrow">Ler Notícia</div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid bg2 pt-5 pb-5" id="news">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pr-0">
                <h2 class="titulos">Últimas Notícias</h2>
            </div>
        </div>
        <div class="row py-3 border-news touch">
            <div class="col-lg-5">
                <div class="d-block d-md-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']">
                    <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
                </a>
                <p class="news-date">22 Dezembro, 2020</p>
                <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito
                    de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título
                    de campeão de Inverno.
                </p>
                <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
            </div>
        </div>

        <div class="row py-3 border-news touch">
            <div class="col-lg-5">
                <div class="d-block d-md-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']">
                    <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
                </a>
                <p class="news-date">22 Dezembro, 2020</p>
                <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito
                    de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título
                    de campeão de Inverno.
                </p>
                <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
            </div>
        </div>

        <div class="row py-3 border-news touch">
            <div class="col-lg-5">
                <div class="d-block d-md-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']">
                    <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
                </a>
                <p class="news-date">22 Dezembro, 2020</p>
                <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito
                    de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título
                    de campeão de Inverno.
                </p>
                <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
            </div>
        </div>

        <div class="row py-3 border-news touch">
            <div class="col-lg-5">
                <div class="d-block d-md-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0">eSports | 1v1 </p>
                </div>
                <a [routerLink]="['/noticia']">
                    <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
                </a>
                <p class="news-date">22 Dezembro, 2020</p>
                <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito
                    de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título
                    de campeão de Inverno.
                </p>
                <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
            </div>
        </div>

        <div class="row py-3 border-news touch">
            <div class="col-lg-5">
                <div class="d-block d-md-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0">eSports | 1v1</p>
                </div>
                <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0">eSports | 1v1 </p>
                </div>
                <a [routerLink]="['/noticia']">
                    <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
                </a>
                <p class="news-date">22 Dezembro, 2020</p>
                <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito
                    de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título
                    de campeão de Inverno.
                </p>
                <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
            </div>
        </div>
    </div>
</div> -->