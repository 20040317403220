import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {BilheteiraComponent} from './bilheteira.component';
import {BannerGenericComponent} from '../shared/banner-generic/banner-generic.component';
import {NavbarGenericComponent} from '../shared/navbar-generic/navbar-generic.component';
import {SpinnerTicketComponent} from "../shared/spinner/spinner.component";
import {CookieService} from "ngx-cookie-service";



@NgModule({
  declarations: [ BilheteiraComponent, BannerGenericComponent, NavbarGenericComponent, SpinnerTicketComponent],
  exports: [ BilheteiraComponent, BannerGenericComponent, NavbarGenericComponent, SpinnerTicketComponent ],
  imports: [CommonModule, RouterModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [ CookieService ]
})
export class CustomBilheteiraComponent {}
