import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as $ from 'jquery';
import { StateService } from './services/state.service';
import {filter} from 'rxjs/operators'
import {Location} from '@angular/common';

import * as smoothscroll from "smoothscroll-polyfill";
import AOS from 'aos'
import { Meta } from '@angular/platform-browser';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cdt-angular';

  showBtn: boolean = false
  cdtLogo: string = 'cdtLogo'
  bg: string = 'bg'

  ogTitle: string = "noticia"
  ogDesc: string = "desc"
  ogImg: string = "img"
  ogUrl: string = "url"

  modalReference: any;
  @ViewChild('content') modalBase: ElementRef;
  closeResult = '';

  subscriptions:any = [];
  notificationData:any = null;

  constructor(
    private modalService: NgbModal,
    public state: StateService,
    private router: Router,
    public meta: Meta,
    private location:Location,
    private activatedRoute: ActivatedRoute
  ) {
    this.state.checkHaveLogin();
    if(this.location.path().length == 0) {
      this.router.navigate([window.location.pathname])
    }

    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-STTEN2BXXC', {
        'page_path': event.urlAfterRedirects,
        'page_title': event.urlAfterRedirects
      })
    })


    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects
       // set changeColor property with the value of the current route
        if(currentRoute.includes('historia') || currentRoute.includes('palmares') || currentRoute.includes('presidente')) {
          this.bg = 'bgVintage'
          this.cdtLogo = 'cdtLogoVintage outline'
        } else {
          this.bg = 'bg'
          this.cdtLogo = 'cdtLogo outline'
        }
      }

    });
    smoothscroll.polyfill();

    this.subscriptions.push(
      this.state.showModalNotification.subscribe((data:any) => {
        if(data != null) {
          this.notificationData = data
          this.openModal();
          this.state.hideNotification();
        } else {
         // this.notificationData = null;
        }
      })
    )

  }

  ngOnInit() {
    AOS.init()
    this.navbarMobile()

    this.meta.addTag({name: 'description', content: 'BEM VINDOS AO SITE OFICIAL DO CD TONDELA'})

  }

  ngOnDestroy()  {
    this.subscriptions.forEach((subs:any) =>  {
      subs.unsubscribe();
    });
  }

  navbarMobile() {
    var scroll_top
    // add padding top to show content behind navbar
    $('body').css('padding-top', $('.navbar').outerHeight() + 'px')

    // detect scroll top or down
    if ($('.smart-scroll').length >= 0) { // check if element exists
      var last_scroll_top = 0;

      $(window).on('scroll', function () {
        scroll_top = $(this).scrollTop();
        if(scroll_top < 0){
          scroll_top = 0;
        }
        if (scroll_top <= last_scroll_top) {
          $('.smart-scroll').removeClass('scrolled-down').addClass('scrolled-up');
        }
        else {
          $('.smart-scroll').removeClass('scrolled-up').addClass('scrolled-down');
        }
        last_scroll_top = scroll_top;
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    if(window.pageYOffset > 300 && window.screen.width > 767) {
      this.showBtn = true
    } else {
      this.showBtn = false
    }
  }

  pageTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  openModal() {
    this.modalReference = this.modalService.open(this.modalBase, {ariaLabelledBy: 'modal-basic-title', centered: true});

    this.modalReference.result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
