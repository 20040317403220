<!-- refazer a base de lógica disto -->
<app-banner-generic [title]="bannerTitle" [subTitle]="bannerSubTitle"></app-banner-generic>
<app-navbar-generic></app-navbar-generic>

<div class="container-fluid section">
  <div class="container py-5 px-0 px-md-5">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="accordion" id="accordionExample">

          <div class="card">
            <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#collapseone"
              aria-expanded="true" aria-controls="collapseone">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">Processo de compra</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseone"
                      aria-expanded="true" aria-controls="collapseone"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapseone" class="collapse show" aria-labelledby="headingThree">
              <div class="card-body bg1">
                <p>Faça o seu registo, pode usar o Gmail ou o Facebook para um registo mais rápido. Se for sócio,
                  dirija-se à Loja CDT, no Estádio João Cardoso, para atualizar os seus dados e fazer o seu registo.
                  Escolha um setor disponível, escolha o lugar, escolha o tipo de pagamento e efetue o pagamento nos 10
                  minutos seguintes.</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#collapseTwo"
              aria-expanded="true" aria-controls="collapseTwo">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">ONDE POSSO COMPRAR BILHETES?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseTwo"
                      aria-expanded="true" aria-controls="collapseTwo"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingThree">
              <div class="card-body bg1">
                <p>Poderá comprar bilhete para os jogos na loja do Estádio João Cardoso, ou através do site, utilizando
                  o link <a href="https://cdtondela.pt/#/bilheteira/lista-eventos" target="_blank">https://cdtondela.pt/#/bilheteira/lista-eventos</a></p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
              aria-expanded="true" aria-controls="collapseThree">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">COMPREI BILHETE ONLINE, COMO O POSSO RECEBER?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseThree"
                      aria-expanded="true" aria-controls="collapseThree"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
              <div class="card-body bg1">
                <p>Depois de efetuada a compra do bilhete deverá receber o bilhete em formato PDF no e-mail que registou
                  no momento da compra.
                  Caso não rececione o bilhete ou ocorra algum erro durante o processo de compra, poderá enviar um
                  e-mail para <a href="mailto:geral@2ticket.pt" target="_blank">geral@2ticket.pt</a></p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
              aria-expanded="true" aria-controls="collapseFive">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">SOU SÓCIO, QUAL A QUOTA VÁLIDA PARA COMPRAR BILHETE?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFive"
                      aria-expanded="true" aria-controls="collapseFive"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
              <div class="card-body bg1">
                <p>Os sócios do Clube Desportivo de Tondela devem ter a quota do mês respetivo regularizada para
                  proceder à compra do bilhete. A quota deverá ser paga na Loja CDT ou através da sua área de sócio no nosso website.</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingSix" data-toggle="collapse" data-target="#collapsesix"
              aria-expanded="true" aria-controls="collapsesix">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">NÃO SOU SÓCIO, QUANTOS BILHETES POSSO COMPRAR?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapsesix"
                      aria-expanded="true" aria-controls="collapsesix"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapsesix" class="collapse" aria-labelledby="headingSix">
              <div class="card-body bg1">
                <p>Pode comprar 5 bilhetes.</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingcinco" data-toggle="collapse" data-target="#collapsecinco"
              aria-expanded="true" aria-controls="collapsecinco">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">SOU SÓCIO, QUANTOS BILHETES POSSO COMPRAR?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapsecinco"
                      aria-expanded="true" aria-controls="collapsecinco"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapsecinco" class="collapse" aria-labelledby="headingcinco">
              <div class="card-body bg1">
                <p>Pode comprar 1 bilhete com desconto de sócio.</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingseis" data-toggle="collapse" data-target="#collapseseis"
              aria-expanded="true" aria-controls="collapseseis">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">QUANTO TEMPO TENHO PARA PAGAR O BILHETE NO SITE?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseseis"
                      aria-expanded="true" aria-controls="collapseseis"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapseseis" class="collapse" aria-labelledby="headingseis">
              <div class="card-body bg1">
                <p>Após a escolha do lugar tem 10 minutos para efetuar o pagamento, sendo que os seus lugares ficam
                  bloqueados durante esse tempo. Se não efetuar o pagamento os lugares são disponibilizados de novo para
                  venda.</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header cursor" id="headingsete" data-toggle="collapse" data-target="#collapsesete"
              aria-expanded="true" aria-controls="collapsesete">
              <h2 class="mb-0">
                <div class="row hRow centerVert">
                  <div class="col-9 col-lg-10">
                    <p class="instiTit ml-3 mb-0 ml-lg-5">POSSO TER ACESSO A REEMBOLSO DE BILHETES?</p>
                  </div>
                  <div class="col-3 col-lg-2 text-center">
                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapsesete"
                      aria-expanded="true" aria-controls="collapsesete"><i
                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                  </div>
                </div>
              </h2>
            </div>
            <div id="collapsesete" class="collapse" aria-labelledby="headingsete">
              <div class="card-body bg1">
                <p>O Clube Desportivo Tondela não efetua reembolso de bilhetes, excepto nas situações em que o evento
                  seja cancelado ou adiado</p>
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  </div>
</div>