<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2" id="barra">
            <div id="hist" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/clube/historia']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">HISTÓRIA</span>
            </div>
            <div id="palm" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/clube/palmares']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">PALMARÉS</span>
            </div>
            <div id="presi" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/clube/presidente']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto"
                    [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">PRESIDENTE</span>
            </div>
            <div id="estad" class="col-8 col-md-2 col-lg-2 border-left outline" [routerLink]="['/clube/estrutura']"
                routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">ESTRUTURA</span>
            </div>

            <div id="estad" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/clube/estadio']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">ESTÁDIO</span>
            </div>
        
            <div id="acad" class="col-8 col-md-2 col-lg-2 border-left border-right outline"
                [routerLink]="['/clube/academia']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">ACADEMIA CDT</span>
            </div>
        </div>
    </div>
</div>