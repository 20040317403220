import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClubeComponent } from './components/clube/clube.component';
import { FutebolComponent } from './components/futebol/futebol.component';
import { SociosComponent } from './components/socios/socios.component';
import { StoreComponent } from './components/store/store.component';
import { BilheteiraComponent } from './components/bilheteira/bilheteira.component';
import { FaqsComponent } from './components/bilheteira/faqs/faqs.component';

import { InformacaoComponent } from './components/informacao/informacao.component';
import { HistoriaComponent } from './components/clube/historia/historia.component';
import { PalmaresComponent } from './components/clube/palmares/palmares.component';
import { PresidenteComponent } from './components/clube/presidente/presidente.component';
import { EstadioComponent } from './components/clube/estadio/estadio.component';
import { EquipaPrincipalComponent } from './components/futebol/equipa-principal/equipa-principal.component';
import { Sub19Component } from './components/futebol/formacao/sub19/sub19.component';
import { VantagensComponent } from './components/socios/vantagens/vantagens.component';
import { QuotasComponent } from './components/socios/quotas/quotas.component';
import { DescontosComponent } from './components/socios/descontos/descontos.component';
import { SerSocioComponent } from './components/socios/ser-socio/ser-socio.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { EquipaPrincipalResultadosComponent } from './components/futebol/equipa-principal/equipa-principal-resultados/equipa-principal-resultados.component';
import { EquipaPrincipalClassificacaoComponent } from './components/futebol/equipa-principal/equipa-principal-classificacao/equipa-principal-classificacao.component';
import { EquipaPrincipalCalendarioComponent } from './components/futebol/equipa-principal/equipa-principal-calendario/equipa-principal-calendario.component';
import { TermosComponent } from './components/footer/termos/termos.component';
import { PrivacidadeComponent } from './components/footer/privacidade/privacidade.component';
import { CookiesComponent } from './components/footer/cookies/cookies.component';
import { ContactosComponent } from './components/footer/contactos/contactos.component';
import { ComoChegarComponent } from './components/informacao/como-chegar/como-chegar.component';
import { EquipaPrincipalHighlightsComponent } from './components/futebol/equipa-principal/equipa-principal-highlights/equipa-principal-highlights.component';
import { AcademiaComponent } from './components/clube/academia/academia.component';
import { ModalidadesComponent } from './components/modalidades/modalidades.component';
import { TenisComponent } from './components/modalidades/tenis/tenis.component';
import { HipismoComponent } from './components/modalidades/hipismo/hipismo.component';
import { EsportsComponent } from './components/modalidades/esports/esports.component';
import { FormacaoComponent } from './components/futebol/formacao/formacao.component';
import { Sub19CalendarioComponent } from './components/futebol/formacao/sub19/sub19-calendario/sub19-calendario.component';
import { Sub19ResultadosComponent } from './components/futebol/formacao/sub19/sub19-resultados/sub19-resultados.component';
import { Sub19ClassificacaoComponent } from './components/futebol/formacao/sub19/sub19-classificacao/sub19-classificacao.component';
import { Sub17Component } from './components/futebol/formacao/sub17/sub17.component';
import { Sub17CalendarioComponent } from './components/futebol/formacao/sub17/sub17-calendario/sub17-calendario.component';
import { Sub17ResultadosComponent } from './components/futebol/formacao/sub17/sub17-resultados/sub17-resultados.component';
import { Sub17ClassificacaoComponent } from './components/futebol/formacao/sub17/sub17-classificacao/sub17-classificacao.component';
import { Sub15Component } from './components/futebol/formacao/sub15/sub15.component';
import { Sub15CalendarioComponent } from './components/futebol/formacao/sub15/sub15-calendario/sub15-calendario.component';
import { Sub15ResultadosComponent } from './components/futebol/formacao/sub15/sub15-resultados/sub15-resultados.component';
import { Sub15ClassificacaoComponent } from './components/futebol/formacao/sub15/sub15-classificacao/sub15-classificacao.component';
import { Sub14Component } from './components/futebol/formacao/sub14/sub14.component';
import { Sub14CalendarioComponent } from './components/futebol/formacao/sub14/sub14-calendario/sub14-calendario.component';
import { Sub14ResultadosComponent } from './components/futebol/formacao/sub14/sub14-resultados/sub14-resultados.component';
import { Sub14ClassificacaoComponent } from './components/futebol/formacao/sub14/sub14-classificacao/sub14-classificacao.component';
import { Sub13Component } from './components/futebol/formacao/sub13/sub13.component';
import { Sub13CalendarioComponent } from './components/futebol/formacao/sub13/sub13-calendario/sub13-calendario.component';
import { Sub13ClassificacaoComponent } from './components/futebol/formacao/sub13/sub13-classificacao/sub13-classificacao.component';
import { Sub13ResultadosComponent } from './components/futebol/formacao/sub13/sub13-resultados/sub13-resultados.component';
import { Sub11Component } from './components/futebol/formacao/sub11/sub11.component';
import { Sub11CalendarioComponent } from './components/futebol/formacao/sub11/sub11-calendario/sub11-calendario.component';
import { Sub11ResultadosComponent } from './components/futebol/formacao/sub11/sub11-resultados/sub11-resultados.component';
import { Sub11ClassificacaoComponent } from './components/futebol/formacao/sub11/sub11-classificacao/sub11-classificacao.component';
import { EquipaPrincipalStaffComponent } from './components/futebol/equipa-principal/equipa-principal-staff/equipa-principal-staff.component';
import { VeteranosComponent } from './components/modalidades/veteranos/veteranos.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NoticiaComponent } from './components/noticia/noticia.component';
import { EsportsProclubsComponent } from './components/modalidades/esports/esports-proclubs/esports-proclubs.component';
import { EsportsOneoneComponent } from './components/modalidades/esports/esports-oneone/esports-oneone.component';
import { EsportsStaffComponent } from './components/modalidades/esports/esports-staff/esports-staff.component';
import { EsportsStreamComponent } from './components/modalidades/esports/esports-stream/esports-stream.component';
import { MissaoComponent } from './components/futebol/formacao/missao/missao.component';
import { PetizesComponent } from './components/futebol/formacao/petizes/petizes.component';
import { TraquinasComponent } from './components/futebol/formacao/traquinas/traquinas.component';
import { CertificacaoComponent } from './components/futebol/formacao/certificacao/certificacao.component';
import { NoticiasFiltradasComponent } from './components/futebol/noticias-filtradas/noticias-filtradas.component';
import { EstruturaComponent } from './components/estrutura/estrutura.component';
import { BasquetebolComponent } from './components/modalidades/basquetebol/basquetebol.component';
import { CoordenacaoTecnicaComponent } from './components/futebol/formacao/formacao-estrutura/coordenacao-tecnica/coordenacao-tecnica.component';
import { StaffDeApoioComponent } from './components/futebol/formacao/formacao-estrutura/staff-de-apoio/staff-de-apoio.component';
import { DepartamentoDePedagogiaComponent } from './components/futebol/formacao/formacao-estrutura/departamento-de-pedagogia/departamento-de-pedagogia.component';
import { DepartamentoMedicoComponent } from './components/futebol/formacao/formacao-estrutura/departamento-medico/departamento-medico.component';
import { FormacaoEstruturaComponent } from './components/futebol/formacao/formacao-estrutura/formacao-estrutura.component';
import { AtletismoComponent } from './components/modalidades/atletismo/atletismo.component';


const routes: Routes = [
   {path: 'home', pathMatch: 'full', redirectTo: '' },
  {path: '', component: HomepageComponent },
  {path: 'clube', component: ClubeComponent, children: [
    {path: '', redirectTo: 'historia', pathMatch: 'full' },
    {path: 'historia', component: HistoriaComponent},
    {path: 'palmares', component: PalmaresComponent},
    {path: 'presidente', component: PresidenteComponent},
    {path: 'estadio', component: EstadioComponent},
    {path: 'estrutura', component: EstruturaComponent},
    {path: 'academia', component: AcademiaComponent}
  ]},
  {path: 'futebol', component: FutebolComponent, children: [
    {path: '', redirectTo: 'equipa-principal/plantel', pathMatch: 'full' },
    {path: 'equipa-principal/plantel', component: EquipaPrincipalComponent},
    {path: 'equipa-principal/staff', component: EquipaPrincipalStaffComponent},
    {path: 'equipa-principal/resultados', component: EquipaPrincipalResultadosComponent},
    {path: 'equipa-principal/resultados/detalhes/:id', component: EquipaPrincipalHighlightsComponent},
    {path: 'equipa-principal/classificacao', component: EquipaPrincipalClassificacaoComponent},
    {path: 'equipa-principal/calendario', component: EquipaPrincipalCalendarioComponent},
    {path: 'equipa-principal/noticias', component: NoticiasFiltradasComponent},
  ]},
  {path: 'formacao', component: FormacaoComponent, children: [
    {path: '', redirectTo: 'sub-19/plantel', pathMatch: 'full' },
    {path: 'estrutura', component: FormacaoEstruturaComponent}, 
    {path: 'sub-19/plantel', component: Sub19Component},
    {path: 'sub-19/calendario', component: Sub19CalendarioComponent},
    {path: 'sub-19/resultados', component: Sub19ResultadosComponent},
    {path: 'sub-19/classificacao', component: Sub19ClassificacaoComponent},
    {path: 'sub-19/noticias', component: NoticiasFiltradasComponent},
    {path: 'sub-17/plantel', component: Sub17Component},
    {path: 'sub-17/calendario', component: Sub17CalendarioComponent},
    {path: 'sub-17/resultados', component: Sub17ResultadosComponent},
    {path: 'sub-17/classificacao', component: Sub17ClassificacaoComponent},
    {path: 'sub-17/noticias', component: NoticiasFiltradasComponent},
    {path: 'sub-15/plantel', component: Sub15Component},
    {path: 'sub-15/calendario', component: Sub15CalendarioComponent},
    {path: 'sub-15/resultados', component: Sub15ResultadosComponent},
    {path: 'sub-15/classificacao', component: Sub15ClassificacaoComponent},
    {path: 'sub-15/noticias', component: NoticiasFiltradasComponent},
    {path: 'pestinhas/plantel', component: Sub14Component},
    {path: 'pestinhas/calendario', component: Sub14CalendarioComponent},
    {path: 'pestinhas/resultados', component: Sub14ResultadosComponent},
    {path: 'pestinhas/classificacao', component: Sub14ClassificacaoComponent},
    {path: 'pestinhas/noticias', component: NoticiasFiltradasComponent},
    {path: 'sub-13/plantel', component: Sub13Component},
    {path: 'sub-13/calendario', component: Sub13CalendarioComponent},
    {path: 'sub-13/resultados', component: Sub13ResultadosComponent},
    {path: 'sub-13/classificacao', component: Sub13ClassificacaoComponent},
    {path: 'sub-13/noticias', component: NoticiasFiltradasComponent},
    {path: 'sub-11/plantel', component: Sub11Component},
    {path: 'sub-11/calendario', component: Sub11CalendarioComponent},
    {path: 'sub-11/resultados', component: Sub11ResultadosComponent},
    {path: 'sub-11/classificacao', component: Sub11ClassificacaoComponent},
    {path: 'sub-11/noticias', component: NoticiasFiltradasComponent},
    {path: 'missao', component: MissaoComponent},
    {path: 'certificacao', component: CertificacaoComponent},
    {path: 'petizes', component: PetizesComponent},
    {path: 'traquinas', component: TraquinasComponent},
  ]},

  {path: 'modalidades', component: ModalidadesComponent, children: [
    {path: '', redirectTo: 'tenis', pathMatch: 'full' },
    {path: 'tenis', component: TenisComponent},
    {path: 'basquetebol', component: BasquetebolComponent},
    {path: 'atletismo', component: AtletismoComponent},
    {path: 'hipismo', component: HipismoComponent},
    /*{path: 'esports', component: EsportsComponent, children: [
      {path: 'proclubs', component: EsportsProclubsComponent},
      {path: '1v1', component: EsportsOneoneComponent},
      {path: 'staff', component: EsportsStaffComponent},
      {path: 'stream', component: EsportsStreamComponent}
    ]},*/
    {path: 'veteranos', component: VeteranosComponent}
  ]},
  {path: 'socios', component: SociosComponent, children: [
    {path: '', redirectTo: 'vantagem', pathMatch: 'full' },
    {path: 'vantagens', component: VantagensComponent},
    {path: 'quotas', component: QuotasComponent},
    {path: 'descontos', component: DescontosComponent},
    {path: 'ser-socio', component: SerSocioComponent},
  ]},
  {path: 'store', component: StoreComponent},
  {
    path: 'bilheteira',
    children: [
      { path: '**', component: BilheteiraComponent },
    ],
  },
  { path: 'faqs', component: FaqsComponent, pathMatch: 'full' },
  {path: 'informacao', component: InformacaoComponent, children: [
    {path: '', redirectTo: 'como-chegar', pathMatch: 'full' },
    {path: 'como-chegar', component: ComoChegarComponent},
  ]},
/*   {path: 'media', component: MediaComponent},*/
  {path: 'noticias', component: NoticiasComponent},
  {path: 'noticia/:id', component: NoticiaComponent},
  {path: 'termos', component: TermosComponent},
  {path: 'privacidade', component: PrivacidadeComponent},
  {path: 'cookies', component: CookiesComponent},
  {path: 'contactos', component: ContactosComponent},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
