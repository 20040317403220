<app-formacao-banner></app-formacao-banner>
<app-spinner *ngIf="state.loading"></app-spinner>

<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2">
            <div id="plantel" class="col-8 col-md-2 col-lg-2 centerTabs border-left border-right outline"
                routerLink="/formacao/traquinas" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">TRAQUINAS</span>
            </div>
        </div>
    </div>
</div>

<div  @fade *ngIf="!state.loading" class="container py-0 py-md-5">
    <div class="row labelPos d-flex align-items-center justify-content-center justify-content-md-start">
        <div class="col-12">
            <h2>
               TRAQUINAS<span></span>
            </h2>
        </div>
    </div>

    <div class="row px-3 px-md-0">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center  justify-content-md-start py-4 pb-md-0 borderBot" *ngFor="let el of traquinas">
            <div class="circle">
                <img src="assets/img/01.png" class="img-fluid player">
            </div>

            <div class="ml-3">
                <span class="playName">{{el.nome | uppercase}}</span><br>
            </div>
        </div>
    </div>

    <div class="row labelPos d-flex align-items-center pt-0 pt-md-5  justify-content-md-start" >
        <div class="col-12">
            <h2>
                <svg style="height:50px;" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs></defs><path class="cls-1" d="M238.35,189.56h23.3L266,171.19l-16-13.61-16,13.57Z"/><path class="cls-1" d="M166.5,116.23l33.73,69.23,40-33.73Z"/><path class="cls-1" d="M238.74,200.23l-24.4,164.08L250,403.12l35.66-38.81-24.4-164.08Z"/><path class="cls-1" d="M299.77,185.46l33.73-69.23-73.75,35.5Z"/><path class="cls-1" d="M330.13,105.91A303.93,303.93,0,0,0,250,95h-.14a304.73,304.73,0,0,0-80.09,10.8L250,144.53Z"/></svg>STAFF<span></span>
            </h2>
        </div>
    </div>

    <div class="row pt-0 py-0 py-md-3 px-3 px-md-0">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center  justify-content-md-start py-4 pb-md-0 borderBot" *ngFor="let tecnico of staff">
            <div class="circle">
                <img src="assets/img/01.png" class="img-fluid player">
            </div>

            <div class="ml-3">
                <span class="playName">{{tecnico.nome | uppercase}}</span><br>
                <span class="playPos">{{tecnico.posicao | uppercase}}</span>
            </div>
        </div>
    </div>

</div>
