import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-esports-navbar',
  templateUrl: './esports-navbar.component.html',
  styleUrls: ['./esports-navbar.component.scss']
})
export class EsportsNavbarComponent implements OnInit {

  activeLink: string = "active-link"

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects
       // set changeColor property with the value of the current route
        if(currentRoute.includes('historia') || currentRoute.includes('palmares') || currentRoute.includes('presidente')) {
          this.activeLink = 'active-link-2'
        } else {
          this.activeLink = 'active-link'
        }
      }
    });
   }

  ngOnInit(): void {
  }

}
