import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-init',
  templateUrl: './popup-init.component.html',
  styleUrls: ['./popup-init.component.scss']
})
export class PopupInitComponent implements OnInit {

  sub: Subscription[] = []
  popup: any = {}

  cookieState: boolean = true

  mostraBannerCookie: boolean = true

  constructor(private cookieService: CookieService, private data: DataService) {}

  ngOnInit(): void {
    this.getCookies()
    this.getPopUp()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  setCookies() {
    this.cookieService.set('loja', 'true', {expires: 1} )

    this.getCookies()
  }

  getPopUp() {
    this.sub.push(
      this.data.getPopUp().subscribe((res) => {
        this.popup = res
      })
    )
  }

  getCookies() {
    if(this.cookieService.get('loja')) {
      this.mostraBannerCookie = false
    } else {
      this.mostraBannerCookie = true
    }
  }

  goUrl() {
    if(this.popup.link !== null && this.popup.ativo == 1) {
      window.open(this.popup.link, '_blank')
    }
  }

}
