import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-chegar',
  templateUrl: './como-chegar.component.html',
  styleUrls: ['./como-chegar.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class ComoChegarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
