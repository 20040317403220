import { HostListener } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { jogos } from 'src/app/interfaces/calendario';
import { classificacao } from 'src/app/interfaces/classificacao';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})


export class HomepageComponent implements OnInit, OnDestroy {

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        stagePadding: 75,
        loop:true,
        margin:10
      },

      400: {
        items: 2,
        stagePadding: 50,
        loop:true,
        margin:10

      },

      540: {
        items: 2,
        margin:15
      },

      740: {
        items: 3,
        margin:15
      },

      940: {
        items: 4,
        margin:15
      }
    },
    nav: false
  }

/*   calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    initialView: 'dayGridMonth',
    events: [

      { title: '', date: '2020-10-02'},

    ],
    eventColor: 'red',
    locale: 'pt',
    firstDay: 1,
    themeSystem: 'bootstrap',
  }; */

  classificacao: classificacao[] = []
  jogo: jogos = {}
  sub: Subscription[] = []

  ultimasNoticias = []
  destaques = []

  arrayFilter: string[] = [
    'Equipa Principal',
    'Sub-19',
    'Sub-17',
    'Sub-15',
    'Sub-13',
    'Sub-14',
    'Sub-11',
    'Traquinas',
    'Petizes',
    'Pestinhas',
    'Ténis',
    'Hipismo',
    'Veteranos',
    'Esports Proclubs',
    'Esports 1v1',
    'Outro',
    'Basquetebol'
  ]

  currentWindowWidth: number;

  constructor(
    private dataService: DataService
  ) {

  }

  ngOnInit(): void {
    this.getRankings()
    this.getProximoJogo()
    this.getUltimasNoticias()
    this.getDestaques()

    this.changeImages()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  changeImages() {
    this.currentWindowWidth = window.innerWidth

    //desktop

    //menor 992

    //menor 768
  }

  /* @HostListener('window:resize', ['$event'])
  onResize(event) {

    console.log('janela janela', event.target.innerWidth)
  } */

  getRankings() {
    this.sub.push(this.dataService.getRankingParcial().subscribe(res => {
      this.classificacao = Array.isArray(res) ? res : [];
    }))
  }

  lojahover(item) {
    $('.'+item).addClass("buttonhover-shop");
  }
  lojaleave(item) {
    $('.btn--skew').removeClass("buttonhover-shop");
  }

  getProximoJogo() {
    this.sub.push(this.dataService.getCalendario().subscribe(res=> {
      this.jogo = res[0].jogos[0]
    }))
  }

  getUltimasNoticias() {
    this.sub.push(this.dataService.getNoticias(1).subscribe((res: any) => {
      this.ultimasNoticias = res.noticias.slice(0,5)

      this.ultimasNoticias.forEach(el => {
        el.escalao = this.arrayFilter[el.escalao-1]
      });



      if(window.innerWidth < 768) {
        this.ultimasNoticias[0].imagem_noticia = this.ultimasNoticias[0].imagem_listagem
        this.ultimasNoticias[1].imagem_noticia = this.ultimasNoticias[1].imagem_pequena
        this.ultimasNoticias[2].imagem_listagem = this.ultimasNoticias[2].imagem_pequena
        this.ultimasNoticias[3].imagem_listagem = this.ultimasNoticias[3].imagem_pequena
        this.ultimasNoticias[4].imagem_listagem = this.ultimasNoticias[4].imagem_pequena
      }

    }))
  }


  getDestaques() {
    this.sub.push(this.dataService.getNoticias(1).subscribe((res: any) => {
      this.destaques = res.destaques
      this.destaques.forEach(el => {
        el.escalao = this.arrayFilter[el.escalao-1]
      });

      if(window.innerWidth < 768) {
        this.destaques[0].imagem_full = this.destaques[0].imagem_listagem
      }
    }))
  }


}
