import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clube',
  templateUrl: './clube.component.html',
  styleUrls: ['./clube.component.scss']
})
export class ClubeComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}
