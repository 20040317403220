<div id="banner" class="container-fluid filterImg align-items-center fix-padding" [ngClass]="background ? 'bg-1' : 'bg-2'">
    <div class="container">
        <div class="row filterIndex">
            <div class="col-12 col-md-10">
                <p class="equipaTitulo d-md-flex" [ngClass]="background ? 'c1' : 'c2'">CD TONDELA</p>
                <p class="equipaDescricao">{{title | uppercase}}</p>
            </div>
        </div>
    </div>
</div>

