<app-info-banner></app-info-banner>
<app-info-navbar></app-info-navbar>
<div @fade class="container-fluid bg1">
    <div class="container py-5 px-0 px-md-5 bg2">


        <div class="accordion" id="accordionExample">

            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                aria-expanded="true" aria-controls="collapseThree">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">ADMINISTRAÇÃO SAD</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseThree"
                                    aria-expanded="true" aria-controls="collapseThree"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse show" aria-labelledby="headingThree">
                    <div class="card-body">

                        <h5>Presidente</h5>
                        <p>David Belenguer</p>

                        <h5>Administrador</h5>
                        <p>Miguel Angel Mendez</p>

                        <h5>Administrador</h5>
                        <p>Eduardo Silva</p>

                        <h5>Diretor Geral</h5>
                        <p>Nicola Ventra</p>

                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header cursor" id="headingFour" data-toggle="collapse" data-target="#collapseFour"
                aria-expanded="true" aria-controls="collapseFour">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">DIREÇÃO CLUBE</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFour"
                                    aria-expanded="true" aria-controls="collapseFour"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                    <div class="card-body bg1">

                        <h5>Presidente</h5>
                        <p>Gilberto Neves Coimbra</p>

                        <h5>Vice-Presidente - Gestão Financeira</h5>
                        <p>Vítor Amorim Lisboa Ferreira Sacras</p>

                        <h5>Vice-Presidente - Equipamentos E Instalações</h5>
                        <p>Flávio Coimbra Ferreira</p>

                        <h5>Vice-Presidente - Outras Atividades</h5>
                        <p>Eduardo Luís Rebelo Silva</p>

                        <h5>Vice-Presidente - Futebol de Formação</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Primeiro Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Segundo Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Terceiro Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Quarto Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Quinto Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Sexto Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Sétimo Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Oitavo Vogal - Diretor Executivo</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Suplente</h5>
                        <p>Adelino Fernando Correia Pedrosa</p>

                        <h5>Suplente</h5>
                        <p>José Fernando Carmo Ferreira</p>

                        <h5>Suplente</h5>
                        <p>Pedro Ramalho Martins</p>

                        <h5>Suplente</h5>
                        <p>Ana Lúcia Silva Santos de Matos</p>

                        <h5>Suplente</h5>
                        <p>António Joaquim de Matos</p>
                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header cursor" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
                aria-expanded="true" aria-controls="collapseFive">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">CONSELHO FISCAL</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFive"
                                    aria-expanded="true" aria-controls="collapseFive"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
                    <div class="card-body">

                        <h5>Presidente</h5>
                        <p>Luís Henrique Brás Marques</p>

                        <h5>Vice-Presidente</h5>
                        <p>Fernando Manuel Ferreira Clemente</p>

                        <h5>1º Secretário</h5>
                        <p>Carlos Luís Marques Machado</p>

                        <h5>2º Secretário</h5>
                        <p>Luís Duarte dos Santos Chaves</p>

                        <h5>Relator</h5>
                        <p>José Alves Pinto Pizarro</p>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">ASSEMBLEIA GERAL</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseTwo"
                                    aria-expanded="true" aria-controls="collapseTwo"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                    <div class="card-body bg1">

                        <h5>Presidente</h5>
                        <p>Carlos Manuel Antunes Amaral</p>

                        <h5>Vice-Presidente</h5>
                        <p>José da Cunha Fernandes</p>

                        <h5>1º Secretário</h5>
                        <p>Nuno Miguel Almeida Antunes</p>

                        <h5>2º Secretário</h5>
                        <p>José Manuel Pereira Mendes</p>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#equipatecnica"
                aria-expanded="true" aria-controls="equipatecnica">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">EQUIPA TÉCNICA</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#equipatecnica"
                                    aria-expanded="true" aria-controls="equipatecnica"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="equipatecnica" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body">

                        <h5>Treinador</h5>
                        <p>Pako Ayestarán</p>

                        <h5>Treinador Adjunto</h5>
                        <p>Joshe Viela</p>

                        <h5>Treinador Adjunto</h5>
                        <p>Sérgio Gaminha</p>

                        <h5>Analista</h5>
                        <p>Ricardo Alves</p>

                        <h5>Treinador Guarda-Redes</h5>
                        <p>Pedro Taborda</p>

                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#depfutebolprofissional"
                aria-expanded="true" aria-controls="depfutebolprofissional">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">DEPARTAMENTO FUTEBOL PROFISSIONAL</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#depfutebolprofissional"
                                    aria-expanded="true" aria-controls="depfutebolprofissional"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="depfutebolprofissional" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body bg1">

                        <h5>Diretor Desportivo</h5>
                        <p>Luís Agostinho</p>

                        <h5>Team Manager</h5>
                        <p>João Redondo</p>

                        <h5>Scouting</h5>
                        <p>Natcho Arenas</p>

                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#depmedico"
                aria-expanded="true" aria-controls="depmedico">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">DEPARTAMENTO MÉDICO</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#depmedico"
                                    aria-expanded="true" aria-controls="depmedico"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="depmedico" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body">

                        <h5>Médico</h5>
                        <p>Eduardo Mendes</p>

                        <h5>Fisioterapeuta</h5>
                        <p>João Carmo</p>

                        <h5>Enfermeiro</h5>
                        <p>António Mendes</p>

                        <h5>Massagista</h5>
                        <p>José Alves</p>

                        <h5>Fisioterapeuta</h5>
                        <p>Pedro Joel</p>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingFour" data-toggle="collapse" data-target="#estrutura-sad"
                aria-expanded="true" aria-controls="estrutura-sad">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">Estrutura Sad</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#estrutura-sad"
                                    aria-expanded="true" aria-controls="estrutura-sad"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="estrutura-sad" class="collapse" aria-labelledby="headingFour">
                    <div class="card-body bg1">

                        <h5>Contabilidade</h5>
                        <p>Carlos Machado</p>

                        <h5>Diretor de Comunicação</h5>
                        <p>Francisco Favinha</p>

                        <h5>Relações Públicas</h5>
                        <p>Tânia Barbosa</p>

                        <h5>Diretor Segurança</h5>
                        <p>Flávio Coimbra</p>

                        <h5>Coordenador Geral de Formação (SAD)</h5>
                        <p>Paulo Freitas</p>

                        <h5>Coordenador Geral de Formação (Clube)</h5>
                        <p>Rui Manaia</p>

                        <h5>Coordenador Técnico de Formação</h5>
                        <p>Tiago Veloso</p>

                        <h5>Team Manager/Formação</h5>
                        <p>Pedro Maneira</p>

                        <h5>OLA - O.Ligação Adeptos</h5>
                        <p>Francisco Fonseca</p>

                        <h5>Lonas ' Diretor de Campo</h5>
                        <p>António Carmo</p>

                        <h5>Loja</h5>
                        <p>Stefanie</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>José Pedro</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>Sr.Rolando</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>Júlio</p>

                        <h5>Limpeza</h5>
                        <p>Elisabete</p>

                        <h5>Motorista</h5>
                        <p>Luís Chaves</p>


                    </div>
                </div>
            </div>




            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#twoplay"
                aria-expanded="true" aria-controls="twoplay">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">2PLAY</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#twoplay"
                                    aria-expanded="true" aria-controls="twoplay"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="twoplay" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body">

                        <h5>Márcio Cortez</h5>
                        <p>CEO</p>

                        <h5>Diogo Seixas</h5>
                        <p>Marketing</p>

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header cursor" id="headingSix" data-toggle="collapse" data-target="#collapseSix"
                aria-expanded="true" aria-controls="collapseSix">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">RELATÓRIOS E CONTAS</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseSix"
                                    aria-expanded="true" aria-controls="collapseSix"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingSix">
                    <div class="card-body bg1">
                        <h5>Relatório e Contas 2019/2020</h5>
                       <!--  <p>Montante total pago no último período de reporte a agentes/intermediários: 75.618,65€</p> -->
                        <p>Consulte <a href="assets/data/relat%C3%B3rio.pdf" target="_blank">aqui</a> o Relatório de Contas da Época 2019/2020</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
