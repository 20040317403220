import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vantagens',
  templateUrl: './vantagens.component.html',
  styleUrls: ['./vantagens.component.scss']
})
export class VantagensComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0)
  }

}
