import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from '../../services/state.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(400),
      ])
    ]),
    trigger('blackDiv', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),
      transition(':leave', [
        animate(300, keyframes([
          style({ opacity: 0 }),
        ]))
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit {

  haveLogin$ = this.state._haveLogin;

  show: boolean = false;
  showSubMenu: number = 0;
  changeColor: string = 'default'
  cdtLogo: string = 'cdtLogo'

  showBlackDiv: boolean = false

  showSubDiv: number = 0

  public showSubSubDiv: number = 0;
  private appearTimeout: any = undefined;

  constructor(
    public state: StateService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects
        this.mouseLeave()
        // set changeColor property with the value of the current route
        if (currentRoute.includes('historia') || currentRoute.includes('palmares') || currentRoute.includes('presidente')) {
          this.changeColor = 'historia'
          this.cdtLogo = 'cdtLogoVintage'
        } else {
          this.changeColor = 'default'
          this.cdtLogo = 'cdtLogo mt-4'
        }
      }
    });
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.state.appear = true
    }
  }

  mouseEnter() {
    if (window.screen.width <= 767) {
      if (this.state.appear) this.state.appear = false
      this.state.appear = true
    } else {
      if (this.state.appear) {
        this.state.appear = false
      }
      const element = document.getElementById('bar')
      element.classList.add("sideBar-hover")
      this.showBlackDiv = true
      if (this.appearTimeout) {
        clearTimeout(this.appearTimeout);
      }
      this.appearTimeout = setTimeout(() => {
        this.state.appear = true
      }, 250)
    }
  }


  mouseLeave() {
    if (window.screen.width <= 767) {
      this.show = false
      this.state.appear = false
      this.showBlackDiv = false
      this.showSubMenu = 0;
      this.showSubDiv = 0;
      this.showSubSubDiv = 0;
    } else {
      if (!this.state.appear) {
        if (this.appearTimeout) {
          clearTimeout(this.appearTimeout);
        }
        this.appearTimeout = setTimeout(() => {
          var element = document.getElementById('bar')
          element.classList.remove("sideBar-hover")

          this.show = false
          this.state.appear = false
          this.showBlackDiv = false
          this.showSubMenu = 0;
          this.showSubDiv = 0;
          this.showSubSubDiv = 0;
        }, 250)
      } else {
        var element = document.getElementById('bar')
        element.classList.remove("sideBar-hover")

        this.show = false
        this.state.appear = false
        this.showBlackDiv = false
        this.showSubMenu = 0;
        this.showSubDiv = 0;
        this.showSubSubDiv = 0;
      }
    }
  }

  changeMenu() {
    if (!this.router.url.includes('home')) {
      window.location.href = '/home'
    }
  }

  menuToggle() {
    //fecha a navbar no mobile
    this.state.sidebar = false
  }

  goTo(url) {
    window.open(url, "_blank");
  }

  nextPageMember() {
    this.state.nextPage = 'membro';
  }

}
