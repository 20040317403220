import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { StateService } from '../services/state.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

  count = 0

  constructor(private state: StateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.count++;
    if(!request.url.includes("api.2ticket.pt"))
    this.state.loading = true

    if(!request.url.includes("dev.api.2ticket.pt"))
      this.state.loading = true

    return next.handle(request)
      .pipe(tap(), finalize(() => {

        this.count--;
        if (this.count == 0 && !request.url.includes("dev.api.2ticket.pt")) {
            setTimeout(()=> {
                this.state.loading = false
             }, 200)
        }
      })
      );
  }

 /*  Explanation: With each http request, the value of ‘count’ increases and the spinner is shown and when a response is received, the ‘count’ reduces by one.
  When all the http requests are finished( either successfully or with an error ), the value of ‘count’ reaches zero and the spinner is hid. */
}
