import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { noticias } from 'src/app/interfaces/noticias';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-veteranos',
  templateUrl: './veteranos.component.html',
  styleUrls: ['./veteranos.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class VeteranosComponent implements OnInit, OnDestroy {

  //noticias veteranos id = 13
  sub: Subscription[] = []
  news: noticias[]


  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getNoticias()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias() {
    this.sub.push(this.data.getNoticiasFiltradas(13).subscribe(res => {
      this.news = res
    }))
  }


}
