<div id="bar" class="sideBar" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"  [ngClass]="changeColor">
  <div class="row d-none d-md-flex">
    <div class="col-12 d-flex align-items-center cursor" >
      <div class="{{cdtLogo}} outline" (click)="changeMenu()"></div>
    </div>
  </div>

  <div class="row smart-scroll d-flex d-md-none align-items-center">
    <div class="col-8 d-flex align-items-center">
        <div class="{{cdtLogo}} outline" [routerLink]="['/']" (click)="menuToggle()"></div>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <img src="assets/img/close.svg" width="25px" height="25px" alt="close" class="mobile-buttons" (click)="menuToggle()">
    </div>
</div>

<div [ngClass]="showSubDiv == 2 ? 'centrar-formacao' : 'centrar-barra' ">
  <div class="iconsBar" [hidden]="show">

    <div class="row m-0 d-flex align-items-center justify-content-between cursor transition items-sidebar"
      (click)="show = true; showSubMenu = 1">
      <div class="col-12 border-item border-item1 text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <path class="cls-1"
                d="M249.87,445.17a13.54,13.54,0,0,1-8.75-3.21h0c-17.55-14.8-35-33.54-51.84-55.7a486.41,486.41,0,0,1-54.23-89.79c-17.54-37.88-31.53-79.36-42.76-126.8C90.7,163,89.36,156.85,88.18,151c-4.34-21.57-7.17-44.81-8.91-73.14A13.53,13.53,0,0,1,96.34,64c20.1,5.48,39.56,7.94,65.07,8.23,26.71.3,58.55-5.94,83.08-16.28a13.49,13.49,0,0,1,10.47,0,223.75,223.75,0,0,0,83.87,16.49c19.13-.12,47.28-2.92,63.9-8.79a13.53,13.53,0,0,1,18,13.14c-.76,28.34-5.28,56.74-9.45,81.14-2.38,13.86-5.58,28.85-9.77,45.82-4.88,19.67-11.68,45.44-21.09,70.36-4.42,11.72-8.93,22.24-13.78,32.18-28.78,59-65.39,104.86-108.82,136.36A13.53,13.53,0,0,1,249.87,445.17Zm.91-14.68h0ZM94.36,79c1.71,26.7,4.43,48.7,8.52,69.06,1.15,5.7,2.46,11.65,4,18.2,11,46.45,24.66,87,41.77,124a471.34,471.34,0,0,0,52.56,87c15.91,20.93,32.3,38.62,48.72,52.6,41-30,75.76-73.81,103.25-130.14,4.58-9.39,9-19.79,13.23-30.91,9.13-24.18,15.78-49.4,20.56-68.67,4.1-16.6,7.23-31.24,9.54-44.75,4-23.27,8.29-50.24,9.18-76.91-21,6.86-51.59,8.87-66.78,9A236.45,236.45,0,0,1,249.77,70c-26.22,11-60.08,17.53-88.52,17.23C135.22,86.9,115.07,84.43,94.36,79Z" />
              <path class="cls-1"
                d="M195.88,113.75v11.7a45.58,45.58,0,0,0-18.76-4.64c-6.51,0-13.59,4.55-13.25,14.29.3,8.67,6.3,12.15,14,12.2,6.29,0,14.14-1.69,19.08-6.24v11.11c-4.68,3.45-13.21,5.35-19.41,5.5-8,.19-15-1.92-19.78-6.2s-7.27-10.34-7.35-17.88c-.16-14.63,13.4-23.61,27-23.93C183,109.53,192.77,111.87,195.88,113.75Z" />
              <path class="cls-1"
                d="M232.43,157.26l-.16-47.46,21.92-.15c7.37-.06,28.2,4.43,27.24,25.47-.66,14.46-11,22-26,22.14Zm13.49-10h8.81c6,0,13.74-2.68,13.74-13.38,0-10.22-7.06-13.47-14.37-13.47h-8.18Z" />
              <polygon class="cls-1"
                points="320.41 157.41 320.41 120.67 302.9 120.67 302.9 109.7 349.36 109.7 349.36 120.67 333.89 120.67 333.89 157.41 320.41 157.41" />
              <path class="cls-1"
                d="M250.5,400a12.13,12.13,0,0,1-8.16-3.14c-25.51-23.13-51.05-59.43-70.05-99.59-15.79-33.39-28.67-69.68-36.24-102.17a12.14,12.14,0,0,1,11.83-14.89h0l204.24.28a12.15,12.15,0,0,1,12,14c-6.14,40.12-26.45,95.81-48.28,132.42-16.16,27.09-36.53,52-57.37,70.12A12.14,12.14,0,0,1,250.5,400ZM149.66,193.78c7.42,31.14,19.77,65.75,34.89,97.7,18,38.13,42,72.49,66,94.5C270,368.84,289,345.45,304.21,320c20.75-34.8,40.07-87.44,46.27-125.91Zm-1.78,0Z" />
              <rect class="cls-1" x="239.42" y="190" width="22" height="202" />
              <polygon class="cls-2" points="196 327 173.58 291 174 190 196 190 196 327" />
              <polygon class="cls-2" points="325.58 302 304 327 304 190 326 190 325.58 302" /></svg>
            <span *ngIf="state.appear" class="labelText">CD TONDELA</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"
      (click)="show = true; showSubMenu = 2">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500"
              style="enable-background:new 0 0 500 500;" xml:space="preserve">
              <path class="st0" d="M119.6,452.5c-10.1,0-18.2-8.2-18.2-18.2l0,0l-0.2-368.4c0-10.1,8.2-18.2,18.2-18.2l261-0.2l0,0
              c10.1,0,18.2,8.2,18.2,18.2c0,0,0,0,0,0l0.2,368.4c0,10.1-8.2,18.2-18.2,18.2L119.6,452.5L119.6,452.5z M116.4,434.3
              c0,1.8,1.5,3.2,3.2,3.2l0,0l261-0.2c1.8,0,3.2-1.5,3.2-3.2l-0.2-368.4c0-0.9-0.3-1.7-1-2.3c-0.6-0.6-1.4-1-2.3-1l0,0l-261,0.2
              c-1.8,0-3.2,1.4-3.2,3.2c0,0,0,0,0,0L116.4,434.3L116.4,434.3z" />
              <rect x="109.2" y="242.5" class="st0" width="281.5" height="15" />
              <circle class="st0" cx="250" cy="250" r="29.9" />
              <path class="st0" d="M250,287.4c-20.6,0-37.4-16.7-37.4-37.4c0-20.6,16.7-37.4,37.4-37.4c20.6,0,37.4,16.7,37.4,37.4c0,0,0,0,0,0
              C287.3,270.6,270.6,287.3,250,287.4z M250,227.6c-12.4,0-22.4,10-22.4,22.4c0,12.4,10,22.4,22.4,22.4s22.4-10,22.4-22.4
              C272.4,237.7,262.3,227.6,250,227.6L250,227.6z" />
              <path class="st0" d="M320.8,115.2H179.2V47.6h141.6V115.2z M194.2,100.2h111.6V62.6H194.2V100.2z" />
              <path class="st0" d="M320.8,452.4H179.2v-67.7h141.6V452.4z M194.2,437.4h111.6v-37.7H194.2V437.4z" />
            </svg>
            <span *ngIf="state.appear" class="labelText">Futebol</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar" (click)="show = true; showSubMenu = 3">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500"
              style="enable-background:new 0 0 500 500;" xml:space="preserve">
              <g id="rapidez-3">
                <g id="Grupo_378-3">
                  <g id="Grupo_377-3">
                    <path id="Caminho_145-3" class="st0" d="M312.8,28.5c-22.4,0-40.6,18.2-40.6,40.6l0,0v14.8c-0.4,22.4,17.4,41,39.8,41.4
            				c22.4,0.4,41-17.4,41.4-39.8c0-0.5,0-1,0-1.6V69.1C353.5,46.7,335.3,28.5,312.8,28.5L312.8,28.5L312.8,28.5z M338.7,83.9
            				c0.3,14.3-10.9,26.1-25.2,26.5c-14.3,0.3-26.1-10.9-26.5-25.2c0-0.4,0-0.8,0-1.3V69.1c0.3-14.3,12.2-25.6,26.5-25.2
            				c13.8,0.3,24.9,11.4,25.2,25.2V83.9z" />
                  </g>
                </g>
                <g id="Grupo_380-3">
                  <g id="Grupo_379-3">
                    <path id="Caminho_146-3" class="st0" d="M468.3,118.4L468.3,118.4l-22.2-14.8c-2.9-2-6.8-1.6-9.3,0.9l-62.8,62.7l-114.9-54
            				c-4.9-2.3-10.3-3.5-15.7-3.5h-80.7c-12.6,0-24.3,6.4-31.1,17l-57.7,89.8c-2.2,3.4-1.2,7.9,2.1,10.1l22.2,14.8
            				c3,2,7.1,1.5,9.5-1.2l64.2-71.4h42.8l-87.2,147.7H35.9c-4.1,0-7.4,3.3-7.4,7.4l0,0v57.3c0,9.1,7.3,16.5,16.5,16.5
            				c6.3,0,12-3.5,14.8-9.2l10.1-20.1l80.8,5.8c13.5,1,26.5-5.5,33.8-17l38-59.7l71.4,51.9l-14.3,113.8c-0.5,4,2.4,7.7,6.4,8.2
            				c0,0,0,0,0,0c0.3,0,0.6,0,0.9,0h56.9c9.4,0.1,17-7.5,17.1-16.9c0-5.8-2.8-11.1-7.6-14.3l-13.9-9.2l17.8-95
            				c2.6-14-3.1-28.3-14.6-36.7l-60.6-44l29.8-47.8l52.4,23.9c16.5,7.5,36,2,46-13.2l59.9-89.9C472.4,125.1,471.6,120.7,468.3,118.4z
            				 M58,359.1L46.5,382c-0.5,0.8-1.7,1.1-2.5,0.5c0,0,0,0,0,0c-0.4-0.3-0.7-0.7-0.8-1.3v-50H58L58,359.1z M198.3,154.1h-29.5
            				c-2.1,0-4.1,0.9-5.5,2.4l-62.2,69.1l-10.8-7.2l53.9-83.7c4.1-6.3,11.1-10.2,18.6-10.1h35.6L198.3,154.1z M172.1,349.4
            				c-4.4,6.9-12.2,10.8-20.3,10.2l-78.9-5.6v-22.6h59.1c2.6,0,5-1.4,6.4-3.6l6.6-11.1l36.5,18.2L172.1,349.4z M345.1,452.7
            				c1,0.6,1.4,2,0.7,3c0,0,0,0,0,0c-0.4,0.7-1.1,1.1-1.9,1.1h-48.5l1.8-14.8h31.9L345.1,452.7z M334,311.5c6.9,5,10.3,13.6,8.7,21.9
            				l-17.6,93.8h-26.1l10-80.3c0.3-2.7-0.8-5.3-3-6.9l-10.9-7.9l22-32.9L334,311.5z M305.3,290.6l-22,32.9L224.9,281
            				c-3.3-2.4-7.9-1.7-10.3,1.6l-0.3,0.4l-24.9,39.2l-37-18.5l43.2-73l72.9,33.2L305.3,290.6z M312.3,191.7c-3.4-1.5-7.3-0.3-9.3,2.8
            				L269.4,248l-66.3-30.1l31.2-52.7c2.1-3.5,0.9-8-2.5-10.1c0,0,0,0,0,0c-1.1-0.7-2.5-1-3.8-1h-14.8v-29.5h30.3
            				c3.3,0,6.5,0.7,9.4,2.1l91.8,43.2l-14.9,29.8L312.3,191.7z M398.2,210.3c-6,9.1-17.7,12.4-27.6,7.9l-27.4-12.5l14.8-29.6
            				l14.4,6.8c2.8,1.3,6.2,0.7,8.4-1.5l62.2-62.2l11,7.3L398.2,210.3z" />
                  </g>
                </g>
              </g>
            </svg>

            <span *ngIf="state.appear" class="labelText">Modalidades</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"
      (click)="show = true; showSubMenu = 4">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <path class="cls-1"
                d="M68.57,388.11a17.52,17.52,0,0,1-17.5-17.49l-.14-228a17.5,17.5,0,0,1,17.49-17.51l365-.22h0a17.52,17.52,0,0,1,17.5,17.49l.14,228a17.5,17.5,0,0,1-17.49,17.51l-365,.22ZM433.43,139.89h0l-365,.22a2.49,2.49,0,0,0-2.5,2.5l.14,228a2.5,2.5,0,0,0,2.5,2.5h0l365-.22a2.49,2.49,0,0,0,2.5-2.5l-.14-228A2.5,2.5,0,0,0,433.43,139.89Z" />
              <path class="cls-1"
                d="M110.51,274.05A17.11,17.11,0,0,1,93.41,257l0-79.4a17.09,17.09,0,0,1,17.1-17.11l79.34,0h0a17.12,17.12,0,0,1,17.11,17.09l.05,79.4A17.12,17.12,0,0,1,189.87,274l-79.35,0Zm79.3-98.65h0l-79.35,0a2,2,0,0,0-1.48.61,2.07,2.07,0,0,0-.62,1.49l0,79.4a2.07,2.07,0,0,0,.62,1.49,2,2,0,0,0,1.48.61h0l79.34,0A2.12,2.12,0,0,0,192,256.9l-.05-79.4A2.11,2.11,0,0,0,189.81,175.4Z" />
              <rect class="cls-1" x="229" y="228" width="178.25" height="15.92" rx="7.96" />
              <rect class="cls-1" x="229" y="195" width="133.98" height="15.92" rx="7.96" />
              <rect class="cls-1" x="91" y="332" width="320" height="15.92" rx="7.77" />
            </svg>
            <span *ngIf="state.appear" class="labelText">Sócios</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"
    (click)="show = true; showSubMenu = 10">
    <div class="col-12 border-item text-light">
      <div class="row">
        <div class="col-10">
         <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs>></defs><path class="cls-1" d="M196.73,461.66c-.32,0-.63,0-.95,0a18,18,0,0,1-11.7-5.33l-38-38,3.55-5.4c8.57-13,5.66-31.62-6.92-44.24s-31.21-15.58-44.26-7l-5.4,3.54L54.74,326.78c-6.81-6.82-7.13-17.59-.7-24l260.31-260c6.43-6.42,17.19-6.09,24,.73L377,82.17l-3,5.24c-7.37,13-4.11,30.56,7.92,42.63s29.58,15.37,42.64,8l5.25-3L467.7,173c6.81,6.82,7.12,17.59.7,24L208.08,457A16.06,16.06,0,0,1,196.73,461.66Zm-30.62-45.8,29.19,29.23a1.89,1.89,0,0,0,1.63.68l260.25-260a2.06,2.06,0,0,0-.71-1.57L426.8,154.52c-18.36,7.27-40.63,2.22-56.1-13.28s-20.44-37.75-13.16-56.08L327.13,54.7A1.9,1.9,0,0,0,325.5,54L65.25,314a2.12,2.12,0,0,0,.71,1.57l29.56,29.6c18.65-8.62,42.27-3.94,58.44,12.27S174.73,397.24,166.11,415.86Z"/><rect class="cls-1" x="329.58" y="78.5" width="15.87" height="193" transform="translate(-25.03 289.46) rotate(-44.93)"/><rect class="cls-1" x="172.5" y="310.35" width="178.25" height="15.92" rx="7.96" transform="translate(-148.4 277.71) rotate(-44.93)"/><rect class="cls-1" x="169.81" y="288.5" width="133.98" height="15.92" rx="7.96" transform="translate(-140.21 253.8) rotate(-44.93)"/></svg>
          <span *ngIf="state.appear" class="labelText">Bilheteira</span>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>
    </div>
  </div>-->

    <a href="https://cdtondela.pt/loja/" target="_blank" style="text-decoration: none;">
    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <path class="cls-1"
                d="M383.44,405.73H101.5a7.48,7.48,0,0,1-7.45-8.37l29.82-256.19a7.5,7.5,0,0,1,7.45-6.64h222.3a7.5,7.5,0,0,1,7.45,6.64l29.82,256.19a7.48,7.48,0,0,1-7.45,8.37Zm-273.52-15H375L347,149.53H138Z" />
              <path class="cls-1"
                d="M296,179.52a7.5,7.5,0,0,1-7.5-7.5V120.35a46,46,0,1,0-92.08,0V172a7.5,7.5,0,1,1-15,0V120.35a61,61,0,1,1,122.08,0V172A7.49,7.49,0,0,1,296,179.52Z" />
            </svg>
            <span *ngIf="state.appear" class="labelText">Loja</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    </a>

    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar outline" [routerLink]="['/noticias']"
    (click)="menuToggle()">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">

            <svg class="icons"  id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><defs></defs><path class="cls-1" d="M419.21,73H141.32a7.79,7.79,0,0,0-7.79,7.79v29.35H111.05a7.8,7.8,0,0,0-7.78,7.79v36.24H80.79A7.79,7.79,0,0,0,73,162V389A38.09,38.09,0,0,0,111.05,427H389A38.09,38.09,0,0,0,427,389V80.79A7.79,7.79,0,0,0,419.21,73ZM149.11,389V296.09a7.79,7.79,0,0,0-15.58,0V389a22.48,22.48,0,0,1-44.95,0V169.74h14.69V382.07a7.79,7.79,0,1,0,15.57,0V125.72h14.69V234.18a7.79,7.79,0,0,0,15.58,0V88.58H411.42V389A22.5,22.5,0,0,1,389,411.42H141.74A38.15,38.15,0,0,0,149.11,389Z"/><path class="cls-1" d="M174.34,183.5H386.19a7.79,7.79,0,0,0,7.79-7.79V126.19a7.79,7.79,0,0,0-7.79-7.79H174.34a7.79,7.79,0,0,0-7.79,7.79v49.52A7.79,7.79,0,0,0,174.34,183.5Zm7.78-15.58V134H378.41v33.94Z"/><path class="cls-1" d="M174.34,348.59H280.27a7.8,7.8,0,0,0,7.78-7.79V208.73a7.79,7.79,0,0,0-7.78-7.79H174.34a7.79,7.79,0,0,0-7.79,7.79V340.8A7.8,7.8,0,0,0,174.34,348.59Zm98.14-132.07V333H182.12V216.52Z"/><path class="cls-1" d="M386.19,200.94H313.28a7.79,7.79,0,1,0,0,15.58h72.91a7.79,7.79,0,1,0,0-15.58Z"/><path class="cls-1" d="M386.19,234H313.28a7.79,7.79,0,1,0,0,15.57h72.91a7.79,7.79,0,1,0,0-15.57Z"/><path class="cls-1" d="M386.19,267H313.28a7.79,7.79,0,1,0,0,15.58h72.91a7.79,7.79,0,1,0,0-15.58Z"/><path class="cls-1" d="M386.19,300H313.28a7.79,7.79,0,1,0,0,15.58h72.91a7.79,7.79,0,1,0,0-15.58Z"/><path class="cls-1" d="M386.19,333H313.28a7.79,7.79,0,0,0,0,15.58h72.91a7.79,7.79,0,1,0,0-15.58Z"/><path class="cls-1" d="M394,373.81a7.8,7.8,0,0,0-7.79-7.79H174.34a7.79,7.79,0,1,0,0,15.58H386.19A7.79,7.79,0,0,0,394,373.81Z"/><path class="cls-1" d="M141.66,257.34a7.8,7.8,0,1,0,5.51,2.29A7.83,7.83,0,0,0,141.66,257.34Z"/></svg>
            <span *ngIf="state.appear" class="labelText">Notícias</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />

            </svg>

          </div>
        </div>
      </div>
    </div>

     <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"  [routerLink]="['/bilheteira/lista-eventos']"
     (click)="menuToggle()">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
          <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <path class="cls-1"
              d="M196.73,461.66c-.32,0-.63,0-.95,0a18,18,0,0,1-11.7-5.33l-38-38,3.55-5.4c8.57-13,5.66-31.62-6.92-44.24s-31.21-15.58-44.26-7l-5.4,3.54L54.74,326.78c-6.81-6.82-7.13-17.59-.7-24l260.31-260c6.43-6.42,17.19-6.09,24,.73L377,82.17l-3,5.24c-7.37,13-4.11,30.56,7.92,42.63s29.58,15.37,42.64,8l5.25-3L467.7,173c6.81,6.82,7.12,17.59.7,24L208.08,457A16.06,16.06,0,0,1,196.73,461.66Zm-30.62-45.8,29.19,29.23a1.89,1.89,0,0,0,1.63.68l260.25-260a2.06,2.06,0,0,0-.71-1.57L426.8,154.52c-18.36,7.27-40.63,2.22-56.1-13.28s-20.44-37.75-13.16-56.08L327.13,54.7A1.9,1.9,0,0,0,325.5,54L65.25,314a2.12,2.12,0,0,0,.71,1.57l29.56,29.6c18.65-8.62,42.27-3.94,58.44,12.27S174.73,397.24,166.11,415.86Z" />
            <rect class="cls-1" x="329.58" y="78.5" width="15.87" height="193"
              transform="translate(-25.03 289.46) rotate(-44.93)" />
            <rect class="cls-1" x="172.5" y="310.35" width="178.25" height="15.92" rx="7.96"
              transform="translate(-148.4 277.71) rotate(-44.93)" />
            <rect class="cls-1" x="169.81" y="288.5" width="133.98" height="15.92" rx="7.96"
              transform="translate(-140.21 253.8) rotate(-44.93)" /></svg>
          <span class="labelText" *ngIf="state.appear">Bilheteira</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"
      (click)="show = true; showSubMenu = 7">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <g id="Icon_feather-user" data-name="Icon feather-user">
                <path class="cls-1"
                  d="M372,405.55h0l-244.2-.22a7.45,7.45,0,0,1-7.43-7.45l0-36.78c0-44.63,30.78-80.93,68.51-80.93h.06l122.1.11c37.76,0,68.45,36.4,68.41,81.06l0,36.78A7.45,7.45,0,0,1,372,405.55ZM135.19,390.46l229.33.21,0-29.34c0-36.46-24-66.14-53.55-66.17l-122.1-.11h0c-29.54,0-53.6,29.63-53.63,66.07Z" />
                <path class="cls-1"
                  d="M250.08,252.61H250a81.06,81.06,0,0,1,.07-162.11h.08a81.06,81.06,0,0,1-.08,162.11Zm0-147.11A66.06,66.06,0,0,0,250,237.61h.06a66.06,66.06,0,0,0,.06-132.11Z" />
              </g>
            </svg>
            <span class="labelText" *ngIf="state.appear">Conta</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0 d-flex align-items-center justify-content-between cursor items-sidebar"
      (click)="show = true; showSubMenu = 8">
      <div class="col-12 border-item text-light">
        <div class="row">
          <div class="col-10">
            <svg class="icons" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <path class="cls-1"
                d="M250.5,429.5c-107,0-194-87-194-194s87-194,194-194,194,87,194,194S357.47,429.5,250.5,429.5Zm0-370c-97.05,0-176,78.95-176,176s78.95,176,176,176,176-78.95,176-176S347.55,59.5,250.5,59.5Z" />
              <path class="cls-1" d="M262.29,164.79A11.79,11.79,0,1,1,250.5,153,11.79,11.79,0,0,1,262.29,164.79Z" />
              <path class="cls-1"
                d="M250.5,318a8.84,8.84,0,0,1-8.84-8.84V217.82H229.88a8.84,8.84,0,1,1,0-17.68H250.5a8.84,8.84,0,0,1,8.84,8.84V309.16A8.84,8.84,0,0,1,250.5,318Z" />
              <path class="cls-1" d="M277,318H224a8.84,8.84,0,0,1,0-17.68h53A8.84,8.84,0,0,1,277,318Z" /></svg>
            <span class="labelText" *ngIf="state.appear">Info</span>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="iconsBar" *ngIf="show && showSubMenu == 1">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">CD TONDELA</h1>
      </div>
    </div>

    <div class="container p-0" @fadeIn>
      <div class="row m-0 border-item border-item1 items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/historia']"
          (click)="menuToggle();">
          <p class="cursor m-0 labelText">História</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/palmares']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Palmarés</p>
        </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>


      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/presidente']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Presidente</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/estrutura']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Estrutura</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/estadio']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Estádio</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>


      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/clube/academia']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Academia</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="iconsBar" *ngIf="show && showSubMenu == 2">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false; showSubDiv = 0">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Futebol</h1>
      </div>
    </div>

    <div class="container p-0" @fadeIn>
      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" (click)="showSubDiv = 1">
          <p class="cursor m-0 labelText">Seniores</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>


      <!-- SUB DIV EQUIPA PRINCIPAL -->

      <div class="pl-3" *ngIf="showSubDiv == 1" @fadeIn>
        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Plantel</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/resultados']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Resultados</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/calendario']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Calendário</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/classificacao']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Classificação</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/staff']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">staff</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/futebol/equipa-principal/noticias']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">notícias</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
      </div>

      <!-- formacao -->
      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" (click)="showSubDiv = 2">
          <p class="cursor m-0 labelText">FORMAÇÃO</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <!-- SUB DIV formacao -->

      <div class="pl-3" *ngIf="showSubDiv == 2" @fadeIn>
        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/missao']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Missão</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/certificacao']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Certificação</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/estrutura']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Estrutura</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/sub-19/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Sub-19</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline"  [routerLink]="['/formacao/sub-17/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Sub-17</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline"  [routerLink]="['/formacao/sub-15/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Sub-15</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline"  [routerLink]="['/formacao/sub-13/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Sub-13</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/sub-11/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Sub-11</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/traquinas']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Traquinas</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/formacao/petizes']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Petizes</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline"  [routerLink]="['/formacao/pestinhas/plantel']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Pestinhas</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

      </div>
      <!--  -->

            <!--  -->
    </div>
  </div>

  <div class="iconsBar" *ngIf="show && showSubMenu == 3">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Modalidades</h1>
      </div>
    </div>

    <div class="container p-0" @fadeIn>
      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/tenis']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Ténis</p>
        </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          	c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/basquetebol']"
            (click)="menuToggle()">
            <p class="cursor m-0 labelText">Basquetebol</p>
          </div>
            <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
              <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
                <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
              c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
              </svg>
            </div>
          </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/hipismo']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Hipismo</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/veteranos']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Veteranos</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/atletismo']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Atletismo</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <!--div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" (click)="showSubDiv = 3">
          <p class="cursor m-0 labelText">eSports</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div-->

      <!-- SUB DIV Esports -->

      <div class="pl-3" *ngIf="showSubDiv == 3" @fadeIn>
        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/esports/staff']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Staff</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/esports/1v1']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">1v1</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

<!--         <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/esports/staff']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Staff</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>
        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/modalidades/esports/stream']" (click)="menuToggle()">
            <p class="cursor m-0 labelText">Stream</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div> -->
      </div>
    </div>
    </div>


  <div class="iconsBar" *ngIf="show && showSubMenu == 4">

    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Sócios</h1>
      </div>
    </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/socios/vantagens']" (click)="menuToggle()">
          <p class="cursor m-0 labelText">Vantagens</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

        <div class="row m-0 border-item items-sidebar2 cursor">
          <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/socios/quotas']"
            (click)="menuToggle()">
            <p class="cursor m-0 labelText">Quotas</p>
          </div>
          <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
            <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
            c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
            </svg>
          </div>
        </div>

      <!--     <div class="row m-0 border-item items-sidebar2 cursor">
            <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/socios/descontos']"
              (click)="menuToggle()">
              <p class="cursor m-0 labelText">Descontos</p>
            </div>
            <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
              <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
                <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
              c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
              </svg>
            </div>
          </div>  -->

          <div class="row m-0 border-item items-sidebar2 cursor">
            <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/socios/ser-socio']"
              (click)="menuToggle()">
              <p class="cursor m-0 labelText">Ser Sócio</p>
            </div>
            <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
              <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
                <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
              c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
              </svg>
            </div>
          </div>
  </div>




  <div class="iconsBar" *ngIf="show && showSubMenu == 7">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Conta</h1>
      </div>
    </div>


    <div class="container p-0" @fadeIn>

      <div class="row m-0 border-item items-sidebar2 cursor" *ngIf="!(haveLogin$ | async)">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/login']"
             (click)="menuToggle(); nextPageMember()">
          <p class="cursor m-0 labelText">Login</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf=" state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor" *ngIf="haveLogin$ | async">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/editar-perfil']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Perfil</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor" *ngIf="(haveLogin$ | async) && false">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/editar-perfil']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Compras</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor" *ngIf="(haveLogin$ | async)  && false">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/lista-eventos']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Bilhetes</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor" *ngIf="(haveLogin$ | async) && false">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/quotas']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Pagar Quotas</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
          c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="iconsBar" *ngIf="show && showSubMenu == 8">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Info</h1>
      </div>
    </div>


    <div class="container p-0" @fadeIn>
 <!--      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/informacao/institucional']"
          (click)="menuToggle()">
          <p class="cursor m-0 labelText">Institucional</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
            style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
                c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div> -->

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/informacao/como-chegar']" (click)="menuToggle()">
          <p class="cursor m-0 labelText">Como Chegar</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
            style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
                c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/contactos']" (click)="menuToggle()">
          <p class="cursor m-0 labelText">Contactos</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
            style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
                c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>
    </div>
  </div>





  <div class="iconsBar" *ngIf="show && showSubMenu == 10">
    <div class="row m-0 submenuselect cursor ">
      <div class="col-12 d-flex align-items-center justify-content-between colorText" (click)="show = false">
            <svg version="1.1" class="arrowright cursor" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
              style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
              <path d="M10.3,0.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L5.7,7.3l5.3,5.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-6-6
            	c-0.4-0.4-0.4-1,0-1.4l6-6C9.8,0.4,10,0.3,10.3,0.3z" />
            </svg>
        <h1 class="m-0 lh-submenu labelText">Bilheteira</h1>
      </div>
    </div>


    <div class="container p-0" @fadeIn>

      <div class="row m-0 border-item items-sidebar2 cursor">
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/bilheteira/jogos']" (click)="menuToggle()">
          <p class="cursor m-0 labelText">Jogos</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
            style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
                c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>

      <div class="row m-0 border-item items-sidebar2 cursor" >
        <div class="col-10 d-flex align-items-center lh-submenu cursor outline" [routerLink]="['/faqs']" (click)="menuToggle()">
          <p class="cursor m-0 labelText">Faqs</p>
        </div>
        <div class="col-2 pl-2 icon-fixposition" *ngIf="state.appear">
          <svg class="arrowright" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
            style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
            <path d="M4.3,14.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3L3.6,2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6,6
                c0.4,0.4,0.4,1,0,1.4l-6,6C4.8,14.2,4.6,14.3,4.3,14.3z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
<div @blackDiv *ngIf="showBlackDiv" class="sidenav-overlay"></div>
