import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-socios-sidebar',
  templateUrl: './socios-sidebar.component.html',
  styleUrls: ['./socios-sidebar.component.scss']
})
export class SociosSidebarComponent implements OnInit {

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.scrollBar()
  }

  scrollBar() {
    let el = document.getElementById('ser-socio-nav').offsetWidth
    if(this.router.url.includes('vantagens')) {
      $('.hRow2').scrollLeft(0)
    }
    if(this.router.url.includes('quotas')) {
      $('.hRow2').scrollLeft(el)
    }
    /* if(this.router.url.includes('descontos')) {
      $('.hRow2').scrollLeft(el*2)
    } */
    if(this.router.url.includes('ser-socio')) {
      $('.hRow2').scrollLeft(el*2)
    }
  }

}
