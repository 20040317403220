import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-clube-header',
  templateUrl: './clube-header.component.html',
  styleUrls: ['./clube-header.component.scss']
})
export class ClubeHeaderComponent implements OnInit {

  title: string = ''
  background: boolean
  
  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        let url = e.urlAfterRedirects

        if(url.includes('historia')) {
          this.title = 'a nossa história'
          this.background = true
        }
        if(url.includes('palmares')) {
          this.title = 'palmarés'
          this.background = true
        }
        if(url.includes('presidente')){
          this.title = 'Mensagem do Presidente'
          this.background = true
        }
        if(url.includes('estadio')) {
          this.title = 'o nosso estádio'
          this.background = false
        }
        if(url.includes('academia')) {
          this.title = 'a nossa academia'
          this.background = false
        }
        if(url.includes('estrutura')) {
          this.title = 'estrutura'
          this.background = false
        }
      }
    })
  }


  ngOnInit(): void {
  }

}
