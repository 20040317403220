<div class="container-fluid bg filterImg align-items-center fix-padding">
    <div class="container">
        <div class="row filterIndex">
            <div class="col-12 col-md-10">
                <p class="equipaTitulo d-md-flex">{{title | uppercase}}</p>
                <p class="equipaDescricao">eSports</p>
            </div>
        </div>
    </div>
</div>
