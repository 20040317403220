<app-formacao-banner></app-formacao-banner>
<div @fade class="container-fluid p-0 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">ENTIDADE FORMADORA 4 ESTRELAS</p>
        <p class="descEst text-justify">
            O Clube Desportivo de Tondela foi de novo certificado pela Federação Portuguesa de Futebol como uma entidade formadora quatro estrelas.
            O nosso emblema recebeu, uma vez mais, o reconhecimento por parte da FPF relativamente ao trabalho que desenvolveu no último ano em todo o universo dos escalões de formação.
        </p>
        <p class="descEst text-justify">
            Cientes que seguimos o nosso caminho e que em breve poderemos dar novos passos com a concretização da da Academia, reafirmamos que o nosso objetivo continua a ser só um - formar além de atletas, homens com valores.
        </p>
    </div>

    <div class="container-fluid bg_3 mb-lg-5">
        <div class="container fix-mobile-padding">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/img/entidade_formadora.jpg" alt="Missão">
                            </div>
                            <!-- <div class="carousel-item">
                                <img class="d-block w-100" src="assets/img/missao/2.jpg" alt="Missão">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/img/missao/1.jpg" alt="Missão">
                            </div> -->
                        </div>
                       <!--  <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a> -->
                    </div>
 
                </div>
            </div>
        </div>
    </div>
</div>