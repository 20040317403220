import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';


@Component({
  selector: 'app-navbar-generic',
  templateUrl: './navbar-generic.component.html',
  styleUrls: ['./navbar-generic.component.scss']
})
export class NavbarGenericComponent implements OnInit {
  constructor(
    private router: Router,
    public state: StateService
  ) { }
  ngOnInit(): void {
  }

  scrollBar() {
    let el = document.getElementById('chegar-nav').offsetWidth

    if(this.router.url.includes('como-chegar')) {
      $('.hRow2').scrollLeft(0)
    }

    if(this.router.url.includes('contactos')) {
      $('.hRow2').scrollLeft(el)
    }
  }
}
