<app-formacao-banner></app-formacao-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading" class="container-fluid py-5">
    <div class="container bg-news">
        <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
            <div class="col-lg-5">
                <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>
               <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-4">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>

                <h2 class="news-title">{{new.titulo}}</h2>
                <p class="news-date">{{new.data}}</p>
                <p class="news-subtitle">{{new.subtitulo}}
                </p>
                <div class="button arrow">Ler Notícia</div>
            </div>
        </div>
    </div>

    <div *ngIf="news.length == 0" class="container">
        <h1 class="tit">SEM NOTÍCIAS PARA APRESENTAR</h1>

        <div class="button arrow" [routerLink]="['/noticias']">Notícias principais</div>
    </div>
</div>
