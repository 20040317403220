<div class="row bg-white border">
    <div class="col-12 col-md-6 p-0 hRow">
        <div class="calendar-header bgh1">
            <i class="fa fa-chevron-left pl-4" (click)="prevMonth()"></i>
            <div class="date">
                <h3 class="m-0">{{date.format('MMMM') | uppercase}}</h3>
                <p class="m-0">{{date.format('YYYY')}}</p>
            </div>
            <i class="fa fa-chevron-right pr-4" (click)="nextMonth()"></i>
        </div>
        <div class="weekdays">
            <div>SEG</div>
            <div>TER</div>
            <div>QUA</div>
            <div>QUI</div>
            <div>SEX</div>
            <div>SAB</div>
            <div>DOM</div>
        </div>
        <div class="days">
            <div class="outline0" tabindex="1" *ngFor="let day of daysArr" [ngClass]="{'inactive': !day, 'today': today(day), 'event': addEvents(day)}" (click)="getDay(day)">{{day?.date()}}</div>
        </div>
    </div>
    <div class="col-12 col-md-6 p-0">
        <div class="calendar-header bgh2">
            <h3 class="m-0 pl-5">LISTA DE EVENTOS</h3>
        </div>
        <div class="events-list">
            <div class="events-details" *ngIf="eventDay.dia != ''">
                <p class="m-0 data">{{eventDay.dia}} DE {{eventDay.mes | uppercase}} DE {{eventDay.ano}}</p>
                <p class="m-0 hora">{{eventDay.dia_semana | uppercase}} | 19:30</p>
                <p class="m-0 nome">Evento-exemplo</p>
            </div>
        </div>
    </div>
</div>

    