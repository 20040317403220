
<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2" (click)="scrollBar()">
            <div id="vantagens-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline" [routerLink]="['/socios/vantagens']" routerLinkActive="active-link">
                <span class="tabs mx-auto">VANTAGENS</span>
            </div>
            <div id="quotas-nav" #quot class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline" [routerLink]="['/socios/quotas']" routerLinkActive="active-link">
                <span class="tabs mx-auto">QUOTAS</span>
            </div>
          <!--   <div id="descontos-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline" [routerLink]="['/socios/descontos']" routerLinkActive="active-link">
                <span class="tabs mx-auto">DESCONTOS</span>
            </div> -->
            <div id="ser-socio-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline border-right" [routerLink]="['/socios/ser-socio']" routerLinkActive="active-link">
                <span class="tabs mx-auto">SER SÓCIO</span>
            </div>
        </div>
    </div>
</div>