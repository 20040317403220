<app-clube-header></app-clube-header>
<app-clube-navbar></app-clube-navbar>

<div @fade class="container-fluid bg2">
  <div class="container py-5">
    <p class="tit mb-4">HISTÓRIA</p>
    <p class="desc">O sonho cumpre-se numa longa história. O Clube Desportivo de Tondela (C.D.T.) com 89 anos de história, confirma um legado e currículo de importância extrema na região da Beira Alta.</p>
    <!-- vertical timeline -->
    <section class="cd-timeline js-cd-timeline">
      <div class="container max-width-lg cd-timeline__container my-5">
        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
  
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">6 DE JUNHO DE 1933</span>
            </div>
              <h2>Data de Fundação</h2>
              <p class="color-contrast-medium text-justify">As forças vivas de Tondela decidiram fazer da união bandeira e se procedeu à fusão dos dois grandes clubes de então na localidade.
              Tondela Futebol Club e Operário Atlético Clube, com poucos anos de vida, colocaram as diferenças de parte e num nobre
              gesto de cidadania e desportivismo uniram forças, levando à fusão daqueles dois emblemas, para fundar o verdadeiro e
              grandioso Clube Desportivo de Tondela.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">17 DE SETEMBRO DE 1933</span>
            </div>
            <p class="color-contrast-medium text-justify">Por ocasião das Festas de Santa Eufémia, padroeira de Tondela, é inaugurado o novo Campo Desportivo do Pereiro (atual estádio João Cardoso), o primeiro campo oficial do clube localizado num terreno cedido pelo benfeitor tondelense João Cardoso. A sua inauguração coincidiu com o primeiro jogo oficial da história do Clube Desportivo de Tondela na abertura do campeonato 1933/1934 com o União Foot Ball Coimbra Club. Gravado na nossa história ficaram os nomes dos jogadores que constituíram a primeira equipa de futebol do clube, são eles: Aníbal Silva Mocho, Armelim do Carmo, João do Carmo, Álvaro de Matos, Herculano Nunes, Fernando Vale, José do Carmo Gonçalves, Luiz Mota, José Luiz Ferrador, António Teles, Tonito Ferrador e José Matos Fortunato. A primeira direção do Desportivo tinha como Presidente o Sr. Dr. José Tavares.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1936/1937</span>
            </div>
            <h2>Primeira competição nacional</h2>
            <p class="color-contrast-medium text-justify">O Clube Desportivo de Tondela compete pela primeira vez num campeonato à escala nacional, participando no denominado “Campeonato da II Liga” constituído por 39 equipas, subdivididas em 10 séries, 9 delas com 4 clubes e 1 de 3 clubes. O Desportivo integrou a Série 4 juntamente com o SP. Espinho, AC. Viseu e AD Ovarense.</p>
          </div>
        </div>


        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">JUNHO DE 1941</span>
            </div>
            <h2>Primeiro Título</h2>
            <p class="color-contrast-medium text-justify">Não durou muito até que o CD Tondela alcançasse os seus primeiros feitos. Sete épocas depois, a equipa sénior sagrar-se-ia campeã distrital da 1.ª Divisão, feito repetido no ano imediatamente a seguir.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1944/1945</span>
            </div>
            <p class="color-contrast-medium text-justify">Depois de uma participação honrosa em 1936/1937 no “Campeonato da II Liga”, o Tondela voltaria ao convívio entre clubes de maior nomeada com o regresso a esta divisão, permanecendo nesta competição por duas épocas consecutivas, ambas na Série 6, em 1944/1945 (Tondela, Sanjoanense, Viseu e Benfica, Anadia e Lusitânia de Lourosa) e 1945/1946 (Tondela, Marinhense, Sport, Lusitânia Coimbra e Naval 1º de Maio). Estas fases constituíram os pontos mais altos dos auriverdes na década de 40, iniciada com uma participação em 1941/1942 também na II Divisão - Série 4, mas competindo numa “subsérie 1”.</p>
          </div>
        </div>


        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1950</span>
            </div>
            <h2>Homenagem a João Cardoso e à sua família</h2>
            <p class="color-contrast-medium text-justify">O Tondela comemora o primeiro título de Campeão Distrital da 1ª Divisão da AF Viseu, depois de no início da década se ter sagrado por duas vezes Campeão Distrital da 2ª Divisão. Nesse mesmo ano, faleceu João Cardoso, personalidade tondelense que dá nome ao atual estádio. Lavrador abastado, tanto ele como depois a família (particularmente os filhos Adriano Cardoso e Felícia Cardoso também estimados e homenageados pelo CD Tondela), foram nomes queridos na localidade, disponíveis para resolver assuntos pessoais de amigos e vizinhos. João Cardoso esteve ligado a tudo o que havia de humanitário na região, desde associações culturais e desportivas de Tondela, a associações de socorros mútuos, bombeiros, a sopa dos pobres, as cantinas escolares e até à feitoria do Hospital Cândido de Figueiredo. Em 1923, assim, cedeu o terreno onde se jogava futebol na zona onde se realiza a feira semanal de Tondela (junto ao atual Parque Urbano da Cidade). Mais tarde, cedeu um terreno maior onde está o atual estádio.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1953/1954</span>
            </div>
            <h2>Regresso aos campeonatos nacionais</h2>
            <p class="color-contrast-medium text-justify">O regresso do Clube Desportivo de Tondela aos campeonatos nacionais depois de sete épocas consecutivas nos campeonatos distritais. O Desportivo participa pela primeira vez na sua história no recém-criado Campeonato Nacional da III Divisão. Nesta década, voltaria a conseguir presença nesta competição também em 1957/1958, integrando nestas duas épocas mencionadas a 3ª Série. Depois desta época, o clube demorou largos anos para conseguir voltar a competir nos campeonatos nacionais.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1967</span>
            </div>
            <h2>Participação no Campeonato Nacional de Juvenis</h2>
            <p class="color-contrast-medium text-justify">O Clube Desportivo de Tondela participa pela primeira vez no Campeonato Nacional de Juvenis. Um marco importante para os escalões de formação do clube.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1976</span>
            </div>
            <h2>Regresso à 3ª Divisão Nacional</h2>
            <p class="color-contrast-medium text-justify">Depois do interregno mais longo da história do Clube Desportivo de Tondela, com a ausência de participações nos campeonatos nacionais, é no pós “25 de abril” que os auriverdes conseguem o tão desejado regresso à 3ª Divisão Nacional, depois de no inicio desta década o clube ter começado a dar sinais de querer voltar regressar a um patamar onde já tinha estado. com a conquista de um título de Campeões Distritais de Juniores em 1974/1975 e na época seguinte, em 1975/1976, uma subida da 1ª Divisão Distrital à 3ª Divisão Nacional. Estes títulos associam-se diretamente a uma aposta do clube numa geração de jovens jogadores talentosos da região oriundos dos escalões de formação que ajudaram a catapultar o Desportivo para uma fase de maior estabilidade nos nacionais. Por lá, permaneceu continuadamente durante nove épocas até ao ano de 1985, consolidando-se e projetando o seu nome no panorama nacional com a realização de algumas campanhas memoráveis.</p>
          </div>
        </div>
  
        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1980</span>
            </div>
            <h2>Primeiro treinador estrangeiro</h2>
            <p class="color-contrast-medium text-justify">João Augusto Alves é o primeiro treinador estrangeiro do Clube Desportivo de Tondela. O treinador de nacionalidade brasileira orientou o CDT em 1980/1981. No decorrer desta década, o CDT não viveu só de Futebol. A modalidade de Râguebi foi também ela implementada e dinamizada com entusiasmo no clube.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">MAIO DE 1985</span>
            </div>
            <h2>CHEGADA AOS CAMPEONATOS NACIONAIS</h2>
            <p class="color-contrast-medium text-justify">Clube Desportivo de Tondela chegou à 3.ª Divisão Nacional. Em 1985, o clube sagra-se campeão distrital e ascende pela primeira vez a uma prova da Federação Portuguesa de Futebol, onde ficou até 1989.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1986</span>
            </div>
            <p class="color-contrast-medium text-justify">Campeão Distrital da AF Viseu em 1985/1986 e nova subida à 3ª Divisão Nacional. Um ano após ter descido ao Campeonato da 1ª Distrital da AF Viseu depois de anos memoráveis nos campeonatos nacionais, o Tondela volta rapidamente a uma divisão onde cimentou o seu lugar principalmente na segunda metade da década de 70 e na maioria das épocas da década de 80. Esta década marcaria também uma grande novidade nos equipamentos oficiais do Clube Desportivo de Tondela com o surgimento de patrocínios na parte da frente da camisola, algo inédito até aqui. A empresa &quot;Beirafrio - indústria de produtos alimentares&quot; foi o primeiro patrocinador oficial a figurar nas camisolas auriverdes.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1990</span>
            </div>
            <h2>Denominação do Estádio</h2>
            <p class="color-contrast-medium text-justify">O Campo Desportivo do Campo do Pereiro que foi a casa do clube desde a sua fundação passa a denominar-se de Estádio João Cardoso por homenagem do CDT ao homem que cedeu os terrenos para a construção do campo de futebol. Ainda neste ano, foi oferecido ao Clube Desportivo de Tondela pelo Sr. Rui Almiro Figueiredo (proprietário da antiga Sociedade de Transportes do Caramulo, Lda.) o seu primeiro autocarro que os seus sócios e adeptos batizaram carinhosamente de “A Vaidosa” que “desfilava” pelo país ostentando no seu exterior as cores branca, verde e amarela.</p>
          </div>
        </div>

        
  
        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">DE 1990/91 A 93/94</span>
            </div>
            <h2>Subida à 2ª Divisão Nacional</h2>
            <p class="color-contrast-medium text-justify">Marca o início de uma década de grandes feitos e conquistas, numa “ascensão meteórica” do clube desde à 2ª Divisão Distrital da AF Viseu em 1991 até à 2ª Divisão Nacional da Federação Portuguesa de Futebol em 1994. Em quatro épocas, três subidas de divisão e participação e quatro escalões competitivos diferentes! O Tondela volta a momentos de glória. Campeão da 2ª Divisão Distrital (Série Sul) em 1990/1991, Campeão Distrital da 1ª Divisão Distrital em 1991/1992 com subida ao campeonato da 3ª Divisão Nacional (um regresso depois de 4 épocas nos distritais desde 1988) e uma nova subida de Divisão da 3ª Divisão Nacional à 2ª Divisão Nacional B, feito alcançado por ter sido vice-campeão no campeonato de 1993/1994, logo na segunda época de regresso aos nacionais.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">1994</span>
            </div>
            <h2>Remodelação do Estádio João Cardoso (do lado esquerdo) (a seguir à subida à 2ª divisão)</h2>
            <p class="color-contrast-medium text-justify">O Clube Desportivo de Tondela dá início na época 1994/1995 à primeira grande remodelação do Estádio João Cardoso com o espaço a sofrer durante esse ano obras de melhoramento que dotaram o piso do campo de futebol até aqui de terra batida com relva natural que seria obrigatório para o clube cumprir com as regras exigidas pela Federação Portuguesa de Futebol para competir no seu estádio na 2ª Divisão Nacional. Foi ainda aumentada a capacidade do estádio com o crescimento significativo das bancadas. Pelo seu estádio estar interdito por obras de grande envergadura ao longo desta época, com o clube a ter de jogar obrigatoriamente num campo relvado, a sua Direção optou por jogar todos os jogos oficiais na condição de visitado no Complexo Desportivo de Canas de Senhorim, que tinha um dos poucos campos já relvados nesta época. Mesmo neste cenário de extrema dificuldade com o Clube Desportivo de Tondela a jogar 34 jornadas fora do Estádio João Cardoso, os beirões conquistam uma manutenção memorável, salvando-se na última jornada com uma vitória no Ribatejo diante do União de Santarém por 2-1. O Desportivo voltaria a competir oficialmente no seu estádio a partir de agosto de 1995, depois de inaugurar oficialmente o novo relvado com um jogo amigável entre as velhas glórias da equipa de Veteranos do CDT com o CA Molelos. Curiosamente, na época seguinte, em 1995/1996, o clube voltaria a conseguir a sua segunda manutenção consecutiva, também na última jornada do campeonato com um empate precioso a uma bola no reduto do União Torrense.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">MAIO DE 2005</span>
            </div>
            <h2>Regresso aos nacionais de futebol</h2>
            <p class="color-contrast-medium text-justify">O clube regressa aos Nacionais de onde nunca mais saiu até hoje. Durante quatro épocas, o Desportivo lutou por outros vôos e em 2009 consegue o título de Campeão Nacional da 3.ª Divisão.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">2008</span>
            </div>
            <h2>Fundação da claque oficial “Febre Amarela”</h2>
            <p class="color-contrast-medium text-justify">Ano de fundação da “Febre Amarela”, claque oficial do Clube Desportivo de Tondela. Neste mesmo ano, é também criado o futsal. Esta modalidade funcionou no clube em 2008/2009, voltando a ser aposta três anos depois em 2011/2012 com o Pavilhão Municipal de Tondela a acolher os treinos e jogos da modalidade.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">2011/2012</span>
            </div>
            <h2>Chegada ao futebol profissional</h2>
            <p class="color-contrast-medium text-justify">Já na 2.ª Divisão Nacional, bastaram três épocas de um CD Tondela sempre perto do topo da classificação para que mais um sonho se realizasse pois em 2011/2012 os auriverdes vencem a Zona Centro e garantem a subida à Segunda Liga num play-off disputado com Varzim e Fátima.
              Escreveu-se história! O C.D. Tondela chega ao futebol profissional!</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
  
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">24 DE MAIO DE 2015</span>
            </div>
            <h2>Subida à primeira liga</h2>
            <p class="color-contrast-medium text-justify">O concretizar de um sonho que se tornou realidade no inesquecível dia de 24 de Maio de 2015 quando o CD Tondela, culminando uma época áurea no segundo patamar do futebol português, garante em Freamunde a inédita mas tão esperada subida à Primeira Liga e ainda lhe junta o título de campeão da Segunda Liga.</p>
          </div>
        </div>


        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">2018</span>
            </div>
            <h2>Melhor participação na Liga NOS</h2>
            <p class="color-contrast-medium text-justify">O Clube Desportivo de Tondela obtém a sua melhor participação de sempre na Liga NOS classificando-se em 11º lugar com um registo de 38 pontos conquistados na época 2017/2018. Para além dos brilhantes resultados no Futebol com a equipa principal e escalões de formação, o clube conseguiu projetar-se cada vez mais também por força da revitalização da secção de Veteranos e através das modalidades amadoras, com o ressurgimento do Râguebi e Futsal. Foram ainda criadas novas modalidades que mantêm a sua atividade desportiva como o Ténis, e- Sports e o Hipismo. Uma década de ouro na história do Clube Desportivo de Tondela com a ascensão em 2012 aos campeonatos profissionais e em 2015 à Liga NOS, o escalão mais alto do futebol português.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">Maio de 2022</span>
            </div>
            <h2>Final da Taça de Portugal</h2>
            <p class="color-contrast-medium text-justify">A 22 de Maio o Clube Desportivo de Tondela alcançou algo que até então nunca tinha acontecido: a presença na final da Prova Rainha. Depois de eliminar a AD Camacha, Leixões, Estoril, Rio Ave e Mafra, os auriverdes disputaram a final da Taça com o FC Porto. Até então, o melhor que os beirões tinham alcançado eram os oitavos-de-final, acabando por fazer história frente ao Estoril quando garantiram a passagem aos quartos-de- final. Num duelo agendado para as 17:15, a equipa saiu do Jamor com a medalha de finalista vencido após a derrota por 3-1. Neto Borges, de cabeça, foi o homem que fez o golo de honra dos tondelenses.</p>
          </div>
        </div>

        <div class="cd-timeline__block">
          <div class="cd-timeline__img">
            <div class="square"></div>
          </div> 
          <div class="cd-timeline__content text-component">
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date datas">Julho de 2022</span>
            </div>
            <h2>Supertaça Cândido Oliveira</h2>
            <p class="color-contrast-medium text-justify">Após a presença no Jamor, esta foi a segunda vez que CD Tondela e FC Porto se encontraram em finais.</p>
          </div>
        </div>
      </div>
    </section>
    <p class="desc text-center">Mas não só de seniores vive o “museu” do CDT pois o clube garantiu também, ao longo destes anos, alguns troféus importantes na sua formação e, nomeadamente, nas suas equipas de futebol de 11.</p>

    <p class="desc text-center">
      O Tondela conta com três títulos de Campeão Distrital de Juniores da AF Viseu (1974/1975, 1982/1983 e 2012/2013), um de Campeão Nacional da 2ª Divisão (2017/2018) e ainda uma presença na Fase Final de Apuramento de Campeão Nacional da 1ª Divisão (2018/2019). Nos Juvenis, são dois os títulos de campeão distrital (1993/1994 e 2013/2014) e um de Iniciados (2013/2014). Em 2003 e 2006, a equipa júnior do CD Tondela alcançou a fase final de acesso aos nacionais, embora não tenha conseguindo o título e a desejada subida, o mesmo aconteceu com o escalão de Juvenis em 2012/2013 e Iniciados em 1998/1999 e 2012/2013. Outros feitos grandiosos e memoráveis da formação auriverde foram as duas subidas em 2015 e 2018 do Campeonato Nacional da 2ª Divisão para o Campeonato Nacional da 1ª Divisão no escalão de Juniores.
    </p>


    <p class="desc text-center">
      Nesta campanha promissora não queremos nem desejamos que se coloque um ponto final, mas ansiamos sim que uma vírgula nos permita nos próximos anos aumentar esta história com os mais belos capítulos da vida do Clube Desportivo de Tondela.
    </p>
  </div>
</div>