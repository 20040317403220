import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { jogador } from 'src/app/interfaces/jogadores';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-equipa-principal',
  templateUrl: './equipa-principal.component.html',
  styleUrls: ['./equipa-principal.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class EquipaPrincipalComponent implements OnInit, OnDestroy {

  guardaRedes: jogador[]
  defesas: jogador[]
  medios: jogador[]
  atacantes: jogador[]

  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);

    this.getPlayers()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getPlayers() {
    this.sub.push(this.dataService.getPlayers().subscribe(res => {
      this.guardaRedes = res.filter(res => res.abrevposicao == "GR")
      this.defesas = res.filter(res => res.abrevposicao == "DEF")
      this.medios = res.filter(res => res.abrevposicao == "MED")
      this.atacantes = res.filter(res => res.abrevposicao == "ATA")
    }))
  }

  jogadorHide(jogador){
    $('#'+jogador).hide();
    $('#'+jogador+' img').removeClass('fade-in');
    $('#'+jogador+'_hide').show();
    $('#'+jogador+'_hide img').addClass('fade-in');
  }
  jogadorShow(jogador){
    $('#'+jogador+'_hide').hide();
    $('#'+jogador+'_hide img').removeClass('fade-in');
    $('#'+jogador).show();
    $('#'+jogador+' img').addClass('fade-in');
  }



}
