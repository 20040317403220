<div class="container-fluid bg1">
    <div class="container py-5 py-lg-0">
        <div class="row py-lg-0">
            <div class="col-lg-4 col-xl-5 d-none d-lg-block">
                <img class="img-fluid" src="assets/img/adeptos2.png">
            </div>
            <div class="col-lg-8 col-xl-7 align-self-center">
                <div class="row">
                    <div class="col-12">
                        <h4 class="vantagens mt-lg-0">VANTAGENS PARA SÓCIOS</h4>
                    </div>
                </div>
                <div class="row mt-lg-4">
                    <div class="col-md-6 mt-3 mt-lg-0 d-flex align-items-start">
                        <img class="w-25" src="assets/icons/socio1.svg">
                        <div class="ml-lg-1">
                            <p class="labelVantagens my-0">Merchandising do clube</p>
                            <p class="textoSocios">Desconto nos artigos da loja (física ou online), excepto
                                promoções.
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3 mt-lg-0 d-flex align-items-start">
                        <img class="w-25" src="assets/icons/socio4.svg">
                        <div class="ml-lg-1">
                            <p class="labelVantagens my-0">Eventos especiais</p>
                            <p class="textoSocios">Possibilidade de levar um acompanhante gratuito para o jogo.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-lg-2">
                    <div class="col-md-6 mt-3 mt-lg-0 d-flex align-items-start">
                        <img class="w-25" src="assets/icons/socio2.svg">
                        <div class="ml-lg-1">
                            <p class="labelVantagens my-0">Bilhetes</p>
                            <p class="textoSocios">Usufrua de vantagens nos bilhetes de jogos em casa.</p>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3 mt-lg-0 d-flex align-items-start">
                        <img class="w-25" src="assets/icons/socio3.svg">
                        <div>
                            <p class="labelVantagens my-0">Jogos Fora</p>
                            <p class="textoSocios">Desconto no preço das excursões dos jogos fora.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>