<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2">
            <div id="plantel" class="col-8 col-md-2 col-lg-2 centerTabs border-left outline"
                routerLink="{{equipa}}plantel" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">PLANTEL</span>
            </div>
            <div id="result" class="col-8 col-md-2 col-lg-2 centerTabs border-left outline"
                routerLink="{{equipa}}resultados" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                <span class="tabs mx-auto">RESULTADOS</span>
            </div>
            <div id="calend" class="col-8 col-md-3 col-lg-2 centerTabs border-left outline"
                routerLink="{{equipa}}calendario" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">CALENDÁRIO</span>
            </div>
            <div id="classi" class="col-8 col-md-3 col-lg-2 centerTabs border-left outline"
                [ngClass]="equipa === '/futebol/equipa-principal/' ? '' : 'border-right' "
                routerLink="{{equipa}}classificacao" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">CLASSIFICAÇÃO</span>
            </div>

            <div *ngIf="equipa === '/futebol/equipa-principal/'" id="eq-tecnica"
                class="col-8 col-md-2 col-lg-2 centerTabs border-left border-right outline" routerLink="{{equipa}}staff"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">STAFF</span>
            </div>

            <div id="noticias" class="col-8 col-md-3 col-lg-2 centerTabs outline border-right"
                routerLink="{{equipa}}noticias" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">NOTÍCIAS</span>
            </div>
        </div>
    </div>
</div>
<!--
*ngIf="equipa ==  '/futebol/equipa-principal/'" -->



<!--    <div id="calend" class="col-8 col-md-3 col-lg-2 centerTabs border-left outline"
                routerLink="{{equipa}}calendario" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">CALENDÁRIO</span>
            </div> -->