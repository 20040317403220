<div class="container pb-5 bg1 position-relative">
    <div class="row">
        <div class="col-12 text-center justify-content-center d-block d-lg-none pt-5">
            <p class="labelPositions">STAFF</p>
        </div>
        <div class="d-none d-lg-block pos-label">
            <p class="labelPositions">STAFF</p>
        </div>
    </div>

    <!-- DESKTOP -->
    <div class="col-12 flex-column">
        <div class="row d-flex justify-content-center ml-xl-5">
            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/joaovelez.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/joaovelez.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">João Velez<br>
                            <span class="yellow-text">"EBILLIBONG"</span></p>
                    </div>
                </div>
                <div class="col-12 py-3 d-none">
                    <p class="texto-main">Desde 2017 ao leme de um
                        dos primeiros projetos reconhecidos em Portugal, João Velez pendurou o comando e deixou o
                        EBILLIBONG de lado para agora ser "apenas" coordenador da seção de Esports do CD Tondela. 3
                        anos de constante crescimento não apagam o sentimento do bebé que criou e que quer continuar
                        a ver brilhar. A promessa aponta ao êxito e o trabalho é o caminho para lá chegar.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 col-xl-4 text-center mt-lg-5 mt-2">
                <img class="w-100 d-block d-md-none" src="assets/img/esports/rodrigolobato.jpg" />
                <div class="img__wrap d-none d-md-block">
                    <img class="img__img" src="assets/img/esports/rodrigolobato.jpg" />
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="player-item-info d-flex align-items-center p-0">
                        <p class="text-center player-item-name mb-0">Rodrigo Lobato <br>
                            <span class="yellow-text">"LOBATOV"</span></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
