<app-info-banner></app-info-banner>
<div class="container-fluid bg1">
    <div class="container bg2 py-5 px-0 px-lg-5">
        <div class="row mt-5">
            <div class="col-12">
                <h3>1. RESPONSÁVEL PELO WEBSITE<span></span></h3>
                <p class="text-justify mt-2">1.1. Estes Termos e Condições de Utilização (doravante os “Termos e Condições”)
                    regem a
                    utilização do website www.cdtondela.pt (doravante “website”) cuja gestão é da responsabilidade do
                    Clube Desportivo de
                    Tondela, com sede no Complexo Desportivo Estádio João Cardoso, Rua Dr. Eurico José Gouveia, n.º 365,
                    3460-580
                    Tondela, com o NIPC 505 212 722.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>2. OBJETO<span></span></h3>
                <p class="text-justify mt-2">2.1. Estes Termos e Condições são destinados aos Utilizadores que pretendam
                    obter informações sobre o Clube Desportivo de Tondela, que tenham a intenção de adquirir produtos na
                    Loja Online do website e que pretendam inscrever-se como sócios do Clube Desportivo de Tondela.
                    
                   <p class="text-justify">
                       2.2. O website permite a qualquer pessoa consultar as informações disponibilizadas sobre o Clube
                    Desportivo de Tondela, estando a utilização da Loja Online sujeita ao disposto na condição de utilizador.
                   </p> 

                   <p class="text-justify">  2.3. A inscrição de Sócio do Clube Desportivo de Tondela poderá ser realizada através do website, sendo
                    contudo tal inscrição regida pelos Estatutos do Clube Desportivo de Tondela e demais documentação
                    que venha a ser entregue ao novo Sócio.</p>
                   <p class="text-justify">   2.4. O Utilizador, ao utilizar o website e/ ou Loja Online, aceita os Termos e Condições e declara para
                    os devidos efeitos que tem capacidade jurídica para contratar e assumir todas as obrigações
                    associadas a tal contratação.</p>
                    
                 
                    
                 
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>3. UTILIZAÇÃO DO WEBSITE<span></span></h3>
                <p class="text-justify mt-2">3.1. O acesso e navegação no website são gratuitos e não requerem registo. No
                    entanto, para fazer qualquer compra na Loja cumprir com o disposto na condição de utilizador.
                    <p class="text-justify">
                        3.2. O acesso e navegação no website implica a aceitação expressa e sem reservas dos Termos e Condições.
                    </p> 
                    <p class="text-justify">
                    3.3. A aceitação e cumprimento dos Termos e Condições será obrigatória para qualquer Utilizador que
                    aceda, navegue ou utilize o website. Se não concordar com os presentes Termos e Condições
                    solicitamos que não utilize o website.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>4. CONDIÇÃO DE UTILIZADOR<span></span></h3>
                <p class="text-justify mt-2">4.1. Por forma a poder utilizar a Loja Online e, nesse sentido, adquirir o merchandising
                    do Clube Desportivo de Tondela, será necessário efetuar o preenchimento de um
                    formulário sendo para tal necessário que seja maior de idade.
                <p class="text-justify">
                    4.2. No formulário disponibilizado para o efeito deverão ser indicados todos os dados
                    identificados como sendo obrigatórios, a saber: nº de sócio, nome completo,
                    morada, código postal, localidade, país, e-mail, nº de identificação fiscal e
                    contacto telefónico.
                </p>
                <p>4.3. Ao preencher o formulário, compromete-se a:</p>
                <ul>
                    <li class="text-justify">4.3.1. Fornecer apenas dados verdadeiros em todas as ações relacionadas com o
                        Clube Desportivo de Tondela e fornecer todos
                        os dados necessários e indicados como obrigatórios.
                    </li>
                    <li class="text-justify">4.3.2. Não se envolver em atividades ilícitas ou contrárias à boa-fé e ordem
                        pública, ou utilizar o website para qualquer
                        um desses propósitos.
                    </li>
                    <li class="text-justify">4.3.3. Não causar danos aos sistemas físicos e lógicos do Clube Desportivo de
                        Tondela, aos seus fornecedores, terceiros ou
                        introduzir ou espalhar um vírus na rede informática ou qualquer outro sistema físico ou lógico
                        que sejam capazes de
                        causar os danos acima mencionados.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>5. INSCRIÇÃO COMO SÓCIO DO CLUBE DESPORTIVO DE TONDELA<span></span></h3>
                <p class="text-justify mt-2">5.1. Poderá, através do formulário disponibilizado para o efeito, solicitar a
                    sua inscrição como sócio do Clube Desportivo de Tondela.
                    <p class="text-justify">
                        5.2. Após preencher o formulário referido na cláusula anterior ser-lhe-á enviado um e-mail com as
                        informações relativas ao pagamento das quotas devidas, bem como com toda a documentação relativa ao
                        Estatuto de Sócio do Clube Desportivo de Tondela.
                    </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>6. PROCEDIMENTO DE COMPRA DE PRODUTOS<span></span></h3>
                <p class="text-justify mt-2">
                    6.1. Conforme anteriormente referido, por forma a poder realizar uma compra na Loja Online é necessário
                   o preenchimento do formulário indicado no ponto 4.1.
                    
                    <p class="text-justify">
                        
                    6.2. Caso deseje adquirir um dos vários produtos
                    disponíveis na Loja Online, deverá pressionar
                    o botão designado para o efeito e adicionar o produto pretendido ao seu carrinho devendo
                    posteriormente preencher o
                    formulário pedido. </p>

                    <p class="text-justify">
       
                    6.3. O Utilizador deverá confirmar a encomenda dos produtos adicionados ao seu
                    carrinho ao pressionar o botão que refere
                    Encomendar e pagar.
                    
                    </p>

                    <p class="text-justify">
                        
                    6.4. O Utilizador finaliza a sua compra ao prosseguir para a etapa de pagamento.
                    Qualquer encomenda confirmada implica
                    especialmente a aceitação do preço correspondente ao(s) Produto(s) adquirido(s). A encomenda só será
                    definitiva quando o
                    preço correspondente tiver sido pago. O Utilizador garante que tem pleno direito a utilizar os meios
                    de pagamento que
                    utiliza.
                    </p>
             
                <p class="text-justify mt-2">
                    6.5. Após a finalização da encomenda o Utilizador irá receber um e-mail (no endereço de correio
                    eletrónico registado) a confirmar os detalhes da mesma.</p>
                    <p class="text-justify">
                         6.6. Após receber o e-mail mencionado no número anterior, o Utilizador confirmará a compra pressionando o
                    link contido no
                    e-mail.  
                    </p>
                    <p class="text-justify">
                        6.7. Uma vez confirmada a encomenda nos termos indicados na cláusula anterior, o Utilizador irá
                    receber uma última
                    confirmação da encomenda para o endereço de correio eletrónico registado com a conta de Utilizador.
                    Este e-mail enumera
                    os montantes faturados, as quantidades e a descrição dos produtos adquiridos. Todos os documentos
                    acima mencionados
                    poderão ser imprimidos e constituirão o contrato num formato duradouro. O Utilizador aceita que os
                    registos informáticos
                    da encomenda são prova da referida encomenda e da sua data.  
                    </p>
                    <p class="text-justify">
                    6.9. No caso dos produtos encomendados não estarem disponíveis após a confirmação
da encomenda pela Clube Desportivo de Tondela, o Utilizador será informado pelo
Clube Desportivo de Tondela por e-mail do cancelamento total ou parcial
(conforme o caso) da sua encomenda. Neste caso, o Utilizador poderá efetuar
uma nova encomenda.</p>
                
                
                <p class="text-justify">
                    6.9. Qualquer encomenda anormal ou colocada de má-fé, qualquer fraude ou tentativa de fraude, qualquer
                    incidente relativo ao
                    pagamento do preço pode resultar na eliminação e/ou desativação da conta do cliente envolvido e/ou
                    na recusa da
                    encomenda.
                </p>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <h3>7. PREÇOS<span></span></h3>
                <p class="text-justify mt-2">
                    7.1. Os preços e ofertas apresentadas são válidos, exclusivamente, para encomendas realizadas na Loja
                    Online e apenas para o momento em que é consultado, uma vez que podem sofrer alterações. O preço que
                    será cobrado pela compra será aquele que corresponde ao(s) produto(s) adquirido(s) no momento da
                    formalização da encomenda, nos termos indicados na cláusula anterior.
                </p>    
                <p class="text-justify">
                    7.2. O valor dos custos de entrega é calculado automaticamente de acordo com cada encomenda, aparecendo
                    tal valor no resumo da encomenda do Utilizador, antes da confirmação da encomenda (conferir cláusula
                    6.3). Assim, no momento da compra do Produto, o Utilizador conhece exatamente o preço total em Euros
                    (€), incluindo quaisquer taxas e impostos aplicáveis, bem como encargos de transporte, entrega,
                    despesas postais ou outros encargos. Além disso, uma vez confirmada a encomenda, o preço é
                    confirmado no e-mail de confirmação enviado ao Utilizador.    
                </p>
                <p class="text-justify">
                    7.3. Caso sejam devidos quaisquer encargos suplementares de transporte, entrega e postais, o Utilizador
                    será informado dos mesmos antes do momento de confirmação da encomenda.    
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>8. CUSTOS DE ENVIO<span></span></h3>
                <p class="text-justify mt-2">
                    8.1. Os custos de envio e/ou gestão, que não estão incluídos no preço, serão mostrados antes de finalizar
                    a compra, pois podem variar consoante a morada de envio.
                </p>
                <p class="text-justify">
                    8.2. Nenhum valor será cobrado por custos de manuseamento e entrega quando o pedido exceder o valor
                    mínimo anunciado em cada caso no website.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>9. MÉTODO DE PAGAMENTO<span></span></h3>
                <p class="text-justify mt-2">
                    9.1. O pagamento no website pode ser feito através de cartão crédito e/ou VISA, ou pagamento através de
                    entidade e referência. A utilização destes meios de pagamento não implica qualquer custo adicional.
      </p>
      <p class="text-justify">
        9.2. Se optar por pagar usando um cartão crédito e/ou VISA, será redirecionado para o site correspondente
                    onde, depois de aceder, poderá efetuar o pagamento.                
    </p>
    <p class="text-justify">
          9.3. O Utilizador garante que possui as autorizações necessárias para utilizar o método de pagamento
                    escolhido.              
    </p>
                    

                    
          
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>10. ENVIOS E PRAZOS DE ENTREGA<span></span></h3>
                <p class="text-justify mt-2">10.1. Os pedidos feitos na Plataforma Virtual podem ser enviados para qualquer
                    ponto do território nacional (incluindo região autónoma dos Açores e Madeira) mas, em caso algum,
                    para os apartados.
                    <p class="text-justify mt-2">
                    10.2. A entrega dos produtos adquiridos será realizada no prazo de 1 semana para endereços nacionais e duas semanas para endereços internacionais, a contar do momento da
                    confirmação da encomenda (conferir cláusula 6.7.).</p>
                    <p class="text-justify mt-2">
                    10.3. Se um pedido é devolvido pela empresa de transporte ao comerciante por culpa do Utilizador, (como
                    erro na morada do envio ou negligência na receção), o pedido não será enviado novamente até que uma
                    nova ordem de entrega seja paga. Para indicar o procedimento, deve entrar em contacto com o
                    comerciante ao qual comprou o(s) produto(s).</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>11. DEVOLUÇÕES E DIREITO DE LIVRE RESOLUÇÃO<span></span></h3>
                <p class="text-justify mt-2">11.1. Se, no momento da entrega, o utilizador verificar que a embalagem encontra-se danificada, deverá este indicar, por escrito, na nota de entrega da empresa de transporte e contactar, no espaço de vinte e quatro horas, o Clube Desportivo de Tondela, através do e-mail loja.cdt@cdtondela.pt. Os gastos de devolução serão suportados pela Clube Desportivo de Tondela.</p>
                <p class="text-justify mt-2">11.2. Todos os clientes podem exercer o seu direito de livre resolução, no prazo máximo de catorze (14) a partir do momento em que o Utilizador ou um terceiro, com exceção do transportador, indicado pelo consumidor adquira a posse física dos bens, no caso dos contratos de compra e venda, ou:
                    Do dia em que o Utilizador ou um terceiro, com exceção do transportador, indicado pelo Utilizador adquira a posse física do último bem, no caso de vários bens encomendados pelo consumidor numa única encomenda e entregues separadamente,
                    Do dia em que o Utilizador ou um terceiro, com exceção do transportador, indicado pelo Utilizador adquira a posse física do último lote ou elemento, no caso da entrega de um bem que consista em diversos lotes ou elementos,
                    Do dia em que o Utilizador ou um terceiro por ele indicado, que não seja o transportador, adquira a posse física do primeiro bem, no caso dos contratos de entrega periódica de bens durante um determinado período;</p>
                <p class="text-justify mt-2">11.3. Caso o Utilizador exerça o seu direito de livre resolução, o Clube Desportivo de Tondela irá devolver todos os pagamentos recebidos, incluindo os custos de entrega – com a exceção das despesas adicionais resultantes da escolha do Utilizador num método de entrega diferente e com um valor superior em relação à opção mais barata que é oferecida -, sem qualquer atraso ou impedimento e, em qualquer situação, num prazo máximo de 14 dias a contar da data em que o fornecedor do bem ou serviço foi informado da decisão de resolução do contrato, ou em qualquer um dos casos aplicados na legislação em vigor, aplicável aos Utilizadores. Em qualquer caso, a devolução será feita através dos mesmos meios utilizados pelo cliente para efetuar o pagamento inicial, a menos que o cliente opte por um meio diferente, no qual poderá incorrer em custos adicionais.</p>
                <p class="text-justify mt-2">11.4. Se os bens, pela sua natureza, não puderem ser devolvidos normalmente pelo correio normal, será da responsabilidade do Utilizador suportar os custos da devolução dos mesmos.
                </p>
                <p class="text-justify mt-2">11.5. De acordo com a legislação aplicável e tendo em consideração os produtos vendidos na Loja Online, não será possível exercer o direito de livre resolução após a entrega se:
                </p>
                <ul>
                    <li class="text-justify">O fornecimento de bens foi feito de acordo com as especificações requeridas pelo Utilizador, ou personalizados.
                    </li>
                    <li class="text-justify">O fornecimento incluir produtos que se possam deteriorar ou expirar o seu prazo de validade num curto espaço de tempo.
                    </li>
                    <li class="text-justify">O fornecimento incluir bens selados que não sejam adequados para serem devolvidos, devido a motivos de proteção de saúde ou higiene e que tenham sido abertos após a entrega.
                    </li>
                    <li class="text-justify">O fornecimento de bens, que após a entrega e tendo em conta a sua natureza, se tenham misturado de forma indissociável com outros bens.
                    </li>
                    <li class="text-justify">O fornecimento de gravações sonoras ou de vídeo seladas, ou de programas informáticos selados que tenham sido violados pelo consumidor e utilizador após a entrega.
                    </li>
                    <li class="text-justify">O fornecimento de imprensa diária, publicações periódicas ou revistas, com exceção dos contratos de assinatura para o fornecimento das referidas publicações.
                    </li>
                    <li class="text-justify">O fornecimento de conteúdo digital que não é fornecido num meio material, quando a execução a execução foi iniciada, com o prévio consentimento expresso do consumidor e utilizador de que perderam o seu direito de devolução como resultado.
                    </li>
                </ul>
                <p class="text-justify mt-2">11.6. O Clube Desportivo de Tondela disponibilizará através da Loja Online, o formulário para o exercício do direito de livre resolução, cuja utilização pelo consumidor é facultativa. Se fizer uso desta possibilidade, enviar-lhe-emos sem demora, num suporte duradouro, um aviso de receção do pedido de resolução.
                </p>
                <p class="text-justify mt-2">11.7. Sem prejuízo dos casos previstos na legislação aplicável, não será possível proceder à devolução dos produtos adquiridos através da Loja Online.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>12. GARANTIAS<span></span></h3>
                <p class="text-justify mt-2">
                    12.1. O Clube Desportivo de Tondela garante que todos os produtos oferecidos são produtos originais da
                    marca, com garantia do fabricante e sem qualquer defeito, contanto com os termos de garantia
                    legalmente reconhecidos e que serão os da própria natureza do produto e, se for o caso, com o
                    serviço de pós-venda, de acordo com os regulamentos aplicáveis aos Utilizadores, na qualidade de
                    consumidores.</p>
                    <p class="text-justify mt-2">
                    12.2. Para reclamar e fazer cumprir a garantia, o cliente pode dirigir-se ao Clube Desportivo de Tondela
                    no prazo geral de dois (2) anos, desde a entrega do bem e sempre tendo em conta as condições e
                    limitações previstas na legislação aplicável.</p>
                    <p class="text-justify mt-2">
                    12.3. O Clube Desportivo de Tondela não se responsabiliza por danos e/ou perdas que possam ser causadas
                    aos Utilizadores como consequência do incumprimento desta cláusula e das condições da regulamentação
                    aplicadas aos consumidores e utilizadores sobre este tema.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>13. REGRAS DE UTILIZAÇÃO DO WEBSITE<span></span></h3>
                <p class="text-justify mt-2">
                    13.1. Não é permitido e, devido a isso, as consequências serão da exclusiva responsabilidade do Utilizador, o acesso ou uso do website para fins ilegais ou não autorizados, mais especificamente, é proibido, com caráter enunciativo, mas não limitativo, o seguinte:
                </p>
                <ul>
                    <p class="text-justify">i. O uso do website de qualquer maneira que possa causar danos, interrupções, ineficiências ou defeitos na funcionalidade ou no computador de terceiros.
                    </p>
                    <p class="text-justify">ii. O uso do website para a transmissão, instalação ou publicação de qualquer vírus, código malicioso ou outros programas ou arquivos nocivos.
                    </p>
                    <p class="text-justify">iii. O uso do website para recolher dados pessoais de Utilizadores.
                                        </p>
                    <p class="text-justify">iv. O acesso, sem autorização, a qualquer secção do website, a outros sistemas ou redes conectadas ou aos serviços oferecidos através do website, por meio de hacking, falsificação, extração de senhas ou qualquer outro meio ilegítimo.
                    </p>
                    <p class="text-justify">v. Violar ou tentar ultrapassar as medidas de segurança ou autenticação do website ou qualquer rede conectada, ou as medidas de segurança e proteção inerentes aos conteúdos oferecidos no website.
                    </p>
                    <p class="text-justify">vi. Executar alguma ação que cause uma saturação desproporcional, injustificada e desnecessária na infraestrutura do website ou nos sistemas de rede do website, bem como nos sistemas de rede conectados à Plataforma Virtual; ou impedir o desenvolvimento normal de um evento, concurso, promoção ou qualquer outra atividade disponível através do website ou de qualquer uma das suas funcionalidades, seja alterando ou tentando alterar, ilegalmente ou de qualquer outra forma, o acesso, participação ou operação, falsificando o resultado e/ou usando métodos de participação fraudulenta, por qualquer procedimento, e/ou por qualquer prática que viole de algum modo estes Termos e Condições.
                    </p>
                    <p class="text-justify">vii. Permitir transações que são ilegais ou qua são consideradas por marcas de cartões de crédito, ou pelo banco adquirente, que podem ou têm o potencial de prejudicar a boa vontade ou influenciá-lo de forma negativa. As seguintes atividades são proibidas pelos programas das marcas de cartão de crédito: a venda ou oferta de um produto que não esteja em total conformidade com todas as leis aplicáveis ao Comprador, Banco Emissor, Comerciante, Titular do Cartão ou Cartões.
                    </p>
                </ul>
                <p class="text-justify mt-2">
                    13.2. Para que o website tenha um ambiente seguro, é estritamente proibido publicar conteúdos:
                </p>
                <ul>
                    <p class="text-justify">i. Que possam ser considerados como uma violação em qualquer forma dos direitos fundamentais de honra, privacidade pessoal e familiar ou à imagem de terceiros, e especialmente menores.
                    </p>
                    <p class="text-justify">ii. Que incluam fotografias que usem imagens ou dados pessoais de terceiros sem ter obtido o consentimento apropriados dos seus titulares.
                    </p>
                    <p class="text-justify">iii. Que violem o sigilo das comunicações, a violação dos direitos de propriedade intelectual e industrial, ou das normas reguladoras da proteção de dados de carácter pessoal.
                    </p>
                    <p class="text-justify">iv. Que contenham qualquer material ou informação que seja considerada ilegal, abusiva, enganosa, fraudulenta ou de qualquer forma contrária à moralidade ou ordem pública.
                    </p>
                    <p class="text-justify">v. Que contenham “spam” e/ou ligações para sites não relacionados ao espaço correspondente.
                    </p>
                    <p class="text-justify">vi. Que tenham por finalidade induzir, incitar ou promover qualquer tipo de descriminação com base no nascimento, raça, sexo, religião, opinião ou qualquer outra condição ou circunstância, pessoal ou social.
                    </p>
                    <p class="text-justify">vii. Que incorporem, disponibilizem ou permitam aceder a produtos, elementos, mensagens e/ou serviços criminais, violentos, ofensivos, prejudiciais, degradantes ou, em geral, contrários à lei, à moralidade e aos bons costumes geralmente aceites ou à ordem pública.
                    </p>
                </ul>
                <p class="text-justity">
                  13.3. O Utilizador que não respeite as condições indicadas na presente cláusula será responsável por qualquer reclamação de danos que possam ocorrer como resultado. Mesmo que não exista qualquer reclamação de terceiros, o Clube Desportivo de Tondela reserva o direito de impedir o acesso ao website aos Utilizadores que violem estas condições.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>14. PROPRIEDADE INTELECTUAL<span></span></h3>
                <p class="text-justify mt-2">
                    14.1. O Utilizador reconhece e aceita que todas as marcas, nomes comerciais ou logotipos, bem como
                    quaisquer outros direitos de propriedade intelectual sobre o conteúdo e/ou quaisquer outros
                    elementos relativos Clube Desportivo de Tondela são da propriedade exclusiva deste (doravante
                    “Propriedade Intelectual do Clube Desportivo de Tondela”). Em nenhum caso, o acesso ao website
                    implica uma renúncia, licença ou transferência total ou parcial da Propriedade Intelectual do Clube
                    Desportivo de Tondela, exceto quando expressamente indicado de outra forma. Os Termos e Condições
                    não conferem ao Utilizador o direito de utilização, alteração, exploração, reprodução, distribuição
                    ou comunicação pública da Propriedade Intelectual do Propriedade Intelectual do Clube Desportivo de
                    Tondela. Qualquer outro uso ou exploração de quaisquer direitos estará sujeita à prévia e expressa
                    autorização concedida especificamente para o efeito pelo Clube Desportivo de Tondela.</p>
                    <p class="text-justify mt-2">
                    14.2. O website, como um todo e obra artística multimédia é protegido como direito de autor / copyright
                    pela legislação aplicável.</p>
                    <p class="text-justify mt-2">
                    14.3. É também proibido remover, ignorar ou manipular os avisos de direito de autor / "copyright" ou
                    quaisquer outros do Clube Desportivo de Tondela, bem como os dispositivos técnicos de proteção ou
                    quaisquer mecanismos de informação / proteção que possam conter os conteúdos.</p>
            
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>15. RESPONSABILIDADE<span></span></h3>
                <p class="text-justify mt-2">
                    15.1. As partes comprometem-se a cumprir as suas obrigações legais e contratuais geradas em virtude deste
                    contrato. Se uma das partes não cumprir qualquer uma das suas obrigações ou impedir o cumprimento
                    pela outra parte, o direito da outra parte de reivindicar uma indeminização por danos causados será
                    gerado, tanto por danos como consequências pela perda de lucros.</p>  
                    <p class="text-justify mt-2">  
                    15.2. As partes serão responsáveis por qualquer infração que possa ter ocorrido de forma propositada,
                    sendo a outra parte indemnizada por qualquer erro, culpa ou negligência que não lhe seja imputável,
                    e por qualquer dano decorrente das referidas infrações ou erros imputáveis à outra parte
                    contratante.
                </p>
                <p class="text-justify mt-2">
                   15.3. O utilizador reconhece e aceita que o Clube Desportivo de Tondela não garante ou é responsável, incluindo, mas não limitando, pelo seguinte:
Continuidade do conteúdo do website;
                </p>
                <ul>
                    <p class="text-justify">
                        i.	Continuidade do conteúdo do website;
                    </p>
                    <p class="text-justify">
                        ii.	Ausência de vírus e/ou outros componentes nocivos no website ou no servidor que o aloja;
                    </p>
                    <p class="text-justify">
                        iii.	A invulnerabilidade do website e/ou impossibilidade de violar as medidas de segurança adotadas;
                    </p>
                    <p class="text-justify">
                        iv.	Má transmissão e/ou receção de qualquer dado e/ou informação na internet;
                    </p>
                    <p class="text-justify">
                        v.	Falha de qualquer material de receção ou linhas de comunicação;
                    </p>
                    <p class="text-justify">
                        vi.	Qualquer disfunção na rede de internet, que impeça o correto funcionamento do website e/ou o correto desenvolvimento dos serviços;
                    </p>
                    <p class="text-justify">
                        vii.	A falta de utilidade ou desempenho do website;
                    </p>
                    <p class="text-justify">
                        viii.	Danos causados a si ou a terceiros, por qualquer pessoa que infrinja as condições, regras e instruções que o Clube Desportivo de Tondela estabelece no website ou através da violação dos sistemas deste;
                    </p>
                    <p class="text-justify">
                        ix.	Qualquer consequência no desempenho dos serviços do website derivadas de informações falsas ou incompletas fornecidas pelo cliente ou utilizador;
                    </p>
                </ul>

                <p class="text-justify">
                    15.4. O Clube Desportivo de Tondela não se responsabiliza por qualquer dano causado no software ou equipamento informático do Utilizador durante o uso do website, nem pelos danos ou perdas de qualquer tipo produzidos no comerciante ou no cliente que sejam derivados por falhas ou desconexões nas redes de telecomunicações, que produzam a suspensão, cancelamento ou interrupção durante a prestação do serviço. 
                </p>
                <p class="text-justify">
                    15.5. O Clube Desportivo de Tondela utilizará todos os esforços comercias e técnicos à sua disposição para manter os seus serviços disponíveis através do website, o que é uma obrigação que, no entanto, não se aplica a qualquer falta de disponibilidade ou desempenho causado por causas alheias ao controlo do Clube Desportivo de Tondela, força maior, problemas de acesso à internet, problemas tecnológicos
                </p>
                <p class="text-justify">
                    15.6. Em qualquer caso, o Clube Desportivo de Tondela reserva o direito de suspender, modificar, restringir ou interromper, temporária ou permanentemente, o acesso, navegação, uso, alojamento e/ou download do conteúdo e/ou uso de serviços do website, com ou sem notificação prévia, para clientes ou utilizadores que violem qualquer das disposições detalhadas nestes Termos e Condições, sem que o Utilizador possa exigir compensação por esta causa.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>16. ALTERAÇÃO DAS CONDIÇÕES<span></span></h3>
           
            <p class="text-justify">
                O Clube Desportivo de Tondela reserva o direito de fazer alterações aos Termos e Condições a qualquer momento. Tais alterações serão comunicadas ao cliente via email, 20 dias antes da entrada em vigor dos novos Termos e Condições. Caso não aceite os novos termos deverá eliminar a sua conta de Utilizador, sendo que a continuação da utilização do website será considerada como aceitação dos novos Termos e Condições.
            </p> </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>17. LEI APLICÁVEL E JURISDIÇÃO COMPETENTE<span></span></h3>
                <p class="text-justify">
                    17.1. Estas condições gerais são regidas pela Lei Portuguesa.
                </p>
                <p class="text-justify">
                    17.2. Qualquer litígio que possa surgir entre as partes contratantes relativamente à interpretação ou execução destas Condições de Utilização será da responsabilidade dos Tribunais do local de residência do consumidor.
                </p>
                <p class="text-justify">
                    17.3. No caso de litígios relacionados à Loja Online, o Utilizador pode recorrer às entidades de resolução alternativa de litígios de consumo listadas no Portal do Consumidor em https://www.consumidor.pt/, onde poderá consultar de forma dinâmica e atualizada a identificação das referidas entidades e os respetivos contactos, ou à Plataforma Europeia de Resolução de Litígios em Linha, disponível em https://ec.europa.eu/consumers/odr/s.
                </p>
                <p class="text-justify">
                    17.4. O Utilizador poderá apresentar uma reclamação através do livro de reclamações eletrónico disponível em https://www.livroreclamacoes.pt/inicio.
                </p>
            </div>
        </div>
    </div>
</div>