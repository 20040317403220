<app-formacao-banner></app-formacao-banner>

<div @fade class="container-fluid p-0 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">Missão</p>
        <p class="descEst text-justify">
            O Clube Desportivo de Tondela Futebol Formação tem como missão formar atletas com competências para a equipa
            sénior, sempre em conjunto com o desenvolvimento das aptidões desportivas, académicas e sociais das crianças
            e jovens, contribuindo assim para a formação de atletas e cidadãos com futuros promissores.
        </p>
    </div>

   <!--  <div class="container-fluid bg_3 mb-lg-5">
        <div class="container fix-mobile-padding">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item ">
                                <img class="d-block w-100" src="assets/img/missao/3.jpg" alt="Missão">
                            </div>
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/img/missao/2.jpg" alt="Missão">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/img/missao/1.jpg" alt="Missão">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
</div>


<div class="container-fluid bg1 py-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="titleEst">Visão</p>
                <p class="descEst text-justify">
                    O Clube Desportivo de Tondela Futebol Formação pretende criar um conceito de academia de futebol de
                    qualidade acrescida, quer pela experiencia e conhecimento dos seu recursos humanos mas também, pela
                    inovação
                    nas metodologias de trabalho. Deverá assumir-se como uma entidade viva e vibrante, reconhecida pela
                    qualidade do seu desempenho na formação, na transferência conhecimento, e com um forte compromisso
                    com a
                    região.
                </p>
            </div>
        </div>  
    </div>
</div>

<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <p class="titleEst">Valores</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">paixão</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">ética</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">compromisso</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">competência</p>
            </div>
        </div>

        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">esforço</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">fair play</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">disciplina</p>
            </div>
        </div>
        <div class="col-12 col-md-3 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">respeito</p>
            </div>
        </div>

        <div class="col-12 col-md-4 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">determinação</p>
            </div>
        </div>
        <div class="col-12 col-md-4 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">ambição</p>
            </div>
        </div>
        <div class="col-12 col-md-4 col-height">
            <div class="overlay">
                <p class="text-capitalize valores">trabalho de equipa</p>
            </div>
        </div>
    </div>

</div>