import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent {
  public bannerTitle:string = "Bilheteira CD Tondela"
  public bannerSubTitle:string = "Área onde poderá encontrar toda a informação relativa aos bilhetes de futebol do Clube Desportivo de Tondela"
}
