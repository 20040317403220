<app-equipa-principal-banner></app-equipa-principal-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading">

    <!-- GUARDA REDES -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div class="col-lg-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">GUARDA-REDES</p>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">GUARDA-REDES</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let gr of guardaRedes">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{gr.nome | uppercase}}</p>
                        <p class="posJog m-0">{{gr.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" alt="{{gr.nome}}"
                            src="{{gr.foto1}}">
                        <p class="numJog">{{gr.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center" *ngFor="let gr of guardaRedes">
                        <div class="bgJogador" (mouseover)="jogadorHide('jogador'+gr.id)" id="jogador{{gr.id}}">
                            <img class="img-fluid" alt="{{gr.nome}}" src="{{gr.foto1}}">
                        </div>
                        <div class="bgJogador fix-bg2" (mouseleave)="jogadorShow('jogador'+gr.id)" id="jogador{{gr.id}}_hide">
                            <img class="img-fluid left-right" alt="{{gr.nome}}" src="{{gr.foto2}}">
                        </div>
                        <div class="row d-flex justify-content-center  mb-4">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{gr.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0">{{gr.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- DEFESAS -->
    <div class="container-fluid py-5">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">DEFESAS</p>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">DEFESAS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let def of defesas">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{def.nome | uppercase}}</p>
                        <p class="posJog m-0">{{def.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" alt="{{def.nome}}"
                            src="{{def.foto1}}">
                        <p class="numJog">{{def.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center mb-4" *ngFor="let def of defesas">
                        <div class="bgJogador" (mouseover)="jogadorHide('jogador'+def.id)" id="jogador{{def.id}}">
                            <img class="img-fluid" alt="{{def.nome}}" src="{{def.foto1}}">
                        </div>
                        <div class="bgJogador fix-bg2" (mouseleave)="jogadorShow('jogador'+def.id)"
                            id="jogador{{def.id}}_hide">
                            <img class="img-fluid left-right" alt="{{def.nome}}" src="{{def.foto2}}">
                        </div>
                        <div class="row d-flex justify-content-center  mb-4">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{def.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0">{{def.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MÉDIOS -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">MÉDIOS</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">MÉDIOS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let med of medios">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{med.nome | uppercase}}</p>
                        <p class="posJog m-0">{{med.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" alt="{{med.nome}}"
                            src="{{med.foto1}}">
                        <p class="numJog">{{med.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center mb-4" *ngFor="let med of medios">
                        <div class="bgJogador" (mouseover)="jogadorHide('jogador'+med.id)" id="jogador{{med.id}}">
                            <img class="img-fluid" alt="{{med.nome}}" src="{{med.foto1}}">
                        </div>
                        <div class="bgJogador fix-bg2" (mouseleave)="jogadorShow('jogador'+med.id)"
                            id="jogador{{med.id}}_hide">
                            <img class="img-fluid left-right" alt="{{med.nome}}" src="{{med.foto2}}">
                        </div>
                        <div class="row d-flex justify-content-center  mb-4">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{med.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0">{{med.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- AVANÇADOS -->
    <div class="container-fluid py-5">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">AVANÇADOS</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">AVANÇADOS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let ata of atacantes">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{ata.nome | uppercase}}</p>
                        <p class="posJog m-0">{{ata.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" alt="{{ata.nome}}"
                            src="{{ata.foto1}}">
                        <p class="numJog">{{ata.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center mb-4" *ngFor="let ata of atacantes">
                        <div class="bgJogador" (mouseover)="jogadorHide('jogador'+ata.id)" id="jogador{{ata.id}}">
                            <img class="img-fluid" alt="{{ata.nome}}" src="{{ata.foto1}}">
                        </div>
                        <div class="bgJogador fix-bg2" (mouseleave)="jogadorShow('jogador'+ata.id)"
                            id="jogador{{ata.id}}_hide">
                            <img class="img-fluid left-right" alt="{{ata.nome}}" src="{{ata.foto2}}">
                        </div>
                        <div class="row d-flex justify-content-center  mb-4">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{ata.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0">{{ata.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
