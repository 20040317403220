import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-modalidades-banner',
  templateUrl: './modalidades-banner.component.html',
  styleUrls: ['./modalidades-banner.component.scss']
})
export class ModalidadesBannerComponent implements OnInit {

  title: string = ''

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        let url = e.urlAfterRedirects

        if(url.includes('tenis')) this.title = 'ténis'
        if(url.includes('hipismo')) this.title = 'hipismo'
        if(url.includes('esports')) this.title = 'esports'
        if(url.includes('atletismo')) this.title = 'atletismo'
        if(url.includes('veteranos')) this.title = 'veteranos'
        if(url.includes('basquetebol')) this.title = 'basquetebol'
      }
    })
  }

  ngOnInit(): void {
  }

}
