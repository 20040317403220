<div *ngIf="mostraBannerCookie && popup.ativo == 1" class="overlay">
  <div class="banner">
    <div class="container">
      <div class="row">
        <div class="col-12 position-relative d-flex justify-content-center align-items-center">
              <img class="img-banner" src="{{popup.imagem}}" alt="popup" (click)="goUrl()"><!-- <button class="btn-loja btn btn--skew btn-default" (click)="setCookies()">
            <div class="button arrow">IR PARA A LOJA</div>
          </button> -->
          <button class="btn-fechar" (click)="setCookies()"><svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" class="feather feather-x">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
