import { animate, style, transition, trigger } from '@angular/animations';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tecnicos } from 'src/app/interfaces/equipa-tecnica';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-equipa-principal-staff',
  templateUrl: './equipa-principal-staff.component.html',
  styleUrls: ['./equipa-principal-staff.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class EquipaPrincipalStaffComponent implements OnInit, OnDestroy {

  treinadores: tecnicos[]
  dep_medico: tecnicos[]
  scouting: tecnicos[]
  logistica: tecnicos[]

  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    this.sub.push(this.dataService.getTecnicos().subscribe((res: any) => {
      this.treinadores = res.treinadores
      this.dep_medico = res.departamento_medico
      this.scouting = res.departamento_observacao
      this.logistica = res.logistica
    }))
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

}
