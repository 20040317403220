<app-clube-header></app-clube-header>
<app-clube-navbar></app-clube-navbar>

<div @fade>
    <div class="container-fluid bg1 pt-5 pb-3">
        <div class="container">
            <p class="titleEst">ESTÁDIO JOÃO CARDOSO</p>
            <p class="descEst text-justify">
                O Complexo Desportivo do Estádio João Cardoso foi oficialmente inaugurado no dia 27 de maio de 2008, com a realização do treino da Seleção Nacional A, aquando da preparação para o EURO 2008.
            </p>
                
            <p class="descEst text-justify">
                No ano de 2015, com a subida do Clube Desportivo de Tondela à Primeira Liga, sofreu obras de reestruturação de forma a poder disputar os jogos do campeonato em sua casa e na presença dos seus adeptos!
            </p>

            <p class="descEst text-justify">
                Localizado nos terrenos do velhinho Campo Desportivo do Campo do Pereiro, que foi o campo de futebol do clube desde a sua fundação, passou a denominar-se, em 1990, de Estádio João Cardoso por homenagem do CDT ao homem que cedeu os terrenos para a sua construção.
            </p>

            <p class="descEst text-justify">
                O Estádio João Cardoso foi sofrendo obras de melhoramento ao longo dos anos. A primeira grande remodelação inicia-se no ano de 1994, logo depois da subida do clube à 2ª Divisão Nacional, com o espaço a sofrer durante, um ano, obras de melhoramento que dotaram o piso do campo de futebol, até aqui de terra batida, com relva natural. Foi ainda aumentada a capacidade do estádio com o crescimento significativo das bancadas. Decorria já o ano de 1995 quando foi inaugurado o novo relvado deste estádio com um jogo amigável entre as velhas glórias da equipa de Veteranos do Clube Desportivo de Tondela com o CA Molelos.
            </p>

            <p class="descEst text-justify">
                Ainda na segunda metade da década de 90, concretizou-se a obra de construção do Pavilhão Desportivo e iniciou-se a construção do Campo nº 2 do Complexo do Estádio João Cardoso que inicialment teve um piso em terra batida, para em 2008 ser substituído pela implementação de um relvado sintético que se manteve até ao ano de 2014, altura em que o campo sofreu obras de melhoramento para colocação também de um piso de relva natural.
            </p>

            <p class="descEst text-justify">
                O estádio do Clube Desportivo de Tondela tem atualmente capacidade máxima para 5000 espetadores (lugares sentados).
            </p>
        </div>
    </div>

    <div class="container-fluid bg_3">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-9">
                        <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                            <div class="carousel-inner">
                            <div class="carousel-item ">
                                <img class="d-block w-100" src="assets/img/estadio/estadio_0.png" alt="Estádio">
                            </div>
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/img/estadio/foto1.jpg" alt="Estádio">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/img/estadio/foto2.jpg" alt="Estádio">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/img/estadio/foto3.jpg" alt="Estádio">
                            </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                            </a>
                    </div>
                      
                </div>
                <div class="col-12 col-lg-3 mb-5 mb-lg-3 centerNumbers">
                    <p class="numbers">2008</p>
                    <p class="desc">ANO DE INAUGURAÇÃO</p>

                    <p class="numbers">5000</p>
                    <p class="desc">CAPACIDADE</p>

                    <p class="numbers">4987</p>
                    <p class="desc">MAIOR ASSISTÊNCIA</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid pt-0 pt-lg-5 mt-lg-5 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.3419367723227!2d-8.085187884463753!3d40.51193265776764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23232cf129172f%3A0xaaa02166973d016c!2sEst%C3%A1dio%20Jo%C3%A3o%20Cardoso!5e0!3m2!1spt-PT!2spt!4v1600358491772!5m2!1spt-PT!2spt" width="100%" height="300" frameborder="0" style="border:0; display: block;" allowfullscreen=""></iframe>
    </div>
</div>
