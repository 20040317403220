<app-formacao-banner></app-formacao-banner>
<app-futebol-navbar></app-futebol-navbar>
<!-- <app-pagina-sem-dados></app-pagina-sem-dados> -->
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading" class="container py-lg-5 mt-4 mt-lg-0">
    <div class="mb-md-2">
        <div class="container">
            <!-- <div class="row d-none d-md-block">
                <div class="col-12 month mb-2">{{cal.label_mes}} <span class="year">{{cal.label_ano}}</span></div>
            </div> -->
            <div class="row">
                <div class="col-12 p-0 mb-3 topTable tit">CALENDÁRIO</div>
            </div>

            <div class="row hRow mb-3 mb-lg-0" *ngFor="let jogos of calendario">
                <div
                    class="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start order-1 order-lg-1 color-info pb-2 pb-lg-0 bt-div">
                    <p class="dataJogo">{{jogos.data}}</p>
                </div>
                <div class="col-12 col-lg-7 order-3 order-lg-2">

                    <div class="row padding-fix fix-border d-flex justify-content-center">

                        <div class="col-4 col-md-5 mr-0  fix-padding-mobile">
                            <div class="row">
                                <div class="col-lg-8 d-flex justify-content-center order-2 order-lg-1 pr-lg-0">
                                    <p class="alignCont equipaLabel"
                                        [ngClass]="jogos.clube_casa_id == 16 ? 'cdt-bold' : ''">
                                        {{jogos.clube_casa | uppercase}}</p>
                                </div>

                                <div class="col-lg-4 p-0 order-1 order-lg-2 text-center">
                                    <img src="assets/img/clubes/{{jogos.logo_casa == null ? 'logo.webp' : jogos.logo_casa}}"
                                        class="img-fluid-logo">
                                </div>
                            </div>
                        </div>

                        <div class="col-4 col-md-2 text-center align-self-center">
                            <p class="mb-0">-</p>
                        </div>


                        <div class="col-4 col-md-5  fix-padding-mobile">
                            <div class="row">
                                <div class="col-lg-4 p-0 text-center">
                                    <img src="assets/img/clubes/{{jogos.logo_fora == null ? 'logo.webp' : jogos.logo_fora}}"
                                        class="img-fluid-logo">
                                </div>

                                <div class="col-lg-8 d-flex justify-content-center pl-lg-0">
                                    <p class="alignCont equipaLabel"
                                        [ngClass]="jogos.clube_fora_id == 16 ? 'cdt-bold' : ''">
                                        {{jogos.clube_fora | uppercase}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end flex-row flex-lg-column color-info order-2 order-lg-3 pb-2 pb-lg-0">
                    <p class="ligaNos" [ngClass]="jogos.competicao == 'Taça de Portugal' ? 'font-titlegrande-fix' : ''">{{jogos.competicao | uppercase}}</p>
                    <p class="jornada text-uppercase">Jornada {{jogos.jornada | uppercase}}</p>
                </div>
            </div>
        </div>
    </div>

</div>
