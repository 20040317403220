
<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2">
            <div id="chegar-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline cursor" [routerLink]="['/informacao/como-chegar']" routerLinkActive="active-link">
                <span class="tabs mx-auto">COMO CHEGAR</span>
            </div>
            <div id="contac-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left border-right outline cursor" [routerLink]="['/contactos']" routerLinkActive="active-link">
                <span class="tabs mx-auto">CONTACTOS</span>
            </div>
        </div>
    </div>
</div>