import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ficha } from 'src/app/interfaces/ficha';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-equipa-principal-highlights',
  templateUrl: './equipa-principal-highlights.component.html',
  styleUrls: ['./equipa-principal-highlights.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class EquipaPrincipalHighlightsComponent implements OnInit, OnDestroy {

  fichaJogo: ficha = {}
  idJogo: number

  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getFicha()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }


  getFicha() {
    this.route.params.subscribe(params => {
      this.idJogo = params['id']
    })

    this.sub.push(this.dataService.getFicha(this.idJogo).subscribe(res => {
      this.fichaJogo = res[0]
    }))
  }

}
