import { Component, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-bilheteira',
  templateUrl: './bilheteira.component.html',
  styleUrls: ['./bilheteira.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ],
})
export class BilheteiraComponent implements AfterViewInit {
  public bannerTitle: string = "Bilheteira CD Tondela"
  public bannerSubTitle: string = "Área onde poderá encontrar toda a informação relativa aos bilhetes de futebol do Clube Desportivo de Tondela"

  constructor(public state: StateService, private elementRef: ElementRef, private route: Router) {
    this.route.events.subscribe((data: any) => {
      if (data instanceof NavigationEnd) {
        if (data.url.includes('/bilheteira/')) {
          document.dispatchEvent(new CustomEvent(`2Ticket_Client`, { detail: { type: 'router', path: data.url.replace('/bilheteira/', '/') } }));
        }
      }
    });
  }

  ngAfterViewInit() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://widget.2ticket.pt/widget.js';
    this.elementRef.nativeElement.appendChild(s);
  }
 
  @HostListener('document:2Ticket', ['$event'])
  handle2TicketEvent(data: any) {
    this.state.handle2TicketLogic(data);
  }

  ngOnDestroy() {
    document.removeEventListener('2Ticket', this.handle2TicketEvent);
  }
}
