<app-clube-header></app-clube-header>
<app-clube-navbar></app-clube-navbar>
<div @fade class="container-fluid bg1">
    <div class="container py-5 px-0 px-md-5 bg2">


        <div class="accordion" id="accordionExample">

            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                aria-expanded="true" aria-controls="collapseThree">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">ADMINISTRAÇÃO SAD</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseThree"
                                    aria-expanded="true" aria-controls="collapseThree"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse show" aria-labelledby="headingThree">
                    <div class="card-body bg1">

                        <h5>Presidente</h5>
                        <p>Gilberto Coimbra</p>

                        <h5>Administrador</h5>
                        <p>Eduardo Silva</p>

                        <h5>Administrador</h5>
                        <p>Carlos Machado</p>

                    </div>
                </div>
            </div>


            <div class="card">
                <div class="card-header cursor" id="headingFour" data-toggle="collapse" data-target="#estrutura-sad"
                aria-expanded="true" aria-controls="estrutura-sad">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">Estrutura SAD</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#estrutura-sad"
                                    aria-expanded="true" aria-controls="estrutura-sad"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="estrutura-sad" class="collapse" aria-labelledby="headingFour">
                    <div class="card-body">

                        <h5>Diretor Geral</h5>
                        <p>Carlos Marta</p>

                        <h5>Contabilidade</h5>
                        <p>Carlos Machado</p>

                        <h5>Diretor de Comunicação</h5>
                        <p>Francisco Favinha</p>

                        <h5>Relações Públicas</h5>
                        <p>Tânia Barbosa</p>

                        <h5>Diretor Segurança</h5>
                        <p>Flávio Coimbra</p>

                        <h5>Coordenador Geral de Formação (SAD)</h5>
                        <p>Paulo Freitas</p>

                        <h5>Coordenador Geral de Formação (Clube)</h5>
                        <p>Rui Manaia</p>

                        <h5>Coordenador Técnico de Formação</h5>
                        <p>Tiago Veloso</p>

                        <h5>Team Manager/Formação</h5>
                        <p>Pedro Maneira</p>

                        <h5>OLA - O.Ligação Adeptos</h5>
                        <p>Miguel Lopes</p>

                        <h5>Diretor de Campo</h5>
                        <p>António Carmo</p>

                        <h5>Loja</h5>
                        <p>Stefanie Marques</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>José Martins</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>Rolando da Cruz</p>

                        <h5>Técnico de Equipamentos</h5>
                        <p>Júlio Barros</p>

                        <h5>Limpeza</h5>
                        <p>Elisabete Marques</p>

                        <h5>Motorista</h5>
                        <p>Luís Chaves</p>


                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingFour" data-toggle="collapse" data-target="#collapseFour"
                aria-expanded="true" aria-controls="collapseFour">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">DIREÇÃO CLUBE</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFour"
                                    aria-expanded="true" aria-controls="collapseFour"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                    <div class="card-body bg1">

                        <h5>Presidente</h5>
                        <p>Gilberto Neves Coimbra</p>

                        <h5>Vice-Presidente - Gestão Financeira</h5>
                        <p>Vítor Amorim Lisboa Ferreira Sacras</p>

                        <h5>Vice-Presidente - Equipamentos E Instalações</h5>
                        <p>Flávio Coimbra Ferreira</p>

                        <h5>Vice-Presidente - Futebol</h5>
                        <p>Paulo de Freitas Marques Correia</p>

                        <h5>Vice-Presidente - Outras Atividades</h5>
                        <p>Eduardo Luís Rebelo Silva</p>


                        <h5>Primeiro Vogal - Diretor Executivo</h5>
                        <p>Rui André Manaia</p>

                        <h5>Segundo Vogal - Diretor Executivo</h5>
                        <p>Carlos Pedro dos Santos Rodrigues</p>

                        <h5>Terceiro Vogal - Diretor Executivo</h5>
                        <p>Nuno Henrique de Figueiredo Simões</p>

                        <h5>Quarto Vogal - Diretor Executivo</h5>
                        <p>Luís Miguel Matos Chaves</p>

                        <h5>Quinto Vogal - Diretor Executivo</h5>
                        <p>António Alberto da Silva do Carmo</p>

                        <h5>Sexto Vogal - Diretor Executivo</h5>
                        <p>Carlos Manuel Simões Marques</p>

                        <h5>Sétimo Vogal - Diretor Executivo</h5>
                        <p>José António da Costa Ferreira</p>

                        <h5>Suplente</h5>
                        <p>Adelino Fernando Correia Pedrosa</p>

                        <h5>Suplente</h5>
                        <p>Artur Jorge Santos Almeida Castaínça</p>

                        <h5>Suplente</h5>
                        <p>Ana Lúcia Silva Santos de Matos</p>

                        <h5>Suplente</h5>
                        <p>António Joaquim de Matos</p>
                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header cursor" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
                aria-expanded="true" aria-controls="collapseFive">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">CONSELHO FISCAL</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFive"
                                    aria-expanded="true" aria-controls="collapseFive"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
                    <div class="card-body">

                        <h5>Presidente</h5>
                        <p>Luís Henrique Brás Marques</p>

                        <h5>Vice-Presidente</h5>
                        <p>Fernando Manuel Ferreira Clemente</p>

                        <h5>1º Secretário</h5>
                        <p>Carlos Luís Marques Machado</p>

                        <h5>Relator</h5>
                        <p>José Alves Pinto Pizarro</p>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                    <h2 class="mb-0">
                        <div class="row hRow centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">ASSEMBLEIA GERAL</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseTwo"
                                    aria-expanded="true" aria-controls="collapseTwo"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                    <div class="card-body bg1">

                        <h5>Presidente</h5>
                        <p>Carlos Manuel Antunes Amaral</p>

                        <h5>Vice-Presidente</h5>
                        <p>José da Cunha Fernandes</p>

                        <h5>1º Secretário</h5>
                        <p>Nuno Miguel Almeida Antunes</p>

                        <h5>2º Secretário</h5>
                        <p>José Manuel Pereira Mendes</p>

                        <h5>Suplente</h5>
                        <p>José Fernando Carmo Ferreira</p>

                        <h5>Suplente</h5>
                        <p>José Evaristo Marques Fernandes</p>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header cursor" id="headingThree" data-toggle="collapse" data-target="#twoplay"
                aria-expanded="true" aria-controls="twoplay">
                    <h2 class="mb-0">
                        <div class="row hRow2 centerVert">
                            <div class="col-9 col-lg-10">
                                <p class="instiTit ml-3 mb-0 ml-lg-5">2PLAY - CREATIVE LAB (Parceiro Externo)</p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <button class="btn btn-link p-0" data-toggle="collapse" data-target="#twoplay"
                                    aria-expanded="true" aria-controls="twoplay"><i
                                        class="fa fa-plus-circle addBtn mx-auto"></i></button>
                            </div>
                        </div>
                    </h2>
                </div>
                <div id="twoplay" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body bg1">

                        <h5>Márcio Cortez</h5>
                        <p>Projeto Manager</p>

                        <h5>Hugo Cabral</h5>
                        <p>Design</p>

                        <h5>André Jacinto</h5>
                        <p>Vídeo e Fotografia</p>
                        
                        <h5>Diogo Seixas</h5>
                        <p>Marketing</p>

                        <h5>Ivan Terra</h5>
                        <p>IT</p>

                        <h5>Ricardo Sequeira</h5>
                        <p>Ecrã LED</p>

                    </div>
                </div>
            </div>

            <div class="card">
              <div class="card-header cursor" id="headingSix" data-toggle="collapse" data-target="#collapseSix"
              aria-expanded="true" aria-controls="collapseSix">
                  <h2 class="mb-0">
                      <div class="row hRow centerVert">
                          <div class="col-9 col-lg-10">
                              <p class="instiTit ml-3 mb-0 ml-lg-5">RELATÓRIOS E CONTAS</p>
                          </div>
                          <div class="col-3 col-lg-2 text-center">
                              <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseSix"
                                  aria-expanded="true" aria-controls="collapseSix"><i
                                      class="fa fa-plus-circle addBtn mx-auto"></i></button>
                          </div>
                      </div>
                  </h2>
              </div>
              <div id="collapseSix" class="collapse" aria-labelledby="headingSix">
                  <div class="card-body bg1">

                        <h5>- SAD</h5>
                      
                      <p><b>Relatório e Contas 2019/2020</b><br>Consulte <a href="assets/data/relat%C3%B3rio.pdf" target="_blank">aqui</a> o Relatório e Contas da Época 2019/2020</p>

                      <p><b>Relatório e Contas 2020/2021</b><br>Consulte <a href="assets/data/RelatorioContasCDTondela2020-2021.pdf" target="_blank">aqui</a> o Relatório e Contas da Época 2020/2021</p>

                      <p><b>Relatório e Contas 2021/2022</b><br>Consulte <a href="assets/data/R&CSADEpoca2021_2022.pdf" target="_blank">aqui</a> o Relatório e Contas da Época 2021/2022</p>

                      <h5>- Clube</h5>

                      <p><b>Relatório de Contas 2021</b><br>Consulte <a href="assets/data/CdtCLUBE-RelatorioContasAno2021.pdf" target="_blank">aqui</a> o Relatório de Contas de 2021</p>

                  </div>
              </div>
          </div>
        </div>
    </div>
</div>
