<app-equipa-principal-banner></app-equipa-principal-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading" class="container-fluid bg">
    <div class="container">
        <div class="text-left fix-button">
            <a class="button arrow" [routerLink]="['/futebol/equipa-principal/resultados']">VOLTAR</a>
        </div>
        <div class="row hRow mb-0 mb-md-4 mb-lg-0 border-top border-bottom">
            <div
                class="col-6 order-1 order-md-1 col-md-2 d-flex justify-content-center justify-content-md-start order-1 order-md-1 color-info pb-0 pb-md-2 pb-lg-0">
                <img src="assets/img/{{fichaJogo.competicao_imagem}}" alt="" class="img-fluid logo-nos">
            </div>
            <div class="col-12 order-3 order-md-2 col-md-7 order-3 order-md-2">
                <div class="row padding-fix">
                    <div class="col-5">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center order-2 order-md-1">
                                <p class="d-flex align-items-center m-0 equipaLabel text-center text-md-left pt-2 pt-md-0"
                                    [ngClass]="fichaJogo.clube_casa_id == 100 ? 'cdt-bold' : ''">
                                    {{fichaJogo.clube_casa | uppercase}}</p>
                            </div>
                            <div class="col-md-12 p-0 order-1 order-md-2 text-center">
                                <img src="assets/img/clubes/{{fichaJogo.logo_casa == null ? 'logo.webp' : fichaJogo.logo_casa}}"
                                    class="img-fluid-logo">
                            </div>
                        </div>
                    </div>
                    <div class="col-2 d-flex justify-content-center ">
                        <p class="d-flex align-items-center m-0 order-2"><span
                                [ngClass]="fichaJogo.clube_casa_id == 100 ? 'cdtNum' : 'eqNum'">{{fichaJogo.resultado | slice:0:1}}</span><span
                                class="twoPoints mx-1">:</span><span
                                [ngClass]="fichaJogo.clube_fora_id == 100 ? 'cdtNum' : 'eqNum'">{{fichaJogo.resultado | slice:2:3}}</span>
                        </p>
                    </div>
                    <div class="col-5">
                        <div class="row">
                            <div class="col-md-12 p-0 text-center">
                                <img src="assets/img/clubes/{{fichaJogo.logo_fora == null ? 'logo.webp' : fichaJogo.logo_fora}}"
                                    class="img-fluid-logo">
                            </div>

                            <div class="col-md-12 d-flex justify-content-center">
                                <p class="d-flex align-items-center m-0 equipaLabel text-center text-md-left pt-2 pt-md-0"
                                    [ngClass]="fichaJogo.clube_fora_id == 100 ? 'cdt-bold' : ''">
                                    {{fichaJogo.clube_fora | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-6 order-2 order-md-3 col-md-3 d-flex justify-content-center justify-content-md-end flex-row flex-md-column color-info order-2 order-md-3 pb-2 pb-lg-0">
                <div class="row m-0 d-flex align-items-center justify-content-end">
                    <a class="text-label d-flex align-items-center text-decoration-none safari_only" target="_blank"
                        href="{{fichaJogo.resumo}}">RESUMO <i class="fa fa-play-circle ml-2"></i></a>
                </div>
            </div>
        </div>

        <div class="row hRow2 border-top border-bottom bgMarcador">
            <div class="col-6">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-4 my-3">
                        <p class="marcador" *ngFor="let marcador of fichaJogo.marcadores_casa">{{marcador.nome}}
                            ({{marcador.minuto}})</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12 col-md-9 offset-md-3 my-3">
                        <p class="marcador" *ngFor="let marcador of fichaJogo.marcadores_fora">({{marcador.minuto}})
                            {{marcador.nome}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 col-md-12 col-lg-6 order-1 order-md-1">
                <p class="onze-titular-title">11 INICIAL - <span
                        [ngClass]="fichaJogo.clube_casa_id == 100 ? 'cdtNum-2' : 'eqNum-2'">{{fichaJogo.clube_casa | uppercase}}</span>
                </p>
            </div>
            <div class="col-12 col-md-12 col-lg-6 order-3 order-md-2 pt-5 pt-md-0">
                <p class="onze-titular-title">11 INICIAL - <span
                        [ngClass]="fichaJogo.clube_fora_id == 100 ? 'cdtNum-2' : 'eqNum-2'">{{fichaJogo.clube_fora | uppercase}}</span>
                </p>
            </div>


            <div class="col-12 col-md-12 col-lg-6 order-2 order-md-3 no-padding">
                <div class="container box-shadow">
                    <div class="row d-flex align-items-center hRow3">
                        <div class="col-2">
                            <p class="numero-treinador m-0">T</p>
                        </div>
                        <div class="col d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{fichaJogo.treinador_casa | uppercase}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center hRow3 "
                        *ngFor="let onze of fichaJogo.onze_inicial_casa; let i = index"
                        [ngClass]="i % 2 ? '' : 'bgWhite' ">
                        <div class="col-2">
                            <p class="numero-jogador m-0">{{onze.numero}}</p>
                        </div>
                        <div class="col-6 col-md d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{onze.nome | uppercase}}<span *ngIf="onze.capitao"
                                    class="ml-1">(C)</span> </p>
                        </div>
                        <div class="col d-md-flex justify-content-md-end">
                            <p class="d-flex aling-items-center justify-content-end mb-0 margin-minuto"
                                *ngFor="let acont of onze.acontecimentos"><img
                                    src="assets/img/acontecimentos/{{acont.imagem}}" alt=""
                                    class="img-fluid-acont pr-1"> ({{acont.minuto}})</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-12 col-lg-6 order-4 order-md-4 no-padding">
                <div class="container box-shadow">
                    <div class="row d-flex align-items-center hRow3">
                        <div class="col-2">
                            <p class="numero-treinador m-0">T</p>
                        </div>
                        <div class="col d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{fichaJogo.treinador_fora | uppercase}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center hRow3 "
                        *ngFor="let onze of fichaJogo.onze_inicial_fora; let i = index"
                        [ngClass]="i % 2 ? '' : 'bgWhite' ">
                        <div class="col-2">
                            <p class="numero-jogador m-0">{{onze.numero}}</p>
                        </div>
                        <div class="col-6 col-md d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{onze.nome | uppercase}} <span *ngIf="onze.capitao"
                                    class="ml-1">(C)</span> </p>
                        </div>
                        <div class="col d-md-flex justify-content-md-end">
                            <p class="d-flex aling-items-center justify-content-end mb-0 margin-minuto"
                                *ngFor="let acont of onze.acontecimentos"><img
                                    src="assets/img/acontecimentos/{{acont.imagem}}" alt=""
                                    class="img-fluid-acont pr-1"> ({{acont.minuto}})</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="row mt-5 pb-3">
            <div class="col-12 col-md-12 col-lg-6 order-1 order-md-1">
                <p class="onze-titular-title">SUPLENTES - <span
                        [ngClass]="fichaJogo.clube_casa_id == 100 ? 'cdtNum-2' : 'eqNum-2'">{{fichaJogo.clube_casa | uppercase}}</span>
                </p>
            </div>
            <div class="col-12 col-md-12 col-lg-6 order-3 order-md-2 pt-5 pt-md-0">
                <p class="onze-titular-title">SUPLENTES - <span
                        [ngClass]="fichaJogo.clube_fora_id == 100 ? 'cdtNum-2' : 'eqNum-2'">{{fichaJogo.clube_fora | uppercase}}</span>
                </p>
            </div>

            <div class="col-12 col-md-12 col-lg-6 order-2 order-md-3 no-padding">
                <div class="container box-shadow">
                    <div class="row d-flex align-items-center hRow3 "
                        *ngFor="let onze of fichaJogo.suplentes_casa; let i = index"
                        [ngClass]="i % 2 ? '' : 'bgWhite' ">
                        <div class="col-2">
                            <p class="numero-jogador m-0">{{onze.numero}}</p>
                        </div>
                        <div class="col-6 d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{onze.nome | uppercase}}</p>
                        </div>
                        <div class="col d-md-flex justify-content-md-end">
                            <p class="d-flex aling-items-center justify-content-end mb-0 margin-minuto"
                                *ngFor="let acont of onze.acontecimentos"><img
                                    src="assets/img/acontecimentos/{{acont.imagem}}" alt=""
                                    class="img-fluid-acont pr-1"> ({{acont.minuto}})</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-md-12 col-lg-6 order-4 order-md-4 no-padding">
                <div class="container box-shadow">
                    <div class="row d-flex align-items-center hRow3 "
                        *ngFor="let onze of fichaJogo.suplentes_fora; let i = index"
                        [ngClass]="i % 2 ? '' : 'bgWhite' ">
                        <div class="col-2">
                            <p class="numero-jogador m-0">{{onze.numero}}</p>
                        </div>
                        <div class="col-6 d-flex align-items-start p-0">
                            <p class="nome m-0 ml-2 ml-md-1">{{onze.nome | uppercase}}</p>
                        </div>
                        <div class="col d-md-flex justify-content-md-end">
                            <p class="d-flex aling-items-center justify-content-end mb-0 margin-minuto"
                                *ngFor="let acont of onze.acontecimentos"><img
                                    src="assets/img/acontecimentos/{{acont.imagem}}" alt=""
                                    class="img-fluid-acont pr-1"> ({{acont.minuto}})</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
