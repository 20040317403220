import { trigger, transition, style, animate } from '@angular/animations';
import { Component,  OnDestroy,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class NoticiasComponent implements OnInit, OnDestroy {

  arrayFilter: string[] = [
    'Equipa Principal',
    'Sub-19',
    'Sub-17',
    'Sub-15',
    'Sub-13',
    'Sub-14',
    'Sub-11',
    'Traquinas',
    'Petizes',
    'Pestinhas',
    'Ténis',
    'Hipismo',
    'Veteranos',
    'Esports Proclubs',
    'Esports 1v1',
    'Outro',
    'Basquetebol'
  ]

  news = []
  sub: Subscription[] = []

  current: number = 1
  totalNews: number
  limit: number


  constructor(
    private dataService: DataService,
    public state: StateService
  ) {
  }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getNoticias(1)


  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias(value) {
    this.sub.push(this.dataService.getNoticias(value).subscribe(
      (res: any) => {
        this.limit = res.limite
        res.noticias.forEach(el => {
          el.escalao = this.arrayFilter[el.escalao-1]
        });
        this.news = res.noticias
        this.totalNews = res.total_noticias
      }
    ))
  }

  getPage(value) {
    this.current = value
    this.getNoticias(this.current)
  }

}
