import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { resultados } from 'src/app/interfaces/resultados';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-sub11-resultados',
  templateUrl: './sub11-resultados.component.html',
  styleUrls: ['./sub11-resultados.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class Sub11ResultadosComponent implements OnInit, OnDestroy {

  resultados: resultados[] = []
  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getResultados()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getResultados() {
    this.sub.push(this.dataService.getResultados(11).subscribe(res => {
      this.resultados = Array.isArray(res) ? res : [];
    }))
  }

}
