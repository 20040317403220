
<div class="container-fluid mobile-navbar">
  <div class="row" >
    <div class="col-12 p-0" >
      <div class="position-news-inicio">
        <div class="news-inicio-div">
          <a [routerLink]="['/noticia', destaques[1]?.noticia]" class="new_hm news-inicio">
            <div class="fix-animation-newhm ">
              <div class="img-news-inicio miniaturas-noticia" style="background-image: url('assets/img/noticias/{{destaques[1]?.imagem_pequena}}'); background-position: center; background-size: cover"></div>
            </div>
            <div class="padding-news-inicio">
              <h5 class="data-noticia-inicio">{{destaques[1]?.data}}</h5>
              <h3 class="titulo-noticia-inicio">{{destaques[1]?.titulo}}</h3>
              <a class="button arrow text-light" [routerLink]="['/noticia', destaques[1]?.noticia]">Ler Notícia</a>
            </div>
          </a>
        </div>
        <div class="news-inicio-div">
          <a [routerLink]="['/noticia' , destaques[2]?.noticia]" class="new_hm news-inicio">
            <div class="fix-animation-newhm ">
              <div class="img-news-inicio miniaturas-noticia" style="background-image: url('assets/img/noticias/{{destaques[2]?.imagem_pequena}}'); background-position: center; background-size: cover"></div>
            </div>
            <div class="padding-news-inicio">
              <h5 class="data-noticia-inicio">{{destaques[2]?.data}}</h5>
              <h3 class="titulo-noticia-inicio">{{destaques[2]?.titulo}}</h3>
              <a class="button arrow text-light" [routerLink]="['/noticia', destaques[2]?.noticia]">Ler Notícia</a>
            </div>
          </a>
        </div>
      </div>

    <div class="filter-news img-fluid-home d-flex align-items-end align-items-md-center" style="background: url('assets/img/noticias/{{destaques[0]?.imagem_full}}');">
        <div class="row filterZindex m-0 pb-5 pb-md-0 ml-3 ml-md-5">
          <div class="col-12 col-md-8 offset-md-1">
            <div class="bg-skew d-flex align-items-center justify-content-center"><p class="skew-text m-0 text-uppercase">{{destaques[0]?.escalao}}</p></div>
            <h1 class="home-title pt-0 pt-2">{{destaques[0]?.titulo}}</h1>
            <a class="button arrow text-light" [routerLink]="['/noticia', destaques[0]?.noticia]">Ler Notícia</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg2">
  <div class="container content-wrap">
    <div class="row whitecontainer">

      <!-- comentado ate novo sorteio -->

    <div class="col-lg-6 padding-box">
        <div class="row" data-aos="fade-down">
          <div class="col-lg-6 pr-0">
            <h2 class="titulos">Próximo Jogo</h2>
          </div>
          <div class="col-lg-6">
            <div class="text-right fix-button">
              <a class="button arrow" [routerLink]="['/futebol/equipa-principal/calendario']">Calendário</a>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" data-aos-delay="100">
          <div class="row div-competicao">
            <div class="col-12">
              <p class="competicao">{{jogo.competicao}}</p>
            </div>
          </div>
          <div class="row bg2 paddingfix-proximojogo">
            <div class="col-3 offset-2 p-0">
              <div class="float-right">
                <img class="clubelogo-jogo" src="assets/img/clubes/{{jogo.logo_casa == null ? 'logo.webp' : jogo.logo_casa}}" width="160px" height="160px" alt="{{jogo.clube_casa}}">
                <p class="nomeclube-jogo">{{jogo.clube_casa | uppercase}}</p>
              </div>
            </div>
            <div class="col-2 p-0">
              <p class="vs-text">vs</p>
            </div>
            <div class="col-3 p-0">
              <img class="clubelogo-jogo" src="assets/img/clubes/{{jogo.logo_fora == null ? 'logo.webp' : jogo.logo_fora}}" width="160px" height="160px" alt="{{jogo.clube_fora}}">
              <p class="nomeclube-jogo">{{jogo.clube_fora | uppercase}}</p>
            </div>
          </div>
          <div class="row bg2 paddingfix-horajogo">
            <div class="col-12">
            </div>
            <div class="col-12">
              <p class="data-jogo">{{jogo.data}}</p>
              <p class="hora-estadio">{{jogo.horario}} <span *ngIf="jogo.estadio !== ''">| {{jogo.estadio}}</span></p>
            </div>
          </div>
        </div>
        </div>


      <div class="col-lg-6 padding-box">
        <div class="row" data-aos="fade-down" data-aos-delay="200">
          <div class="col-lg-6 pr-0">
            <h2 class="titulos">2024/2025</h2>
          </div>
          <div class="col-lg">
            <div class="text-right fix-button">
              <a class="button arrow" [routerLink]="['/futebol/equipa-principal/classificacao']">Classificação</a>
            </div>
          </div>
        </div>
        <div class="row"  data-aos="fade-down" data-aos-delay="300">
          <div class="col-12 p-0">
            <table class="table m-0">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" class="text-center">POS.</th>
                  <th scope="col">CLUBE</th>
                  <th scope="col" class="text-center">J</th>
                  <th scope="col" class="text-center">PTS</th>
                </tr>
              </thead>
              <thead *ngFor="let class of classificacao; let i = index" [ngClass]="[class.clube == 'CD Tondela' ? 'thead-light' : '', i % 2 ? 'bgTable' : '']">
                <tr>
                  <th scope="col" class="text-center">{{class.posicao}}</th>
                  <th scope="col"><img src="assets/img/clubes/{{class.logo == null ? 'logo.webp' : class.logo}}" width="160px" height="160px" class="fix-nomeclube" alt="{{class.clube}}" >{{class.clube | uppercase}}</th>
                  <th scope="col" class="text-center">{{class.jogos}}</th>
                  <th scope="col" class="text-center">{{class.pontos}}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

<div class="container-fluid bgorgulho">
  <div class="container">
    <div class="row">
      <div class="col-4 col-md-5 col-lg-7" data-aos="fade-down">
        <img class="jogador ml-lg-5" src="assets/img/adeptos.webp" alt="Adepto">
      </div>
      <div class="col-8 col-md-7 col-lg-5" data-aos="fade-down" data-aos-delay="300">
        <div class="pr-lg-5">
          <img class="img-fluid orgulhobeirao" width="366.4px" height="106.8px" src="assets/img/orgulhobeirao.svg" alt="Orgulho Beirão">
          <div class="text-right">
              <button class="btn btn--skew btn-default yellow-button" [routerLink]="['/socios/ser-socio']">
                <div class="button arrow">Sócios</div>
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid content-wrap bg2">
  <div class="container">
    <div class="row"  data-aos="fade-down">
      <div class="col-lg-6 p-0">
        <h2 class="titulos">Notícias</h2>
      </div>
      <div class="col-lg-6 p-0">
        <div class="text-right fix-button">
          <a class="button arrow" [routerLink]="['/noticias']">Ver Mais</a>
        </div>
      </div>
    </div>

    <div class="row" id="news-cdt">

      <div class="col-lg-8 p-1">
        <a [routerLink]="['/noticia', ultimasNoticias[0]?.noticia]" class="new_hm">
          <h4 class="tag-noticia">{{ultimasNoticias[0]?.escalao}}</h4>
          <h3 class="titulo-noticia">{{ultimasNoticias[0]?.titulo}}</h3>
          <div class="position-linknew">
            <a class="button arrow button-news">Ler Notícia</a>
          </div>
          <div class="filter-news img-new">
            <img class="img-fluid" width="100%" height="100%" src="assets/img/noticias/{{ultimasNoticias[0]?.imagem_noticia}}" alt="{{ultimasNoticias[0]?.titulo}}">
          </div>
        </a>
      </div>

      <div class="col-6 col-lg-4 p-1">
        <a [routerLink]="['/noticia', ultimasNoticias[1]?.noticia]" class="new_hm">
          <h4 class="tag-noticia">{{ultimasNoticias[1]?.escalao}}</h4>
          <h3 class="titulo-noticia">{{ultimasNoticias[1]?.titulo}}</h3>
          <div class="position-linknew">
            <a class="button arrow button-news">Ler Notícia</a>
          </div>
          <div class="filter-news img-new" style="height: 100%">
            <div class="noticia-background" style="background-image: url(assets/img/noticias/{{ultimasNoticias[1]?.imagem_noticia}}); background-size: cover; background-position: center; height: 100%" ></div>
          </div>
        </a>
      </div>

      <div class="col-6 col-lg-4 p-1">
        <a [routerLink]="['/noticia', ultimasNoticias[2]?.noticia]" class="new_hm">
        <h4 class="tag-noticia">{{ultimasNoticias[2]?.escalao}}</h4>
        <h3 class="titulo-noticia">{{ultimasNoticias[2]?.titulo}}</h3>
        <div class="position-linknew">
          <a class="button arrow button-news">Ler Notícia</a>
        </div>
        <div class="filter-news img-new">
          <img class="img-fluid" width="100%" height="100%" src="assets/img/noticias/{{ultimasNoticias[2]?.imagem_listagem}}" alt="{{ultimasNoticias[2]?.titulo}}">
        </div>
        </a>
      </div>

      <div class="col-6 col-lg-4 p-1">
        <a [routerLink]="['/noticia', ultimasNoticias[3]?.noticia]" class="new_hm">
          <h4 class="tag-noticia">{{ultimasNoticias[3]?.escalao}}</h4>
          <h3 class="titulo-noticia">{{ultimasNoticias[3]?.titulo}}</h3>
          <div class="position-linknew">
            <a class="button arrow button-news">Ler Notícia</a>
          </div>
          <div class="filter-news img-new">
            <img class="img-fluid" width="100%" height="100%" src="assets/img/noticias/{{ultimasNoticias[3]?.imagem_listagem}}" alt="{{ultimasNoticias[3]?.titulo}}">
          </div>
        </a>
      </div>

      <div class="col-6 col-lg-4 p-1">
        <a [routerLink]="['/noticia', ultimasNoticias[4]?.noticia]" class="new_hm">
          <h4 class="tag-noticia">{{ultimasNoticias[4]?.escalao}}</h4>
          <h3 class="titulo-noticia">{{ultimasNoticias[4]?.titulo}}</h3>
          <div class="position-linknew">
            <a class="button arrow button-news">Ler Notícia</a>
          </div>
          <div class="filter-news img-new">
            <img class="img-fluid" width="100%" height="100%" src="assets/img/noticias/{{ultimasNoticias[4]?.imagem_listagem}}" alt="{{ultimasNoticias[4]?.titulo}}">
          </div>
        </a>
      </div>

    </div>

  </div>
</div>


<!-- SHOP DESKTOP -->

<div class="container content-wrap pb-0 pb-md-4 mb-5 ">
  <div class="row"  data-aos="fade-down">
    <div class="col-lg-6">
      <h2 class="titulos">Produtos em destaque</h2>
    </div>
    <div class="col-lg-6">
      <div class="text-right fix-button">
        <a class="button arrow" href="https://cdtondela.pt/loja/index.php?route=common/home">Ver Todos</a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 r-pad-mobile">
    <owl-carousel-o [options]="customOptions">

      <ng-template carouselSlide>
        <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=236" target="_blank" class="new_hm text-decoration-none" (mouseover)="lojahover('loja2')" (mouseleave)="lojaleave()">
          <div class="img-new">
            <img src="assets/img/produtos/camisolaprincpial2024.jpeg" width="100%" height="100%" alt="Camisola Principal CD Tondela">
          </div>
          <h4 class="produto">Camisola Principal</h4>
          <h4 class="preco">€55,00</h4>
        </a>
        <div class="text-center">
          <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=236"  target="_blank">
            <button class="btn btn--skew btn-default loja2">
              <div class="button arrow button-produto">Ver Produto</div>
            </button>
          </a>
        </div>
      </ng-template>




      <ng-template carouselSlide>
        <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=237" target="_blank"class="new_hm text-decoration-none" (mouseover)="lojahover('loja3')" (mouseleave)="lojaleave()">
          <div class="img-new">
            <img src="assets/img/produtos/camisola2.jpeg" width="100%" height="100%" alt="Camisola Alternativa CD Tondela">
          </div>
          <h4 class="produto">Camisola Alternativa</h4>
          <h4 class="preco">€55,00</h4>
        </a>
        <div class="text-center">
          <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=237" target="_blank">
            <button class="btn btn--skew btn-default loja3">
              <div class="button arrow button-produto">Ver Produto</div>
            </button>
          </a>
        </div>

      </ng-template>

      <ng-template carouselSlide>
        <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=238" target="_blank"  class="new_hm text-decoration-none" (mouseover)="lojahover('loja4')" (mouseleave)="lojaleave()">
          <div class="img-new">
            <img src="assets/img/produtos/camisolagr.jpeg" width="100%" height="100%" alt="Camisola Alternativa CD Tondela">
          </div>
          <h4 class="produto">Camisola Guarda-Redes</h4>
          <h4 class="preco">€55,00</h4>
        </a>
        <div class="text-center">
          <a href="https://cdtondela.pt/loja/index.php?route=product/product&product_id=238" target="_blank" >
            <button class="btn btn--skew btn-default loja4">
              <div class="button arrow button-produto">Ver Produto</div>
            </button>
          </a>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
</div>

  <div class="container-fluid bgRedesSociais">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 fix-redessociais">
          <h4 class="tagorgulhobeirao">#Orgulhobeirao</h4>
          <h3 class="text-banner mb-0">Segue-nos nas redes sociais</h3>
        </div>
        <div class="col-12 socialmedia-btn d-block d-lg-none" id="socialmedia-cdt">
          <div class="row pt-2 pt-lg-4 pt-md-0">
            <div class="col-6 text-center">
              <a href="https://www.facebook.com/CDTondela/" target="_blank">
                <button class="btn btn--skew btn-default yellow-button">
                  <div class="button arrow">Facebook</div>
                </button>
              </a>
            </div>
            <div class="col-6 text-center">
              <a href="https://www.instagram.com/cdtondela/" target="_blank">
                <button class="btn btn--skew btn-default yellow-button">
                  <div class="button arrow">Instagram</div>
                </button>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-center">
              <a href="https://twitter.com/cdtondela1933" target="_blank">
                <button class="btn btn--skew btn-default yellow-button">
                  <div class="button arrow">Twitter</div>
                </button>
              </a>
            </div>
            <div class="col-6 text-center">
              <a href="https://www.youtube.com/user/cdtondela" target="_blank">
                <button class="btn btn--skew btn-default yellow-button">
                  <div class="button arrow">Youtube</div>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-none d-lg-block">
          <img class="jogador2" src="assets/img/naf02341_optimized.png" alt="Social Media Player">
        </div>
        <div class="col-md-5 fix-redessociais2 d-none d-lg-block">
          <div class="row">
            <div class="col-6">
              <a href="https://www.facebook.com/CDTondela/" target="_blank">
                <h3 class="numbersocialmedia">+70<span class="size-mil">mil</span></h3>
                <h3 class="namesocialmedia">Facebook</h3>
              </a>
            </div>
            <div class="col-6">
              <a href="https://www.instagram.com/cdtondela/" target="_blank">
                <h3 class="numbersocialmedia">+50<span class="size-mil">mil</span></h3>
                <h3 class="namesocialmedia">Instagram</h3>
              </a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <a href="https://twitter.com/cdtondela1933" target="_blank">
                <h3 class="numbersocialmedia">+30<span class="size-mil">mil</span></h3>
                <h3 class="namesocialmedia">Twitter</h3>
              </a>
            </div>
            <div class="col-6">
              <a href="https://www.youtube.com/user/cdtondela" target="_blank">
                <h3 class="numbersocialmedia">+5<span class="size-mil">mil</span></h3>
                <h3 class="namesocialmedia">Youtube</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
