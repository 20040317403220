import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class StateService {
  public sidebar: boolean
  public appear: boolean = false
  public loading: boolean = false;
  public inited: boolean = false;
  public nextPage = '';

  haveLogin = new BehaviorSubject<boolean>(false);
  public readonly _haveLogin  = this.haveLogin.asObservable();

  isAssociate = new BehaviorSubject<boolean>(false);
  public readonly _isAssociate  = this.isAssociate.asObservable();

  private  _showModalNotification = new BehaviorSubject<any>(null);
  public readonly showModalNotification  = this._showModalNotification.asObservable();

  constructor(private router: Router) {}

  showNotification(title:any, message:any, type:any) {
    this._showModalNotification.next({title, message, type});
  }

  hideNotification(){
    this._showModalNotification.next(null)
  }

  saveInStorage(haveLogin:any,isAssociate:any){
    sessionStorage.clear()
    sessionStorage.setItem('$aGF2ZWxvZ2lu',haveLogin)
    sessionStorage.setItem('$aXNhc3NvY2lhdGU',isAssociate)
  }

  checkHaveLogin(){

    const haveLogin:any = sessionStorage.getItem('$aGF2ZWxvZ2lu');
    const isAssociate:any= sessionStorage.getItem('$aXNhc3NvY2lhdGU');
    if(haveLogin!= null) {
      this.haveLogin.next(haveLogin);
      if(isAssociate != null)
        this.isAssociate.next(isAssociate)
    }

    else {
      this.isAssociate.next(isAssociate)
      this.haveLogin.next(false);
    }

    return this.haveLogin.value;
  }

  cleanStorageAuth(){
    this.haveLogin.next(false);
    this.isAssociate.next(false);
    sessionStorage.removeItem('$aGF2ZWxvZ2lu');
    sessionStorage.removeItem('$aXNhc3NvY2lhdGU');
  }

  handle2TicketLogic(data: any) {
    switch ((data.detail || {}).type) {
      case 'logged':
        if (!(data.detail || {}).logged) {
          this.haveLogin.next(false);
          this.checkHaveLogin();
          this.cleanStorageAuth();
        } else {
          this.haveLogin.next(true);
          this.isAssociate.next((data.detail || {}).isAssociate);
          this.saveInStorage(true, (data.detail || {}).isAssociate);
          this.checkHaveLogin();
          if (this.router.url === 'bilheteira/login')
            this.router.navigate(['bilheteirbilheteira/lista-eventosa'])
        }
        break;
      case "loading":
        this.loading = (data.detail || {}).loading;
        break;
      case "logout":
        this.cleanStorageAuth();
        break;

      case "page-change":
        if (!this.inited) {
          this.inited = true;
          return;
        }

        switch ((data.detail || {}).page) {
          case "login":
              this.router.navigate(['bilheteira/login']);
            break;
          case "bilheteira":
              this.router.navigate(['bilheteira/lista-eventos']);
            break;
          case 'editar-perfil':
              this.router.navigate(['bilheteira/editar-perfil']);
              break;
          case 'quotas':
              this.router.navigate(['bilheteira/quotas']);
              break;
          case 'bilhetes':
              this.router.navigate(['bilheteira/bilhetes']);
              break;
          case 'ask-recover-password':
              this.router.navigate(['bilheteira/ask-recover-password']);
              break;
          case 'criar-conta-bilheteira':
            this.router.navigate(['biheteira/criar-conta']);
            break;
        }

        break;
    }
  }
}
