import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { jogador, jogadorFormacao } from 'src/app/interfaces/jogadores'
import { classificacao } from 'src/app/interfaces/classificacao'
import { calendario } from 'src/app/interfaces/calendario'
import { resultados } from 'src/app/interfaces/resultados'
import { noticias } from 'src/app/interfaces/noticias'

import { ficha } from 'src/app/interfaces/ficha'
import { tecnicos } from 'src/app/interfaces/equipa-tecnica'
import { descontos } from 'src/app/interfaces/descontos'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  base = "assets/data/"

  constructor(
    private http: HttpClient
  ) { }

  getImageTickets() {
    return this.http.get(this.base + "bilheteira.json")
  }

  getPopUp() {
    return this.http.get(this.base + "popup.json")
  }

  getPlayers() {
    return this.http.get<jogador[]>(this.base + "jogadores.json")
  }

  getRankingParcial() {
    return this.http.get<classificacao[]>(this.base + "classificacao_home.json")
  }

  getRanking(sub = 0) {
    switch(sub) {
      case 19:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub19.json")
      case 17:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub17.json")
      case 15:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub15.json")
      case 14:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub14.json")
      case 13:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub13.json")
      case 11:
        return this.http.get<classificacao[]>(this.base + "classificacao_sub11.json")
      default:
        return this.http.get<classificacao[]>(this.base + "classificacao.json")
    }
  }

  getResultados(sub = 0) {
    switch(sub) {
      case 19:
        return this.http.get<resultados[]>(this.base + "resultados_sub19.json")
      case 17:
        return this.http.get<resultados[]>(this.base + "resultados_sub17.json")
      case 15:
        return this.http.get<resultados[]>(this.base + "resultados_sub15.json")
      case 14:
        return this.http.get<resultados[]>(this.base + "resultados_sub14.json")
      case 13:
        return this.http.get<resultados[]>(this.base + "resultados_sub13.json")
      case 11:
        return this.http.get<resultados[]>(this.base + "resultados_sub11.json")
      default:
        return this.http.get<resultados[]>(this.base + "resultados.json")
    }
  }

  getCalendario(sub = 0) {
    switch(sub) {
      case 19:
        return this.http.get<calendario[]>(this.base + "calendario_sub19.json")
      case 17:
        return this.http.get<calendario[]>(this.base + "calendario_sub17.json")
      case 15:
        return this.http.get<calendario[]>(this.base + "calendario_sub15.json")
      case 14:
        return this.http.get<calendario[]>(this.base + "calendario_sub14.json")
      case 13:
        return this.http.get<calendario[]>(this.base + "calendario_sub13.json")
      case 11:
        return this.http.get<calendario[]>(this.base + "calendario_sub11.json")
      default:
        return this.http.get<calendario[]>(this.base + "calendario.json")
    }
  }

  getFormacao(sub = 0) {
    switch(sub) {
      case 19:
        return this.http.get<jogadorFormacao[]>(this.base + "sub19.json")
      case 17:
        return this.http.get<jogadorFormacao[]>(this.base + "sub17.json")
      case 15:
        return this.http.get<jogadorFormacao[]>(this.base + "sub15.json")
      case 14:
        return this.http.get<jogadorFormacao[]>(this.base + "sub14.json")
      case 13:
        return this.http.get<jogadorFormacao[]>(this.base + "sub13.json")
      case 11:
        return this.http.get<jogadorFormacao[]>(this.base + "sub11.json")
      default:
        return this.http.get<jogadorFormacao[]>(this.base + "jogadores.json")
    }
  }

  getFicha(id: number) {
    return this.http.get<ficha[]>("https://cdtondela.pt/api/ficha.php?jogo=" + id)
  }

  getTecnicos() {
    return this.http.get<tecnicos[]>(this.base + "equipa-tecnica.json")
  }

  getNoticias(page: number) {
    return this.http.get<noticias[]>("https://cdtondela.pt/api/noticias.php?pagina=" + page)
  }

  getNoticiasFiltradas(id: number) {
    return this.http.get<noticias[]>("https://cdtondela.pt/api/noticia_categoria.php?escalao=" + id)
  }

  getNoticia(noticia: number) {
    return this.http.get<noticias[]>("https://cdtondela.pt/api/noticia.php?noticia=" + noticia)
  }

  getDescontos() {
    return this.http.get<descontos[]>(this.base + "descontos.json")
  }

  getTraquinas() {
    return this.http.get<jogadorFormacao[]>(this.base + "traquinas.json")
  }

  getPetizes() {
    return this.http.get<jogadorFormacao[]>(this.base + "petizes.json")
  }

  getFormacaoStaff() {
    return this.http.get<jogadorFormacao[]>(this.base + "formacao_staff.json")
  }

  postSerSocio(input: any) {
    return this.http.post("https://cdtondela.pt/api/socios.php", input, {responseType: 'text'}).pipe(
      map(
        (response) => {
          if(response) {
            return response
          }
        },
        (error: any) => {
          return error
        }
      )
    )
  }
 }
