<app-modalidades-banner></app-modalidades-banner>
<app-modalidades-navbar></app-modalidades-navbar>
<div @fade class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">TÉNIS - CD TONDELA</p>
        <p class="descEst text-justify">
            O Ténis é hoje uma realidade não só no Clube Desportivo de Tondela, na cidade, concelho e região, muito por força da dinâmica tida por todos aqueles que fizeram ou fazem parte do “Ténis Auriverde” como os membros das Direções do CDT, Estrutura Técnica da modalidade, tenistas e todos os sócios que desde a primeira hora acolheram com entusiasmo esta modalidade oficial do CDT.
        </p>
    </div>
</div>


<div class="container-fluid bg_3 mb-lg-5">
    <div class="container fix-mobile-padding">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                        <div class="carousel-item ">
                            <img class="d-block w-100" src="assets/img/tenis/tenis-regresso.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img/tenis/tenis-regresso.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/tenis/tenis-regresso.jpg" alt="Ténis">
                        </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                        </a>
                </div>
                  
            </div>
            <div class="col-12 col-lg-3 mb-5 mb-lg-3 centerNumbers">
                <p class="numbers">2015</p>
                <p class="desc">ANO DE FUNDAÇÃO</p>

                <p class="numbers">Federação Portuguesa de Ténis</p>
                <p class="desc">AFILIAÇÃO</p>

                <p class="numbers">Associação de Ténis de Viseu</p>
                <p class="desc">AFILIAÇÃO</p>

                <p class="numbers">Prof. André Alexandrino</p>
                <p class="desc">COORDENADOR TÉCNICO</p>
            </div>
        </div>
    </div>
</div>





<div @fade class="container-fluid bg1">
    <div class="container content-wrap">
        <div class="row">
            <div class="col-12">
                <h2 class="titleEst text-uppercase">História da Modalidade</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 dividir-2">
                <p class="text-modalidades">A modalidade de Ténis do Clube Desportivo de Tondela foi fundada no ano de 2015, iniciando a sua atividade no mês de setembro em parceria com a Associação de Educação Física e Desporto de Tondela, após aprovação de um projeto elaborado por Hugo Pizarro, no sentido de procurar promover a prática desportiva da modalidade junto da população em contexto competitivo ou lazer, algo que na época nenhuma coletividade da cidade ou concelho proporcionava.
                </p>
                <p class="text-modalidades">Em julho de 2016, esta modalidade passou a integrar em exclusividade a secção de Ténis do Clube Desportivo de Tondela, recebendo ao longo dos anos, na sua Escola de Ténis, cada vez mais praticantes oriundos de várias localidades do nosso concelho mas também de outros circundantes, onde muitos deles já representam o Desportivo com participações em torneios federados ou sociais.
                </p>
                <p class="text-modalidades">Com a criação desta modalidade no CDT, sob o lema “Pratica Desporto, Joga
                    Ténis no CDT”, a vida desportiva no concelho ganhou uma nova alma gerando grande interesse dos
                    tondelenses em torno do Ténis, um aumento gradual do número de praticantes de várias faixas etárias,
                    assim como foi notório o contributo deste projeto para a revitalização de vários courts na cidade
                    que até então não tinham uma utilização diária.
                </p>
                <p class="text-modalidades">Sob o lema “Pratica Desporto, Joga Ténis no CDT”, esta modalidade auriverde foi ganhando força no concelho, gerando grande interesse na população pela sua prática, refletindo-se num aumento gradual significativo do número de praticantes de várias faixas etárias.  A revitalização de vários courts de ténis ao ar-livre na cidade que até então não tinham uma utilização diária com a promoção de aulas, treinos ou torneios é agora uma realidade.
                </p>

                <p class="text-modalidades">Para fortalecer ainda mais a dinâmica do ténis em Tondela, a estrutura técnica da modalidade auriverde, teve a iniciativa de propor um projeto inovador ao Município de Tondela e ao Clube Desportivo de Tondela para a implementação de um court de ténis localizado no espaço do pavilhão desportivo do Complexo do Estádio João Cardoso, projeto esse que foi aprovado e concluído, demonstrando-se de vital importância por ter permitido revitalizar com uma nova modalidade um espaço desportivo “indoor”, assegurar toda a sua atividade em caso de más condições climáticas, assim como possibilitar que o Desportivo tivesse o único espaço coberto do concelho para a prática do Ténis.
                </p>

                <p class="text-modalidades">São vários os projetos ou atividades realizadas ao longo dos anos pelo Ténis CDT, desde a promoção aos praticantes de aulas/treinos individuais ou de grupo na sua Escola de Ténis como em parcerias com escolas ou coletividades que organizam Atividades Tempos Livres (ATL’s); proposta ao Município de Tondela de implementação e organização da modalidade de Ténis nos Jogos Desportivos do Concelho de Tondela; comemoração de datas/épocas festivas com eventos; contributo para uma melhoria dos espaços desportivos para a prática desportiva; participação ativa dos tenistas em várias atividades relacionadas com a vida CDT, entre outras.
                </p>
                <p class="text-modalidades">O “Torneio de Natal – Cidade de Tondela”, organizado pelo CDT, é um dos eventos que marca a história do ténis auriverde, por ser o mais antigo e organizado continuamente há mais anos no concelho.
                </p>
                <p class="text-modalidades">A Escola de Ténis CDT tem ao longo destes anos desenvolvido principalmente a sua atividade no Court de Ténis do Parque Urbano (outdoor) e Pavilhão Desportivo do Estádio João Cardoso (indoor). </p>

                <p class="text-modalidades">Em 2020, foi dado um novo passo para o fortalecimento e dinâmica desta modalidade do Clube Desportivo de Tondela com a criação de um novo polo de atividade na vila do Caramulo, alastrando a prática do Ténis a outro ponto de referência do concelho com a promoção de treinos durante a semana.</p>  
            </div>
        </div>
    </div>
</div>

<div class="container py-5">
    <h1 class="titleEst">NOTÍCIAS</h1>
    <div class="container bg-news">
        <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
            <div class="col-lg-5">
                <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>
                <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>

                <h2 class="news-title">{{new.titulo}}</h2>
                <p class="news-date">{{new.data}}</p>
                <p class="news-subtitle">{{new.subtitulo}}
                </p>
                <div class="button arrow">Ler Notícia</div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bgTenis d-none d-lg-block">
    <div class="container">
        <div class="row alt-banner">
            <div class="col-6 align-self-center pos-textleft">
                <h2 class="title-tenis">JOGA TÉNIS NO CDT!</h2>
                <h3 class="desc-tenis">- Aulas Individuais</h3>
                <h3 class="desc-tenis">- Aulas de Grupo</h3>
            </div>
            <div class="col-6 align-self-center pos-textleft text-right">
                <h2 class="inscrever">INSCREVE-TE JÁ!</h2>
                <a class="email" href="mailto:tenis@cdtondela.pt" target="_blank">tenis@cdtondela.pt</a>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid bgTenis d-block d-lg-none">
    <div class="container">
        <div class="row alt-banner ">
            <div class="col-12 align-self-center pos-textleft">
                <h2 class="inscrever">INSCREVE-TE JÁ!</h2>
                <a class="email" href="mailto:tenis@cdtondela.pt" target="_blank">tenis@cdtondela.pt</a>
            </div>
        </div>
    </div>
</div>