import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'
moment.locale('pt')

@Component({
  selector: 'app-agenda-eventos',
  templateUrl: './agenda-eventos.component.html',
  styleUrls: ['./agenda-eventos.component.scss']
})
export class AgendaEventosComponent implements OnInit {

  date = moment()
  daysArr;
  eventDay = {
    dia: '', 
    mes: '', 
    ano: '', 
    dia_semana: ''
  }
  constructor() { }

  ngOnInit(): void {
    this.daysArr = this.createCalendar(this.date)
  }

  createCalendar(month) {
    let firstDay = moment(month).startOf('M')
    let days = Array.apply(null, {length: month.daysInMonth()})
      .map(Number.call, Number)
      .map((n) => {
        return moment(firstDay).add(n,'d')
      })

    for(let i = 0; i < firstDay.weekday(); i++) {
      days.unshift(null)
    }
    return days
  }

  today(day) {
    if(!day) return false
    return moment().format('L') === day.format('L')
  }

  nextMonth() {
    this.date.add(1, 'M')
    this.daysArr = this.createCalendar(this.date)
  }

  prevMonth() {
    this.date.subtract(1, 'M')
    this.daysArr = this.createCalendar(this.date)
  }

  getDay(day) {
    //moment(day).format('D') dia do mes em numero - 11 exemplo
    //moment(day).format('dddd') dia da semana - segunda-feira exemplo
    //moment(day).format('MMMM') mes - novembro exemplo
/*     this.eventDay = moment(day).format('dddd,' + 'D' + ' ' + 'MMMM' + ' ' +  'YYYY')
 */
    this.eventDay = {
      ano: moment(day).format('YYYY'),
      dia: moment(day).format('D'),
      dia_semana: moment(day).format('dddd'),
      mes: moment(day).format('MMMM')
    }
  }

  addEvents(day) {
    let events = ['25/11/2020', '23/11/2020', '12/11/2020', '25/12/2020']

    for(let i=0; i<= events.length-1; i++) {
      if(events[i] === moment(day).format('L')) {
        return moment(day).format('L') === day.format('L')
      } 
    } 
  }

}
