import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { descontos } from 'src/app/interfaces/descontos';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-descontos',
  templateUrl: './descontos.component.html',
  styleUrls: ['./descontos.component.scss']
})
export class DescontosComponent implements OnInit, OnDestroy {

  descontos: string = 'alojamento'
  sub: Subscription[] = []

  alojamento: descontos[] = []
  desporto: descontos[] = []
  diversos: descontos[] = []
  entretenimento: descontos[] = []
  saude: descontos[] = []

  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    $('.hRow2').scrollLeft(0)
    this.getDescontos()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getDescontos() {
    this.sub.push(this.data.getDescontos().subscribe(res => {
      this.alojamento = res.filter(res => res.category == 'alojamento')
      this.desporto = res.filter(res => res.category == 'desporto')
      this.diversos = res.filter(res => res.category == 'diversos')
      this.entretenimento = res.filter(res => res.category == 'entretenimento')
      this.saude = res.filter(res => res.category == 'saude')
    }))
  }

}
