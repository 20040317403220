
<div class="container-fluid bg filterImg align-items-center fix-padding">
    <div class="container">
        <div class="row filterIndex">
            <div class="col-12 col-md-10">
                <p class="equipaTitulo d-md-flex">CD TONDELA</p>
                <p class="equipaDescricao">Notícias</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2">
            <div id="plantel" class="col-8 col-md-2 col-lg-2 centerTabs border-left border-right outline"
                routerLink="/noticias" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <span class="tabs mx-auto">NOTÍCIAS</span>
            </div>

           <!--  <div id="plantel" class="col-8 col-md-2 col-lg-2 centerTabs border-left border-right outline"
                routerLink="/media" >
                <span class="tabs mx-auto">MEDIA</span>
            </div> -->
        </div>
    </div>
</div>

<app-spinner *ngIf="state.loading"></app-spinner>

<div @fade *ngIf="!state.loading"  class="container-fluid py-5">


  <div class="container bg-news">
        <div class="row py-3 border-news touch cursor" *ngFor="let new of news | paginate: {itemsPerPage: limit, currentPage: current, totalItems: totalNews}" [routerLink]="['/noticia/', new.noticia]">
            <div class="col-lg-5">
                <div class="bg-skew d-flex d-block d-md-none align-items-center justify-content-center fix-mobile-tag">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>
                <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-2">
                <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                    <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                </div>

                <h2 class="news-title">{{new.titulo}}</h2>
                <p class="news-date">{{new.data}}</p>
                <p class="news-subtitle">{{new.subtitulo}}
                </p>
                <div class="button arrow">Ler Notícia</div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="paginacao d-none d-lg-block">
            <pagination-controls (pageChange)="getPage($event)" previousLabel="Anterior" nextLabel="Seguinte" maxSize="7"></pagination-controls>
        </div>

        <div class="paginacao d-block d-lg-none pl-0">
            <pagination-controls (pageChange)="getPage($event)" previousLabel="" nextLabel="" maxSize="4"></pagination-controls>
        </div>
    </div>



</div>
