import { Component, OnInit , Input} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-banner-generic',
  templateUrl: './banner-generic.component.html',
  styleUrls: ['./banner-generic.component.scss']
})
export class BannerGenericComponent implements OnInit {
  @Input() image: string
  @Input() title: string
  @Input() subTitle: string
  currentRoute: string = ''

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        const url = e.urlAfterRedirects

        if (url.includes('institucional')) {
          this.currentRoute = 'INSTITUCIONAL'
        }

        if (url.includes('contactos')) {
          this.currentRoute = 'CONTACTOS'
        }

        if (url.includes('como-chegar')) {
          this.currentRoute = 'COMO CHEGAR'
        }

        if (url.includes('privacidade')) {
          this.currentRoute = 'POLÍTICA DE PRIVACIDADE'
        }

        if (url.includes('termos')) {
          this.currentRoute = 'TERMOS E CONDIÇÕES'
        }
        if (url.includes('cookies')) {
          this.currentRoute = 'POLÍTICA DE COOKIES'
        }
      }
    })
   }

  ngOnInit(): void {
  }


}
