import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { noticias } from 'src/app/interfaces/noticias';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-atletismo',
  templateUrl: './atletismo.component.html',
  styleUrls: ['./atletismo.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class AtletismoComponent implements OnInit, OnDestroy {

  //noticias atletismo id = 18
  news: noticias[]

  sub: Subscription[] = []
  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getNoticias()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias() {
    this.sub.push(this.data.getNoticiasFiltradas(18).subscribe(res => {
      this.news = res
    }))
  }

}