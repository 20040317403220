import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { noticias } from 'src/app/interfaces/noticias';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-basquetebol',
  templateUrl: './basquetebol.component.html',
  styleUrls: ['./basquetebol.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class BasquetebolComponent implements OnInit {

  //noticias basquetenol id = 17
  news: noticias[] = []

  sub: Subscription[] = []

  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getNoticias()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias() {
    this.sub.push(this.data.getNoticiasFiltradas(17).subscribe(res => {
      this.news = res
    }))
  }

}
