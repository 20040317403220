import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { classificacao } from 'src/app/interfaces/classificacao';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-sub14-classificacao',
  templateUrl: './sub14-classificacao.component.html',
  styleUrls: ['./sub14-classificacao.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class Sub14ClassificacaoComponent implements OnInit, OnDestroy {

  classificacao: classificacao[] = []
  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getRankings()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getRankings() {
    this.sub.push(this.dataService.getRanking(14).subscribe(res => {
      this.classificacao = Array.isArray(res) ? res : [];
    }))
  }

}
