import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-modalidades-navbar',
  templateUrl: './modalidades-navbar.component.html',
  styleUrls: ['./modalidades-navbar.component.scss']
})
export class ModalidadesNavbarComponent implements OnInit {

  activeLink: string = "active-link"

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects
       // set changeColor property with the value of the current route
        if(currentRoute.includes('historia') || currentRoute.includes('palmares') || currentRoute.includes('presidente')) {
          this.activeLink = 'active-link-2'
        } else {
          this.activeLink = 'active-link'
        }
      }
    });
   }

  ngOnInit(): void {
    this.scrollBar()
  }

  scrollBar() {
    let el: number = document.getElementById('basq').offsetWidth


    if(this.router.url.includes('tenis')) {
      $('.hRow2').scrollLeft(0)
    }
    if(this.router.url.includes('basquetebol')) {
      $('.hRow2').scrollLeft(el)
    }
    if(this.router.url.includes('atletismo')) {
      $('.hRow2').scrollLeft(el*2)
    }
    if(this.router.url.includes('hipismo')) {
      $('.hRow2').scrollLeft(el*3)
    }
    if(this.router.url.includes('veteranos')) {
      $('.hRow2').scrollLeft(el*4)
    }

  }

}
