<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2">
            <div id="chegar-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline cursor" [routerLink]="['/bilheteira/lista-eventos']"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">
                <span class="tabs mx-auto">JOGOS</span>
            </div>
            <div *ngIf="(state.haveLogin | async)  == 'true'" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline cursor" [routerLink]="['/bilheteira/editar-perfil']"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">
                <span class="tabs mx-auto">PERFIL</span>
            </div>
            <div *ngIf="(state.isAssociate | async)  == 'true'" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left outline cursor" [routerLink]="['/bilheteira/quotas']"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">
                <span class="tabs mx-auto">QUOTAS</span>
            </div>
            <div id="contac-nav" class="col-8 col-md-3 col-lg-3 col-xl-2 centerTabs border-left border-right outline cursor" [routerLink]="['/faqs']"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">
                <span class="tabs mx-auto">FAQ</span>
            </div>
        </div>
    </div>
  </div>