<footer class="bg-footer">
  <div class="container-fluid bgFooter content-wrap">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 align-self-center">

          <div class="text-center">
            <img src="assets/img/logo@2x.png" alt="CD Tondela - Logo" width="61.25px" height="70px">
          </div>
          <p class="description">O Clube Desportivo de Tondela nasceu em 1933, com sede na cidade de Tondela, distrito de Viseu.</p>

          <div class="d-none d-lg-block">
            <div class="text-center links-footer"><a [routerLink]="['/contactos']" class="links-footer-text">Contactos</a></div>
            <div class="text-center links-footer"><a [routerLink]="['/termos']" class="links-footer-text">Termos e Condições</a></div>
            <div class="text-center links-footer"><a href="https://www.livroreclamacoes.pt/inicio/reclamacao" class="links-footer-text">Livro de Reclamações</a></div>
            <div class="text-center links-footer"><a [routerLink]="['/privacidade']" class="links-footer-text">Política de Privacidade</a></div>
          </div>
        </div>

        <div class="col-1 d-none d-lg-block ">
          <div class="vl"></div>
        </div>

       

        


        <div class="col-lg-7 d-flex flex-column justify-content-center">
          <div class="partners text-center text-md-left">
            <h5 class="sponsors">Sponsors SAD</h5>
            <a href="https://cabriz.pt/" target="_blank" title="QUINTA DE CABRIZ"><img src="assets/img/sponsors/cabriz2.png" width="168px" height="40px" alt="QUINTA DE CABRIZ" class="logo-sponsor mt-3 mt-lg-0"></a>
            <a href="https://cbiportugal.com/" target="_blank" title="CBI Industria De Vestuário"><img src="assets/img/sponsors/cbi.png" width="168px" height="40px" alt="CBI Industria De Vestuário" class="logo-sponsor mt-3 mt-lg-0"></a> 
            <a href="https://www.alsglobal.pt/" target="_blank" title="Als - Global"><img width="168px" height="40px" src="assets/img/sponsors/als.png" alt="ALS - GLOBAL" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="http://www.floponor.pt/" target="_blank" title="FLOPONOR"><img width="168px" height="40px" src="assets/img/sponsors/floponor.png" alt="FLOPONOR" class="logo-sponsor mt-3 mt-lg-0"></a>
            <a href="https://dcgrupo.pt/" target="_blank" title="DC.GRUPO - Onde o sonho se torna realidade"><img width="168px" height="40px" src="assets/img/sponsors/dcgrupo.png" alt="DC Grupo" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://quintadovaleminhoto.com/" target="_blank" title="QUINTA DO VALE MINHOTO"><img width="168px" height="40px" src="assets/img/sponsors/quinta.png" alt="QUINTA DO VALE MINHOTO" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://www.flordabeira.com/" target="_blank" title="FLOR DA BEIRA"><img width="168px" height="40px" src="assets/img/sponsors/florbeira.png" alt="FLOR DA BEIRA" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://2play.pt/" target="_blank" title="2PLAY"><img width="168px" height="40px" src="assets/img/sponsors/2play.png" alt="2PLAY" class="logo-sponsor mt-3 mt-lg-0"></a>
            <a href="https://www.aguasdocaramulo.pt/" target="_blank" title="Aguas do Caramulo"><img width="168px" height="40px" src="assets/img/sponsors/caramulo.png" alt="Aguas do Caramulo" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="http://www.sereparecer.com/" target="_blank" title="SER E PARECER"><img width="168px" height="40px" src="assets/img/sponsors/serparecer.png" alt="SER E PARECER" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://www.metronumbers.com/" target="_blank" title="METRO NUMBERS"><img width="168px" height="40px" src="assets/img/sponsors/metronumbers.png" alt="METRO NUMBERS" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://www.cdt-equipamentos.com/" target="_blank" title="CDT EQUIPAMENTOS"><img width="110px" height="40px" src="assets/img/sponsors/cdtequip.png" alt="CDT EQUIPAMENTOS" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://montebelohotels.com/pt/home" target="_blank" title="Montebelo - Hotels e Resorts"><img width="168px" height="40px" src="assets/img/sponsors/montebelo.png" alt="MONTEBELO - HOTELS E RESORTS" class="logo-sponsor mt-3 mt-lg-0"></a>
            <a href="https://www.cuf.pt/" target="_blank" title="CUF"><img width="168px" height="40px" src="assets/img/sponsors/cuf.png" alt="CUF" class="logo-sponsor mt-3 mt-lg-2"></a>
          </div>

          

          <div class="partners text-center text-md-left mt-3">
            <h5 class="sponsors">Sponsors Clube</h5>
            <a href="https://www.fhc.pt/" target="_blank" title="FHC Farmacêutica"><img src="assets/img/sponsors/fhc.png" width="168px" height="40px" alt="FHC Farmacêutica" class="logo-sponsor mt-3 mt-lg-0"></a>
            <a href="https://cbiportugal.com/" target="_blank" title="CBI Industria De Vestuário"><img src="assets/img/sponsors/cbi.png" width="168px" height="40px" alt="CBI Industria De Vestuário" class="logo-sponsor mt-3 mt-lg-0"></a> 
            <a href="https://www.alsglobal.pt/" target="_blank" title="Als - Global"><img width="168px" height="40px" src="assets/img/sponsors/als.png" alt="ALS - GLOBAL" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://www.aviagro.pt/" target="_blank" title="Aviagro - Equipamentos e Serviços"><img width="168px" height="40px" src="assets/img/sponsors/aviagro.png" alt="Aviagro - Equipamentos e Serviços" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="http://eptondela.net/" target="_blank" title="Escola Profissional de Tondela"><img width="168px" height="40px" src="assets/img/sponsors/ept.png" alt="Escola Profissional de Tondela" class="logo-sponsor mt-3 mt-lg-2"></a>
            <a href="https://empiminho.pt/" target="_blank" title="Empiminho - Comércio e Aluguer de Empilhadores"><img width="168px" height="40px" src="assets/img/sponsors/empiminho.png" alt="Empiminho - Comércio e Aluguer de Empilhadores" class="logo-sponsor mt-3 mt-lg-2"></a>
            <img width="168px" height="40px" src="assets/img/sponsors/intermarche.png" alt="Intermarche" class="logo-sponsor mt-3 mt-lg-2">
            <a href="https://www.edibestlda.com/" target="_blank" title="EDIBEST - Empresa de Construção e obras públicas"><img width="168px" height="40px" src="assets/img/sponsors/edibest.png" alt="EDIBEST - Empresa de Construção e obras públicas" class="logo-sponsor mt-3 mt-lg-2"></a>
          </div>

          

            
          <div class="d-block d-lg-none">
            <div class="text-center links-footer"><a [routerLink]="['/contactos']" class="links-footer-text">Contactos</a></div>
            <div class="text-center links-footer"><a [routerLink]="['/termos']" class="links-footer-text">Termos e Condições</a></div>
            <div class="text-center links-footer"><a href="https://www.livroreclamacoes.pt/inicio/reclamacao" class="links-footer-text">Livro de Reclamações</a></div>
            <div class="text-center links-footer"><a [routerLink]="['/privacidade']" class="links-footer-text">Política de Privacidade</a></div>
          </div>
          <div class="icons-div d-block d-lg-none">
            <a href="https://www.facebook.com/CDTondela" class="socialmedia-footer"><i class="fa fa-facebook-square mr-3"></i></a>
            <a href="https://www.youtube.com/cdtondela" class="socialmedia-footer"><i class="fa fa-youtube mr-3"></i></a>
            <a href="https://www.instagram.com/cdtondela" class="socialmedia-footer"><i class="fa fa-instagram mr-3"></i></a>
            <a href="https://twitter.com/CDTondela1933" class="socialmedia-footer"><i class="fa fa-twitter mr-3"></i></a>
            <a href="https://pt.linkedin.com/company/clube-desportivo-de-tondela" class="socialmedia-footer"><i class="fa fa-linkedin mr-3"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid creditos">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 text-secondary">
          <p class="creditsLabel">© {{year | date: 'yyyy'}} CLUBE DESPORTIVO DE TONDELA | POWERED BY <b><a href="https://www.2play.pt">2PLAY</a></b></p>
        </div>
        <div class="col-lg-4 text-secondary">
          <div class="icons-div d-none d-lg-block">
            <a href="https://www.facebook.com/CDTondela/" target="_blank" class="socialmedia-footer"><i class="fa fa-facebook-square mr-3"></i></a>
            <a href="https://www.youtube.com/user/cdtondela" target="_blank" class="socialmedia-footer"><i class="fa fa-youtube mr-3"></i></a>
            <a href="https://www.instagram.com/cdtondela/" target="_blank" class="socialmedia-footer"><i class="fa fa-instagram mr-3"></i></a>
            <a href="https://twitter.com/cdtondela1933" target="_blank" class="socialmedia-footer"><i class="fa fa-twitter mr-3"></i></a>
            <a href="https://pt.linkedin.com/company/clube-desportivo-de-tondela" class="socialmedia-footer"><i class="fa fa-linkedin mr-3"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

