<div id="quotas" class="container-fluid">
    <div class="container pt-5 pb-5 pb-xl-0">
        <div class="row d-flex justify-content-center">
            <div class="col-xl-6 offset-xl-6">
                <h4 class="tabelaPrecos pt-3 pt-lg-0">TABELA DE PREÇOS SÓCIO</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-4 col-xl-5 d-none d-xl-block">
                <img class="img-fluid" src="assets/img/adeptos2.png">
            </div>
            <div class="col-lg-6 col-xl-3 offset-xl-1 align-self-lg-center">
                <div class="card text-center shadow">
                    <div class="card-header bgHeader text-light">
                        JOVEM
                    </div>
                    <div class="card-body cb">
                        <h3 class="card-title valorQuota m-0">10<span class="euro">€</span></h3>
                        <p class="card-text quota">QUOTA ANUAL</p>
                        <p class="card-text textoCard">Sócios com idade compreendida entre os 1 e os 18 anos</p>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn--skew btn-default yellow-button" [routerLink]="['/socios/ser-socio']">
                            <div class="button arrow">Aderir</div>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-3 mt-4 mt-lg-0 align-self-lg-center">
                <div class="card text-center shadow">
                    <div class="card-header bgHeader text-light">
                        ADULTO
                    </div>
                    <div class="card-body cb">
                        <h3 class="card-title valorQuota m-0">60<span class="euro">€</span></h3>
                        <p class="card-text quota">QUOTA ANUAL</p>
                        <p class="card-text textoCard">Sócios com idade superior a 18 anos</p>

                    </div>
                    <div class="card-footer">
                        <button class="btn btn--skew btn-default yellow-button" [routerLink]="['/socios/ser-socio']">
                            <div class="button arrow">Aderir</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h4 class="tabelaPrecos darktext">TERMOS E CONDIÇÕES</h4>
        </div>
        <div class="col-12 pt-3 pt-lg-0">
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-header cursor" id="headingFour" data-toggle="collapse"
                        data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                        <h2 class="mb-0">
                            <div class="row hRow centerVert">
                                <div class="col-9 col-lg-10">
                                    <p class="instiTit m-0 ml-3 mb-0 ml-lg-5">Como se tornar Sócio?</p>
                                </div>
                                <div class="col-3 col-lg-2 text-center">
                                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFour"
                                        aria-expanded="true" aria-controls="collapseFour"><i
                                            class="fa fa-plus-circle addBtn mx-auto"></i></button>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div id="collapseFour" class="collapse show" aria-labelledby="headingFour">
                        <div class="card-body">
                            <p>Para te tornares sócio, necessitas somente de preencher o nosso formulário online ou dirigires-te à nossa loja no estádio e preencher uma ficha idêntica
                                com os teus dados e fotografia (que será devolvida; Poderá ser tipo passe, ou então a foto do cartão de cidadão ou uma que possuas no teu telemóvel).</p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header cursor" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="true" aria-controls="collapseTwo">
                        <h2 class="mb-0">
                            <div class="row hRow2 centerVert">
                                <div class="col-9 col-lg-10">
                                    <p class="instiTit m-0 ml-3 mb-0 ml-lg-5">Condições Sócio Jovem</p>
                                </div>
                                <div class="col-3 col-lg-2 text-center">
                                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseTwo"
                                        aria-expanded="true" aria-controls="collapseTwo"><i
                                            class="fa fa-plus-circle addBtn mx-auto"></i></button>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                        <div class="card-body">
                            <p>Apenas pessoas com idade igual ou inferior a 18 anos, a quota é anual, não sendo possível pagar ao mês, bimestral ou
                                trimestral. Tem um valor de 10,00€. Apresenta o mesmo período que um sócio adulto (a inscrição é feita,
                                por exemplo, em Março de 2020, vais ter quota paga até Fevereiro de 2021).</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header cursor" id="headingThree" data-toggle="collapse"
                        data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <h2 class="mb-0">
                            <div class="row hRow centerVert">
                                <div class="col-9 col-lg-10">
                                    <p class="instiTit m-0 ml-3 mb-0 ml-lg-5">Condições Sócio Adulto</p>
                                </div>
                                <div class="col-3 col-lg-2 text-center">
                                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseThree"
                                        aria-expanded="true" aria-controls="collapseThree"><i
                                            class="fa fa-plus-circle addBtn mx-auto"></i></button>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
                        <div class="card-body">
                            <p>Pessoas com idade superior a 18 anos. Aquando da inscrição, terás de proceder ao pagamento na totalidade da quota anual (60,00€);
                                depende do mês de inscrição, não tendo a ver com o ano civil nem com a época desportiva (a inscrição é
                                feita, por exemplo, em Março de 2020, vais ter quota paga até Fevereiro de 2021).</p>
                                <p> Após esse “ano”, o sócio é que escolhe como poderá pagar as restantes quotas (mensal, bimestral, trimestral ou novamente
                                anual). Dos 60,00€ anuais equivalem a 5,00€ por mês.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header cursor" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
                        aria-expanded="true" aria-controls="collapseFive">
                        <h2 class="mb-0">
                            <div class="row hRow2 centerVert">
                                <div class="col-9 col-lg-10">
                                    <p class="instiTit m-0 ml-3 mb-0 ml-lg-5">Vantagens para os Sócios</p>
                                </div>
                                <div class="col-3 col-lg-2 text-center">
                                    <button class="btn btn-link p-0" data-toggle="collapse" data-target="#collapseFive"
                                        aria-expanded="true" aria-controls="collapseFive"><i
                                            class="fa fa-plus-circle addBtn mx-auto"></i></button>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
                        <div class="card-body">
                            <ul>
                                <li>Preço dos bilhetes de jogos em casa mais baratos (varia de jogo para jogo);</li>
                                <li>Desconto de 5€ nos artigos da loja (física ou online) – exceto promoções;</li>
                                <li>Na eventualidade de haver diferenças nos preços das excursões aos jogos fora, sendo sócio permite-lhe pagar menos;</li>
                                <li>Pontualmente o clube permite ao sócio trazer um acompanhante gratuito para o jogo ou a
                                    possibilidade de comprar outro bilhete de sócio (atenção: esta campanha é sempre divulgada previamente,
                                    o normal o sócio só pode comprar 1 bilhete a preço de sócio – o seu bilhete);</li>
                            </ul>
                            <p>Poderás comprar bilhete durante a semana, do dia do jogo que pretendes ver, até ao dia e hora do mesmo,
                                somente na nossa loja.</p>
                            <p>Não efetuamos reservas, terás sempre de te dirigir à loja. Caso não tenhas possibilidade poderás pedir a
                                alguém que o compre por ti, deverás facultar-lhe o teu número de sócio para poder comprar o bilhete a
                                preço de sócio.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>