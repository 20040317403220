import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { noticias } from 'src/app/interfaces/noticias';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-esports-oneone',
  templateUrl: './esports-oneone.component.html',
  styleUrls: ['./esports-oneone.component.scss']
})
export class EsportsOneoneComponent implements OnInit, OnDestroy {

  //noticias 1v1 id = 15
  sub: Subscription[] = []
  news: noticias[]
  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.getNoticias()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias() {
    this.sub.push(this.data.getNoticiasFiltradas(15).subscribe(res => {
      this.news = res
    }))
  }


}
