<app-clube-header></app-clube-header>
<app-clube-navbar></app-clube-navbar>

<div @fade>
    <div class="container-fluid bg2">
        <div class="container pt-5 pb-2">
            <p class="tit">PALMARÉS</p>
        </div>
    </div>
    <div class="container">
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">2010 - 2020<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2018</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Nacional da 2ª Divisão de Juniores</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2015</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Estreia na 1ª Liga</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2015</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato da Segunda Liga (Campeão)</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2015</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Subida à 1ª Divisão Nacional de Juniores (3ºLugar)</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2014</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Juvenis</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2014</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Iniciados</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2013</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Juniores</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2012</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Nacional da 2ª Divisão – Zona Centro</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">2000 - 2010<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2009</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Nacional da 3ª Divisão</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2005</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 1ª Divisão</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2004</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Taça Sócios de Mérito (AF Viseu)</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">2003</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Taça Sócios de Mérito (AF Viseu)</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1990 - 2000<span></span></p>
            <div class="row py-3 bgt">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1994</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Juvenis<br>Vice-Campeão Nacional da 3ª Divisão Nacional – Série C (Subida à 2ª Divisão Nacional)
                    </p>
                </div>
            </div>
            <div class="row py-3 bgt">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1991</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão (Zona Sul)</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1980 - 1990<span></span></p>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1986</p>
                </div>  
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 1ª Divisão</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1983</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Juniores</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1970 - 1980<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1976</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Vice-campeão Distrital da AF Viseu – Subida à 3ª Nacional</p>
                </div>
            </div>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1975</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Juniores</p>
                </div>
            </div>

            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1973</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1960 - 1970<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1964</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1950 - 1960<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1952</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1940 - 1950<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1950</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1942</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
            <div class="row py-3 bgt">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1941</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da 2ª Divisão</p>
                </div>
            </div>
        </div>
        <!-- NOVA DECADA -->
        <div class="border-left p-3 position-relative">
            <div class="square position-absolute"></div>
            <p class="m-0 year">1930 - 1940<span></span></p>
            <div class="row bgt py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1940</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital da Beira Alta</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1935</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Campeonato Distrital de Promoção (2ª Divisão)</p>
                </div>
            </div>
            <div class="row py-3 bgt">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1935</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Vencedor do Play-off de acesso ao Campeonato Distrital de Honra (1ª subida)</p>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-1 d-flex align-items-center">
                    <p class="m-0 title-table">1933</p>
                </div>
                <div class="col-11 d-flex align-items-center">
                    <p class="m-0 content-table">Vencedor do Play-off de acesso ao Campeonato Distrital de Honra (1ª subida)</p>
                </div>
            </div>
        </div>
    </div>
</div>
