<app-info-banner></app-info-banner>
<app-info-navbar></app-info-navbar>
<div @fade class="container-fluid bg1">
    <div class="container py-5 px-0 px-md-5 bg2">
        <div class="row align-items-center">
            <div class="col-12">
                <h2 class="">COMO CHEGAR AO ESTÁDIO?</h2>
                <p class="desc mb-4">Existem diversas formas para vir ao Estádio João Cardoso. Conhece as várias rotas para o nosso estádio.
                </p>

                <ul style="list-style: circle;" class="mb-4">
                    <li>
                        <h4>Lisboa » Tondela</h4>
                        <p>
                            De Lisboa a Tondela são 268 kms, tomando a A1 e posteriormente o IP3 com saída em Tondela;
                        </p>
                    </li>

                    <li>
                        <h4>Porto » Tondela</h4>
                        <p>
                            Do Porto a Tondela são 129 kms, tomando a A1 e saída na A25 e posteriormente em
                            Vouzela/Tondela.
                    </li>

                    <li>
                        <h4>Aveiro » Tondela</h4>
                        <p>
                            De Aveiro a Tondela são 85 kms, tomando a A25 e posteriormente saindo em Vouzela/Tondela.
                        </p>
                    </li>

                    <li>
                        <h4>Viseu » Tondela</h4>
                        <p>
                            De Viseu a Tondela são 27 kms, tomando o IP3 e posteriormente saindo em Tondela. </p>
                    </li>

                    <li>
                        <h4>Coimbra » Tondela</h4>
                        <p>
                            De Coimbra a Tondela são 67 kms, tomando o IP3 e posteriormente saindo em Tondela.
                        </p>
                    </li>

                    <li>
                        <h4>Guarda » Tondela</h4>
                        <p>
                            De Guarda a Tondela são 100 kms, tomando a A25 e posteriormente saindo em Vouzela/Tondela;
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-lg-4">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.3419367723227!2d-8.085187884463753!3d40.51193265776764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23232cf129172f%3A0xaaa02166973d016c!2sEst%C3%A1dio%20Jo%C3%A3o%20Cardoso!5e0!3m2!1spt-PT!2spt!4v1600358491772!5m2!1spt-PT!2spt"
                    width="100%" height="300" frameborder="0" style="border:0; display: block;"
                    allowfullscreen=""></iframe>

                <p class="text-lg-right mt-3"><b>NOTA:</b> Mais informações e direcções/mapas detalhados em: <a
                        href="http://www.viamichelin.com">http://www.viamichelin.com</a></p>
            </div>
        </div>
    </div>
</div>