<app-info-banner></app-info-banner>
<app-info-navbar></app-info-navbar>
<div @fade class="container-fluid bg1">
    <div class="container py-5 px-0 px-md-5 bg2">
        <div class="row d-flex align-items-center">
            <div class="col-12 col-md-9">
                <h3 class="mb-0">CLUBE DESPORTIVO DE TONDELA</h3>
                <p class="mb-0">(Fundado em 06-06-1933 - Instituição de Utilidade Pública)</p>
            </div>
            <div class="col-3 text-right d-none d-md-block">
                <img class="cdtImg" src="assets/icons/logo.svg" alt="">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>MORADA <span></span></h3>
                <p class="mb-0 mt-lg-0 mt-3 text-left text-md-right">Complexo Desportivo Estádio João Cardoso</p>
                <p class="mb-0 text-left text-md-right">Rua Dr. Eurico José Gouveia nº365</p>
                <p class="mb-0 text-left text-md-right">3460-582 - TONDELA</p>   
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <h3>COORDENADAS GPS <span></span></h3>
                <a href="https://www.google.com/maps?ll=40.511929,-8.082999&z=16&t=m&hl=pt-PT&gl=PT&mapclient=embed&cid=12294863707229192556"><p class="mb-0 mt-3 mt-lg-0 text-left text-md-right">40°30'43.4"N 8°04'58.4"W</p></a>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.3419367723227!2d-8.085187884463753!3d40.51193265776764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23232cf129172f%3A0xaaa02166973d016c!2sEst%C3%A1dio%20Jo%C3%A3o%20Cardoso!5e0!3m2!1spt-PT!2spt!4v1600358491772!5m2!1spt-PT!2spt" width="100%" height="300" frameborder="0" style="border:0; display: block;" allowfullscreen=""></iframe>
            </div>
        </div>
        <div class="row mt-5 mt-lg-0">
            <div class="col-12">
                <h3>TELEFONE/ FAX <span></span></h3>
                <div class="d-flex flex-column align-items-lg-end">
                    <a href="tel:+351232821447">(+351) 232 821 447*</a>
                    <span>*(Chamada para a rede fixa nacional)</span>
                </div>
            </div>
        </div>
       <!--  <div class="row mt-5">
            <div class="col-12">
                <h3>NIF <span></span></h3>
                <p class="mb-0 text-left text-md-right">505 212 722</p>
            </div>
        </div> -->
        <div class="row mt-5">
            <div class="col-12 d-none d-md-block">
                <h3>EMAIL <span></span></h3>
                <p class="mt-2 text-right">Geral - <a href="mailto:geral@cdtondela.pt" target="_blank">geral@cdtondela.pt</a></p>
                <p class="text-right">Loja CDT - <a href="mailto:loja.cdt@cdtondela.pt" target="_blank">loja.cdt@cdtondela.pt</a></p>
                <p class="text-right">Relações Públicas - <a href="mailto:rpublicas@cdtondela.pt" target="_blank">rpublicas@cdtondela.pt</a></p>
                <p class="text-right">Comunicação Social/ Acreditações - <a href="mailto:imprensa@cdtondela.pt" target="_blank">imprensa@cdtondela.pt</a></p>
                <p class="text-right">Formação - <a href="mailto:formacao@cdtondela.pt" target="_blank">formacao@cdtondela.pt</a></p>


            </div>
            <div class="col-12 d-block d-md-none">
                <h3>EMAIL <span></span></h3>
                <p class="mb-0 mb-lg-1 mt-3">Geral:</p>
                <p class="mb-0 mb-lg-1"><a href="mailto:geral@cdtondela.pt" target="_blank">geral@cdtondela.pt</a></p>
                <p class="mb-0 mb-lg-1 mt-3">Loja CDT:</p>
                <p class="mb-0 mb-lg-1"><a href="mailto:loja.cdt@cdtondela.pt" target="_blank">loja.cdt@cdtondela.pt</a></p>
                <p class="mb-0 mb-lg-1 mt-3">Relações Públicas:</p>
                <p class="mb-0 mb-lg-1"><a href="mailto:rpublicas@cdtondela.pt" target="_blank">rpublicas@cdtondela.pt</a></p>
                <p class="mb-0 mb-lg-1 mt-3">Comunicação Social/Acreditações:</p>
                <p class="mb-0 mb-lg-1"><a href="mailto:imprensa@cdtondela.pt" target="_blank">imprensa@cdtondela.pt</a></p>
                <p class="mb-0 mb-lg-1 mt-3">Formação:</p>
                <p class="mb-0 mb-lg-1"><a href="mailto:formacao@cdtondela.pt" target="_blank">formacao@cdtondela.pt</a></p>
            </div>
        </div>
    </div>
</div>