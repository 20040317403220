<div @fade class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst">PROCLUBS</p>
        <p class="descEst text-justify">
            Fundados em 2017, fomos uma das primeiras equipas de Playstation reconhecidas no panorama de FIFA PROCLUBS em Portugal. Jogamos competições de variadas entidades organizadoras de ligas ou torneios e a época 19/20 foi a chave para abraçarmos novas plataformas como o PC e a XBOX. 2020 trouxe-nos o desejado caneco que já tardava ao modo que melhor retrata o futebol.

            <br>O Proclubs, para quem não sabe, é a simulação perfeita de um jogo de futebol. Cada atleta controla apenas e só o seu jogador e, por isso, os valores do futebol estão presentes nele ao mais alto nível.    
        </p>
    </div>
</div>


<div class="container-fluid bg_3 mb-lg-5">
    <div class="container fix-mobile-padding">
        <div class="row">
            <div class="col-12 col-lg-9">
                    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
                        <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img/esports/slider/1.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item ">
                            <img class="d-block w-100" src="assets/img/esports/slider/2.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/esports/slider/3.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item ">
                            <img class="d-block w-100" src="assets/img/esports/slider/4.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/esports/slider/5.jpg" alt="Ténis">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/esports/slider/6.jpg" alt="Ténis">
                        </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                        </a>
                </div>
                  
            </div>
            <div class="col-12 col-lg-3 mb-lg-3 centerNumbers">
                <p class="numbers">2017</p>
                <p class="desc mb-2 mb-lg-5">ANO DE FUNDAÇÃO</p>

                <a class="numbers" href="https://twitter.com/cdtesports?lang=pt" target="_blank">CD Tondela Esports</a>
                <p class="desc mb-2 mb-lg-5">TWITTER</p>
                
                <a class="numbers" href="https://www.instagram.com/cdtondelaesports/?hl=pt" target="_blank">CD Tondela Esports</a>
                <p class="desc">INSTAGRAM</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg1">

    <div class="container py-5">
        <h1 class="tit">NOTÍCIAS</h1>
    
        <div class="container bg-news">
        
            <div class="row py-3 border-news touch cursor" *ngFor="let new of news" [routerLink]="['/noticia/', new.noticia]">
                <div class="col-lg-5">
                    <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                   <img src="assets/img/noticias/{{new.imagem_listagem}}" alt="" class="img-news">
                </div>
                <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4 pt-4">
                    <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                        <p class="skew-text m-0 text-uppercase">{{new.escalao}} </p>
                    </div>
                    
                    <h2 class="news-title">{{new.titulo}}</h2>
                    <p class="news-date">{{new.data}}</p>
                    <p class="news-subtitle">{{new.subtitulo}}
                    </p>
                    <div class="button arrow">Ler Notícia</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<div class="container-fluid pt-4 pb-5 pt-lg-5 pb-lg-5 bg3">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="titleEst mb-0">ÚLTIMAS NOTÍCIAS</h2>
            </div>
        </div>
    </div>
    <div class="container">
    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    <div class="row py-3 border-news touch">
        <div class="col-lg-5">
            <div class="d-block d-lg-none bg-skew d-flex align-items-center justify-content-center fix-mobile-tag">
                <p class="skew-text m-0">eSports</p>
            </div>
            <a [routerLink]="['/noticia']"><img src="assets/img/noticias/new1.jpg" alt="" class="img-news"></a>
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center pr-lg-4">
            <div class="bg-skew d-flex align-items-center justify-content-center disable-mobile">
                <p class="skew-text m-0">eSports </p>
            </div>
            <a [routerLink]="['/noticia']">
                <h2 class="news-title">Finais de Inverno da eLiga Portugal arrancam hoje</h2>
            </a>
            <p class="news-date">22 Dezembro, 2020</p>
            <p class="news-subtitle">Apresentada oficialmente no dia 5 de Novembro, a primeira fase do novo circuito de Esports da eLiga Portugal aproxima-se da sua conclusão com oito equipas a batalharem pelo título de campeão de Inverno.
            </p>
            <a [routerLink]="['/noticia']" class="button arrow">Ler Notícia</a>
        </div>
    </div>

    

      
      
    </div>
</div> -->