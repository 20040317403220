
<div class="container-fluid bg filterImg align-items-center fix-padding">
    <div class="container">
        <div class="row filterIndex">
            <div class="col-12 col-md-10">
                <p class="equipaTitulo d-md-flex">CD TONDELA</p>
                <p class="equipaDescricao">Notícias</p>
            </div>
        </div>
    </div>
</div>
<div @fade class="container-fluid bg1">
<div class="container py-5 px-0 px-md-5 bg2">
    <div class="card-columns">
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4922337151169684&width=500&show_text=true&appId=389806291138827&height=509" width="500" height="509" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card p-3">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4923039351099464&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4921102431293156&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4922337151169684&width=500&show_text=true&appId=389806291138827&height=509" width="500" height="509" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card p-3">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4923039351099464&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4921102431293156&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4922337151169684&width=500&show_text=true&appId=389806291138827&height=509" width="500" height="509" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card p-3">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4923039351099464&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="card">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCDTondela%2Fposts%2F4921102431293156&width=500&show_text=true&appId=389806291138827&height=670" width="500" height="670" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
    </div>
  </div>
</div>