<app-equipa-principal-banner></app-equipa-principal-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading" class="container py-lg-5 mt-4 mt-lg-0">




   <div class="row d-block d-md-none">
        <div class="col-12 p-0 mb-3 topTable title">RESULTADOS</div>
    </div>
    <div *ngFor="let result of resultados" class="mb-md-2">
    <div class="container">
        <div class="row d-none d-md-block">
            <div class="col-12 month mb-2">{{result.label_mes}} <span class="year">{{result.label_ano}}</span></div>
        </div>


        <div class="row hRow mb-3 mb-lg-0" *ngFor="let jogos of result.jogos">
            <div
                class="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start order-1 order-lg-1 color-info pb-2 pb-lg-0 bt-div">
                <p class="dataJogo">{{jogos.data}} | <span class="font-weight-bold">{{jogos.horario}}</span></p>
            </div>
            <div class="col-12 col-lg-7 order-3 order-lg-2">

                <div class="row padding-fix fix-border">
                    <div class="col-4 col-md-5 fix-padding-mobile ">
                        <div class="row">
                            <div class="col-lg-8 d-flex justify-content-center order-2 order-lg-1 pr-lg-0">
                                <p class="alignCont equipaLabel" [ngClass]="jogos.clube_casa_id == 100 ? 'cdt-bold' : ''">
                                    {{jogos.clube_casa | uppercase}}</p>
                            </div>

                            <div class="col-lg-4 p-0 order-1 order-lg-2 text-center">
                                <img src="assets/img/clubes/{{jogos.logo_casa == null ? 'logo.webp' : jogos.logo_casa}}"
                                    class="img-fluid-logo">
                            </div>
                        </div>
                    </div>

                    <div class="col-4 col-md-2 text-center align-self-center">
                        <div>
                            <p class="mb-0 order-2"><span
                                    [ngClass]="jogos.clube_casa_id == 100 ? 'cdtNum' : 'eqNum'">{{jogos.resultado | slice:0:1}}</span><span
                                    class="twoPoints mx-1">:</span><span
                                    [ngClass]="jogos.clube_fora_id == 100 ? 'cdtNum' : 'eqNum'">{{jogos.resultado | slice:2:3}}</span>
                            </p>
                        </div>
                        <a *ngIf="jogos.ficha" class="ficha-button text-center"
                            [routerLink]="['/futebol/equipa-principal/resultados/detalhes', jogos.id]">DETALHES</a>

                  </div>

                    <div class="col-4 col-md-5 fix-padding-mobile ">
                        <div class="row">
                            <div class="col-lg-4 p-0 text-center">
                                <img src="assets/img/clubes/{{jogos.logo_fora == null ? 'logo.webp' : jogos.logo_fora}}"
                                    class="img-fluid-logo">
                            </div>

                            <div class="col-lg-8 d-flex justify-content-center pl-lg-0">
                                <p class="alignCont equipaLabel" [ngClass]="jogos.clube_fora_id == 100 ? 'cdt-bold' : ''">
                                    {{jogos.clube_fora | uppercase}}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div
                class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end flex-row flex-lg-column color-info order-2 order-lg-3 pb-2 pb-lg-0">
                <p class="ligaNos" [ngClass]="jogos.competicao == 'Taça de Portugal' ? 'font-titlegrande-fix' : ''">{{jogos.competicao | uppercase}}</p>
                <p class="jornada"><span class="sep_jogo">|</span>{{jogos.jornada}}</p>
            </div>
        </div>
    </div>
    </div>

</div>
