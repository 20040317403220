<app-info-banner></app-info-banner>
<div class="container-fluid bg1">
    <div class="container bg2 py-5 px-0 px-lg-5">
        <div class="row mt-2">
            <div class="col-12">
                <p class="text-justify">
                    O Clube Desportivo de Tondela utiliza cookies para ajudar na navegação e operações do website
                    https://www.cdtondela.pt/ (doravante o “website”). Alguns dos cookies que utilizamos são
                    necessários ao desempenho do website e execução dos serviços associados, sendo que a utilização de
                    outros cookies estará sempre dependente do seu consentimento prévio, o qual poderá ser retirado a
                    todo o tempo através das ferramentas específicas do seu navegador de internet (doravante designado
                    por “browser”) (por favor conferir secção 5. Como posso desativar os cookies?).

                    De seguida poderá encontrar toda a informação relevante aplicável aos cookies utilizados no website:
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <h3> o que são cookies?<span></span></h3>
                <p class="text-justify">
                    Os cookies são pequenos ficheiros de texto colocados no equipamento do utilizador ou no seu
                    dispositivo móvel (doravante designados em conjunto como “equipamentos”), através de um browser. A
                    colocação de cookies permite ao website reconhecer o seu dispositivo numa próxima visita. Quando
                    utilizamos o termos cookies nesta política fazemos referência a todos os ficheiros que recolhem
                    informações desta forma.
                    No website utilizamos diversos tipos de cookies. Para obter informação adicional sobre os tipos de
                    cookies, as suas finalidades e respetivos prazos de conservação por favor consulte a seção 4. Para
                    que servem os cookies?.
                    Dependendo do tipo cookies, a recusa da instalação dos mesmos poderá resultar na impossibilidade de
                    ter acesso a algumas das suas áreas ou de receber informação personalizada.
                    A qualquer momento pode, através do seu browser decidir ser notificado sobre a receção de cookies,
                    bem como bloquear a respetiva instalação nos seus equipamentos.
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <h3>Quem é a entidade responsável pelos Cookies?<span></span></h3>
                <p class="text-justify">
                    A entidade responsável pelos cookies poderá variar consoante o destino de onde provêm os cookies.
                    Assim, no website são utilizados os seguintes tipos de cookies quanto à entidade responsável:
                </p>
                <ul>
                    <li class="text-justify"><b>Cookies próprios:</b> Estes cookies são enviados para os equipamentos do
                        utilizador a partir do responsável pelo website, ou seja o Clube Desportivo de Tondela.</li>
                    <li class="text-justify"><b>Cookies de terceiros:</b> São cookies enviados para os equipamentos do
                        utilizador a partir de um computador ou domínio que não é gerido pelo Clube Desportivo de
                        Tondela, mas por outra entidade que trata os dados obtidos através de cookies.</li>
                </ul>
                <p class="text-justify">
                    Poderá conferir qual a entidade responsável por cada cookie na secção <i>Para que servem os
                        cookies?</i>.
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3>Durante quanto tempo os cookies são conservados?<span></span></h3>
                <p class="text-justify">
                    Relativamente ao tempo durante o qual os cookies permanecem conservados no seu equipamento, será
                    necessário considerar os seguintes tipos de cookies:
                </p>
                <ul>
                    <li class="text-justify"><b>Cookies de sessão:</b> São recolhidos e armazenados enquanto o
                        utilizador acede ao website. São normalmente utilizados para armazenar informações para fins de
                        prestação do serviço solicitado pelo utilizador numa única ocasião (por exemplo, uma lista de
                        produtos adquiridos) e desaparecem no final da sessão.</li>
                    <li class="text-justify"><b>Cookies persistentes:</b> São aqueles em que os dados permanecem
                        armazenados no equipamento do utilizador e podem ser acedidos e tratados durante um período
                        definido pelas entidades responsáveis pelo cookie, que pode variar entre alguns minutos e vários
                        anos.</li>
                </ul>
                <p class="text-justify">
                    Poderá conferir qual a entidade responsável por cada cookie na secção <i>Para que servem os
                        cookies?</i>.
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3>para que servem os cookies?<span></span></h3>
                <p class="text-justify">
                    Os cookies são utilizados para ajudar a determinar a utilidade, interesse e o número de utilizações
                    dos websites, permitindo uma navegação mais rápida e eficiente e eliminando a necessidade de
                    introduzir repetidamente as mesmas informações.
                    Em seguida poderá encontrar informação relativa aos tipos de cookies que são utilizados no website,
                    bem como os cookies em específico que são utilizados, de acordo com cada tipo.

                </p>
                <ul>
                    <li class="text-justify"><b>Cookies necessários:</b> São cookies essenciais para assegurar a
                        navegação e utilização do website. As informações recolhidas estão relacionadas com a gestão
                        normal do website.</li>
                </ul>
                <p class="text-justify">Em suma, estes cookies permitem-nos assegurar que prestamos o serviço que nos é
                    solicitado, sendo que sem os mesmos tal não será possível.</p>

                <div class="table-responsive">


                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <td>Cookie</td>
                                <td>Finalidade</td>
                                <td>Tempo de Conservação</td>
                                <td>Entidade Gestora do cookie</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ASPSESSIONID CADTBQCB</td>
                                <td>-</td>
                                <td>Cookie de sessão eliminado após o Utilizador encerrar o website</td>
                                <td>Cookie próprio</td>
                            </tr>
                            <tr>
                                <td>dadaproaffinity</td>
                                <td>Cookie técnico do servidor IIS host do site </td>
                                <td>Cookie de sessão eliminado após o Utilizador encerrar o website</td>
                                <td>Cookie próprio</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ul>
                    <li class="text-justify"><b>Cookies preferência e personalização:</b> Estes cookies permitem
                        memorizar informações para que o utilizador possa aceder ao website com determinadas
                        características que podem diferenciar a sua experiência de outros utilizadores, como por
                        exemplo: o idioma; a aparência ou o conteúdo do website; o nome de utilizador e a palavra passe.
                    </li>
                </ul>
                <div class="table-responsive">


                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <td>Cookie</td>
                                <td>Finalidade</td>
                                <td>Tempo de Conservação</td>
                                <td>Entidade Gestora do cookie</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>YSC</td>
                                <td>Esses cookies são definidos pelo Youtube e são usados para rastrear as visualizações
                                    dos
                                    vídeos incorporados.</td>
                                <td>Cookie de sessão eliminado após o Utilizador encerrar o website</td>
                                <td>YouTube</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ul>
                    <li class="text-justify"><b>Cookies analíticos:</b> Este cookies permitem-nos acompanhar e analisar
                        o comportamento dos utilizadores do website, incluindo a quantificação do impacto dos anúncios
                        publicitários. A informação recolhida através deste tipo de cookies é utilizada para medir a
                        atividade do website, com o objetivo de efetuar melhorias com base na análise dos dados de
                        utilização efetuada pelos utilizadores do mesmo.</li>
                </ul>
                <div class="table-responsive">


                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <td>Cookie</td>
                                <td>Finalidade</td>
                                <td>Tempo de Conservação</td>
                                <td>Entidade Gestora do cookie</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>GPS</td>
                                <td>Este cookie é definido pelo Youtube e regista um ID único para rastrear usuários com
                                    base na sua localização geográfica</td>
                                <td>30 Minutos</td>
                                <td>YouTube</td>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>Este cookie é instalado pelo Google Analytics. O cookie é usado para calcular o
                                    visitante, a sessão, os dados da campanha e controlar o uso do site para o relatório
                                    de
                                    análise do site. Os cookies armazenam informações anonimamente e atribuem um número
                                    gerado aleatoriamente para identificar visitantes únicos.</td>
                                <td>2 anos</td>
                                <td>Google</td>
                            </tr>
                            <tr>
                                <td>_gid</td>
                                <td>Este cookie é instalado pelo Google Analytics. O cookie é usado para armazenar
                                    informações de como os visitantes usam um site e ajuda a criar um relatório
                                    analítico de
                                    como o website se comporta. Os dados recolhidos, incluindo o número de visitantes, a
                                    fonte de onde vieram e as páginas visitadas de forma anônima</td>
                                <td>1 dia</td>
                                <td>Google</td>
                            </tr>
                            <tr>
                                <td>_gat_gtag_UA_138708838_1</td>
                                <td>O Google usa esse cookie para distinguir os usuários.</td>
                                <td>1 Minuto</td>
                                <td>Google</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ul>
                    <li class="text-justify"><b>Cookies publicidade (comportamental):</b> Estes cookies armazenam
                        informação sobre o comportamento dos utilizadores através da sua navegação no website, o que
                        permite o desenvolvimento de um perfil específico para dirigir publicidade em função do perfil.
                    </li>
                </ul>
                <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <td>Cookie</td>
                                <td>Finalidade</td>
                                <td>Tempo de Conservação</td>
                                <td>Entidade Gestora do cookie</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>VISITOR_INFO1_LIVE</td>
                                <td>Este cookie é definido pelo Youtube. Usado para rastrear as informações dos vídeos
                                    incorporados do YouTube num website.</td>
                                <td>5 meses</td>
                                <td>YouTube</td>
                            </tr>
                            <tr>
                                <td>IDE</td>
                                <td>Usado pelo Google DoubleClick e armazena informações sobre como o usuário usa o
                                    website e qualquer outro anúncio antes de visitar o site. Isso é usado para
                                    apresentar aos usuários anúncios que são relevantes para eles de acordo com o perfil
                                    do usuário.</td>
                                <td>1 ano</td>
                                <td>Google</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3> como posso desativar os cookies?<span></span></h3>
                <p class="text-justify"><b>Mesmo que forneça o seu consentimento para a instalação dos cookies poderá, a todo o tempo, desativar parte ou a totalidade dos mesmos. </b>
                </p>
                <p class="text-justify">
                    Todos os browsers permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da seleção das definições apropriadas no respetivo navegador. Pode configurar os cookies no menu "opções" ou "preferências" do seu browser. Note-se, no entanto, que, ao desativar os cookies, pode impedir que alguns serviços do website funcionem corretamente, afetando, parcial ou totalmente, a navegação no website.  
                </p>
                <p class="text-justify">
                    Para obter mais informações sobre cookies, incluindo o que são, como geri-los e como excluí-los, visite www.allaboutcookies.org, que também inclui informações sobre como gerir as suas configurações para os vários fornecedores de navegadores. 
                </p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h3>Como posso contactar o Clube Desportivo de Tondela?<span></span></h3>
                <p class="text-justify">
                    Caso tenha alguma dúvida ou quiser obter mais informações relativamente à utilização dos cookies, por favor utilize um dos seguintes contactos:
                </p>
                <p class="text-right m-0">
                    Endereço eletrónico: geral@cdtondela.pt
                </p>
                <p class="text-right">
                    Contacto telefónico: (+351) 232 821 447*<br>
                    *(Chamada para a rede fixa nacional)
                </p>
            </div>
        </div>
    </div>
</div>