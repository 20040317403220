<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2" id="barra">
            <!--<div id="hist" class="col-8 col-md-3 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/esports/proclubs']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto text-uppercase" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">ProClubs</span>
            </div>-->
            <div id="palm" class="col-8 col-md-3 col-lg-2 border-left border-right outline"
                [routerLink]="['/modalidades/esports/1v1']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto text-uppercase" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">1v1</span>
            </div>
           <div id="presi" class="col-8 col-md-3 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/esports/staff']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto text-uppercase"
                    [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">Staff</span>
            </div>
            <!--<div id="estad" class="col-8 col-md-3 col-lg-2 border-left border-right outline"
                [routerLink]="['/modalidades/esports/stream']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto text-uppercase" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">Stream</span>
            </div> 

            <div class="col-8 col-md-3 col-lg-2 border-left border-right outline"
                [routerLink]="['/modalidades/esports/parcerias']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto text-uppercase" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">Parcerias</span>
            </div> -->
        </div>
    </div>
</div>