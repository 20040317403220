import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { jogadorFormacao } from 'src/app/interfaces/jogadores';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-sub19',
  templateUrl: './sub19.component.html',
  styleUrls: ['./sub19.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class Sub19Component implements OnInit, OnDestroy {

  guardaRedes: jogadorFormacao[]
  medios: jogadorFormacao[]
  avancados: jogadorFormacao[]
  defesas: jogadorFormacao[]
  staff: jogadorFormacao[]

  sub: Subscription[] = []

  constructor(
    private data: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    this.sub.push(this.data.getFormacao(19).subscribe(res => {

      this.guardaRedes = res.filter(res => res.posicao == "Guarda-Redes")
      this.defesas = res.filter(res => res.posicao == "Defesa")
      this.medios = res.filter(res => res.posicao == "Médio")
      this.avancados = res.filter(res => res.posicao == "Avançado")
      this.staff = res.filter(res => res.staff == true)
    }))
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

}
