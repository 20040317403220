import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-futebol-navbar',
  templateUrl: './futebol-navbar.component.html',
  styleUrls: ['./futebol-navbar.component.scss']
})
export class FutebolNavbarComponent implements OnInit {

  equipa: string = ""

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects

        if(currentRoute.includes('futebol')) {
          this.equipa = "/futebol/equipa-principal/"
        } else if (currentRoute.includes('formacao/sub-19')) {
          this.equipa = '/formacao/sub-19/'
        }  else if (currentRoute.includes('formacao/sub-17')) {
          this.equipa = '/formacao/sub-17/'
        }  else if (currentRoute.includes('formacao/sub-15')) {
          this.equipa = '/formacao/sub-15/'
        }  else if (currentRoute.includes('formacao/pestinhas')) {
          this.equipa = '/formacao/pestinhas/'
        }  else if (currentRoute.includes('formacao/sub-13')) {
          this.equipa = '/formacao/sub-13/'
        }  else if (currentRoute.includes('formacao/sub-11')) {
          this.equipa = '/formacao/sub-11/'
        }
      }
    })
  }

  ngOnInit(): void {
    window.scroll(0,0);

   this.scrollBar()
  }

  scrollBar() {
    let el: number = document.getElementById('plantel').offsetWidth
    if(this.router.url.includes('plantel')) {
      $('.hRow2').scrollLeft(0)
    }
    if(this.router.url.includes('resultados')) {
      $('.hRow2').scrollLeft(el)
    }
    if(this.router.url.includes('calendario')) {
      $('.hRow2').scrollLeft(el*2)
    }
    if(this.router.url.includes('classificacao')) {
      $('.hRow2').scrollLeft(el*3)
    }
    if(this.router.url.includes('staff') /* && this.router.url.includes('equipa-principal') */) {
      $('.hRow2').scrollLeft(el*4)
    }
    if(this.router.url.includes('noticias')) {
      $('.hRow2').scrollLeft(el*5)
    }


  }

}
