import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent implements OnInit {

  currentRoute: string = ''

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        const url = e.urlAfterRedirects

        if (url.includes('institucional')) {
          this.currentRoute = 'INSTITUCIONAL'
        }

        if (url.includes('contactos')) {
          this.currentRoute = 'CONTACTOS'
        }

        if (url.includes('como-chegar')) {
          this.currentRoute = 'COMO CHEGAR'
        }

        if (url.includes('privacidade')) {
          this.currentRoute = 'POLÍTICA DE PRIVACIDADE'
        }

        if (url.includes('termos')) {
          this.currentRoute = 'TERMOS E CONDIÇÕES'
        }
        if (url.includes('cookies')) {
          this.currentRoute = 'POLÍTICA DE COOKIES'
        }
      }
    })
   }

  ngOnInit(): void {
  }

}
