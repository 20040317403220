import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'

@Component({
  selector: 'app-info-navbar',
  templateUrl: './info-navbar.component.html',
  styleUrls: ['./info-navbar.component.scss']
})
export class InfoNavbarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.scrollBar()
  }

  scrollBar() {
    let el = document.getElementById('chegar-nav').offsetWidth

    if(this.router.url.includes('como-chegar')) {
      $('.hRow2').scrollLeft(0)
    }

    if(this.router.url.includes('contactos')) {
      $('.hRow2').scrollLeft(el)
    }
  }

}
