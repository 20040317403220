import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { calendario } from 'src/app/interfaces/calendario';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-sub17-calendario',
  templateUrl: './sub17-calendario.component.html',
  styleUrls: ['./sub17-calendario.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class Sub17CalendarioComponent implements OnInit, OnDestroy {

  calendario: calendario[] = []
  sub: Subscription[] = []

  constructor(
    private dataService: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);

    this.getCalendario()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getCalendario() {
    this.sub.push(this.dataService.getCalendario(17).subscribe(res => {
      this.calendario = Array.isArray(res) ? res/* .slice(0, 2) // devolve os dois primeiros meses */ : [];
    }))
  }


}
