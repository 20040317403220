import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
    /* setTimeout(function(){
      window.location.href = ''
    },3000) */
  }
}
