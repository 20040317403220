import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-clube-navbar',
  templateUrl: './clube-navbar.component.html',
  styleUrls: ['./clube-navbar.component.scss']
})
export class ClubeNavbarComponent implements OnInit {

  activeLink: string = "active-link"

  constructor(
    private router: Router
  ) { 
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects
       // set changeColor property with the value of the current route
        if(currentRoute.includes('historia') || currentRoute.includes('palmares') || currentRoute.includes('presidente')) {
          this.activeLink = 'active-link-2'
        } else {
          this.activeLink = 'active-link'
        }
      }
    });
  }

  ngOnInit(): void {
    this.scrollBar()
  }

  scrollBar() {
    let el: number = document.getElementById('palm').offsetWidth

    if(this.router.url.includes('historia')) {
      $('.hRow2').scrollLeft(0)
    }
    if(this.router.url.includes('palmares')) {
      $('.hRow2').scrollLeft(el)
    }
    if(this.router.url.includes('presidente')) {
      $('.hRow2').scrollLeft(el*2)
    }
    if(this.router.url.includes('estrutura')) {
      $('.hRow2').scrollLeft(el*3)
    }
    if(this.router.url.includes('estadio')) {
      $('.hRow2').scrollLeft(el*4)
    }
    if(this.router.url.includes('academia')) {
      $('.hRow2').scrollLeft(el*5)
    }
  }
}

