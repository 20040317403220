import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { jogadorFormacao } from 'src/app/interfaces/jogadores';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-petizes',
  templateUrl: './petizes.component.html',
  styleUrls: ['./petizes.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class PetizesComponent implements OnInit {

  //noticias petizes id = 9

  sub: Subscription[] = []
  petizes: jogadorFormacao[] = []
  staff: jogadorFormacao[]

  constructor(
    private data: DataService,
    public state: StateService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0)
    this.sub.push(this.data.getPetizes().subscribe(res => {

     this.petizes = res.filter(res => !res.staff)
      this.staff = res.filter(res => res.staff)
    }))

    this.getNoticias()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNoticias() {
    this.sub.push(this.data.getNoticiasFiltradas(9).subscribe(res => {

    }))
  }



}
