<app-equipa-principal-banner></app-equipa-principal-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading">

    <!--  TREINADORES  -->
   <div class="container-fluid py-5 bg1">
        <div class="row">
            <div class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">EQUIPA TÉCNICA</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">EQUIPA TÉCNICA</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let trei of treinadores">
                <div class="row imgFit">
                    <div class="col-6 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{trei.nome | uppercase}}</p>
                        <p class="posJog m-0">{{trei.funcao | uppercase}}</p>
                    </div>
                    <div class="col-6 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" src="assets/img/staff/{{trei.foto}}">
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center" *ngFor="let trei of treinadores">
                        <div class="bgJogador">
                            <img class="img-fluid" src="assets/img/staff/{{trei.foto}}" alt="">
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0 pb-2">{{trei.nome | uppercase}}</p>
                            </div>
                            <div class="tecnico-label shadow">
                                <p class="tecnico-nome m-0">{{trei.funcao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>

        <!--  medicos  -->
     <div class="container-fluid py-5">
        <div class="row">
            <div class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions text-center">DEPARTAMENTO<br> MÉDICO</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">DEPARTAMENTO MÉDICO</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let med of dep_medico">
                <div class="row imgFit">
                    <div class="col-6 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{med.nome | uppercase}}</p>
                        <p class="posJog m-0">{{med.funcao | uppercase}}</p>
                    </div>
                    <div class="col-6 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" src="assets/img/staff/{{med.foto}}">
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
             <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center" *ngFor="let med of dep_medico">
                        <div class="bgJogador">
                            <img class="img-fluid" src="assets/img/staff/{{med.foto}}" alt="">
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0 pb-2">{{med.nome | uppercase}}</p>
                            </div>
                            <div class="tecnico-label shadow">
                                <p class="tecnico-nome m-0">{{med.funcao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--  SCOUTING  -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions text-center">DIREÇÃO<BR>DESPORTIVA</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">DIREÇÃO DESPORTIVA</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let scout of scouting">
                <div class="row imgFit">
                    <div class="col-6 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{scout.nome | uppercase}}</p>
                        <p class="posJog m-0">{{scout.funcao | uppercase}}</p>
                    </div>
                    <div class="col-6 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" src="assets/img/staff/{{scout.foto}}">
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center" *ngFor="let scout of scouting">
                        <div class="bgJogador">
                            <img class="img-fluid" src="assets/img/staff/{{scout.foto}}" alt="">
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0 pb-2">{{scout.nome | uppercase}}</p>
                            </div>
                            <div class="tecnico-label shadow">
                                <p class="tecnico-nome m-0">{{scout.funcao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <!--  logistica-->
 <div class="container-fluid py-5">
        <div class="row">
            <div class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions text-center">LOGÍSTICA</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">LOGÍSTICA</p>
            </div>
            <!-- MOBILE -->
           <div class="container d-flex flex-column d-md-none px-3" *ngFor="let log of logistica">
                <div class="row imgFit">
                    <div class="col-6 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{log.nome | uppercase}}</p>
                        <p class="posJog m-0">{{log.funcao | uppercase}}</p>
                    </div>
                    <div class="col-6 cropImg d-flex flex-column position-relative">
                        <img class="img-fluid position-absolute positionFixedJogador w-75" src="assets/img/staff/{{log.foto}}">
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-lg-5">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center" *ngFor="let log of logistica">
                        <div class="bgJogador">
                            <img class="img-fluid" src="assets/img/staff/{{log.foto}}" alt="">
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0 pb-2">{{log.nome | uppercase}}</p>
                            </div>
                            <div class="tecnico-label shadow">
                                <p class="tecnico-nome m-0">{{log.funcao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
</div>
