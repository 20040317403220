
<div class="container-fluid bg1 bg-remove">
    <!-- Imagem Notícia Mobile -->
    <div class="row d-block d-lg-none">
        <div class="col-12 p-0">
            <img class="img-fluid w-100 ptImg" src="assets/img/noticias/{{noticia?.imagem_noticia}}">
        </div>
    </div>
    <!-- Fim Imagem Notícia Mobile -->

    <div class="container bg2 pr-md-0 pl-md-0">
        <div class="row">
            <div class="col-md-8 offset-md-2 mt-lg-5 hr-newsmobile">
                <button class="btn btn--skew btn-default yellow-button">
                    <div class="button">{{noticia.escalao}}</div>
                </button>
                <h2 class="titulos text-left mt-0 text-uppercase">{{noticia.titulo}}</h2>
                <p class="data">{{noticia.data}}</p>
            </div>
        </div>
        <!-- Imagem Notícia Desktop -->
        <div class="row mt-3 mt-lg-4 d-none d-lg-block">
            <div class="col-12 ">
                <img class="img-fluid w-100" src="assets/img/noticias/{{noticia?.imagem_noticia}}">
            </div>
        </div>
        <!-- Fim Imagem Notícia Desktop -->

        <div class="row">
            <div class="col-md-8 offset-md-2 content-wrap">
                <p class="destaque">{{noticia.subtitulo}}</p>
                <div class="corpoNoticia" [innerHTML]=noticia.descricao></div>
            </div>
        </div>

        <div class="row" *ngIf="noticia.video != null">
            <div class="col-12 center">
                <iframe width="100%" height="415" [src]="urlSafe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

       <!--  <div class="row">
            <div class="col-md-8 offset-md-2 hr-partilhas">
                <div class="fix-position-partilhas">
                    <span class="partilha">PARTILHAR: </span>
                    <a href="#" class="socialmedia-footer"><i class="fa fa-facebook-square mr-3"></i></a>
                    <a href="#" class="socialmedia-footer"><i class="fa fa-twitter mr-3"></i></a>
                    <a href="#" class="socialmedia-footer"><i class="fa fa-link mr-3"></i></a>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="container-fluid bg1 pb-5">
    <div class="container content-wrap">
        <div class="row">
            <div class="col-12">
                <h2 class="titulos text-line">ÚLTIMAS NOTÍCIAS</h2>
            </div>
        </div>

            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let ultima of ultimasNoticias; let i = index">
                    <a [routerLink]="['/noticia', ultima.noticia]" class="new_hm">
                        <h4 class="tag-noticia">{{ultima.escalao}}</h4>
                        <h3 class="titulo-noticia">{{ultima.titulo}}</h3>
                        <div class="position-linknew">
                          <a class="button arrow button-news">Ler Notícia</a>
                        </div>
                        <div class="filter-news img-new">
                          <img class="img-fluid" src="assets/img/noticias/{{ultima.imagem_listagem}}">
                        </div>
                        </a>
                </ng-template>
            </owl-carousel-o>  
    </div>
</div> 