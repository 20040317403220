import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-esports-header',
  templateUrl: './esports-header.component.html',
  styleUrls: ['./esports-header.component.scss']
})
export class EsportsHeaderComponent implements OnInit {

  title: string = ''

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        let url = e.urlAfterRedirects

        if(url.includes('proclubs')) this.title = 'proclubs'
        if(url.includes('1v1')) this.title = '1v1'
        if(url.includes('staff')) this.title = 'staff'
        if(url.includes('stream')) this.title = 'stream'
      }
    })
  }

  ngOnInit(): void {
  }

}
