<app-clube-header></app-clube-header>

<app-clube-navbar></app-clube-navbar>

<div class="container-fluid bg1 pt-5 pb-3">
    <div class="container">
        <p class="titleEst text-uppercase">academia cdt</p>
        <p class="descEst text-justify pt-3">
            O nosso clube tem dado, ao longo dos últimos anos, passos seguros e sempre em frente no que toca aos escalões de formação e no que toca a resultados desportivos, escolares e na ligação ao futebol profissional.
O foco/missão de formar atletas com competências para a equipa sénior, sempre a par com as aptidões académicas e sociais das crianças e jovens, vai em breve "ganhar" um novo reforço - a Academia CDT.</p> 
<p class="descEst text-justify pt-3">
Um espaço construído de raiz, inteiramente pensado para as nossas equipas de formação, onde terão instalações próprias para treinar, jogar e alguns poderem residir.
        </p>
        <p class="highlight text-uppercase py-3">O futuro do nosso clube está a chegar!</p>
    </div>
</div>