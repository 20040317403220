import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { noticias } from 'src/app/interfaces/noticias';
import { DataService } from 'src/app/services/data.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-noticias-filtradas',
  templateUrl: './noticias-filtradas.component.html',
  styleUrls: ['./noticias-filtradas.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class NoticiasFiltradasComponent implements OnInit, OnDestroy {

  idNews: number = 0
  sub: Subscription[] = []
  news: noticias[] = []

  constructor(
    public state: StateService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.router.url.includes('equipa-principal')) this.idNews = 1
    else if(this.router.url.includes('sub-19')) this.idNews = 2
    else if(this.router.url.includes('sub-17')) this.idNews = 3
    else if(this.router.url.includes('sub-15')) this.idNews = 4
    else if(this.router.url.includes('sub-14')) this.idNews = 6
    else if(this.router.url.includes('sub-13')) this.idNews = 5
    else if(this.router.url.includes('sub-11')) this.idNews = 7

    this.getNews()
  }

  ngOnDestroy(): void {
    this.sub.forEach((sub: any) => { sub.unsubscribe;})
  }

  getNews() {
    this.sub.push(this.dataService.getNoticiasFiltradas(this.idNews).subscribe(res => {
      this.news = res
    }))
  }

}
