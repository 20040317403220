<app-formacao-banner></app-formacao-banner>
<app-futebol-navbar></app-futebol-navbar>
<app-spinner *ngIf="state.loading"></app-spinner>
<div @fade *ngIf="!state.loading">

    <!-- Guarda-Redes -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div class="col-lg-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">GUARDA-REDES</p>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">GUARDA-REDES</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let gr of guardaRedes">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{gr.nome | uppercase}}</p>
                        <p class="posJog m-0">{{gr.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img 
                            class="img-fluid position-absolute positionFixedJogador w-75" 
                            alt="{{gr.nome}}"
                            [src]="gr.imagem"
                            onerror="this.src='assets/img/jogadores/no-photo.png'"
                        >
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center" *ngFor="let gr of guardaRedes">
                        <div class="bgJogador" id="jogador{{gr.id}}">
                            <img 
                                class="img-fluid" 
                                alt="{{gr.nome}}" 
                                [src]="gr.imagem"
                                onerror="this.src='assets/img/jogadores/no-photo.png'"
                            >
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{gr.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0" [ngClass]="gr.numero ? 'align-name-number' : ''">{{gr.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- DEFESAS -->
    <div class="container-fluid py-5">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">DEFESAS</p>
            </div>

            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">DEFESAS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let def of defesas">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{def.nome | uppercase}}</p>
                        <p class="posJog m-0">{{def.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img 
                            class="img-fluid position-absolute positionFixedJogador w-75" 
                            alt="{{def.nome}}"
                            [src]="def.imagem"
                            onerror="this.src='assets/img/jogadores/no-photo.png'"
                        >
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center mb-4" *ngFor="let def of defesas">
                        <div class="bgJogador" id="jogador{{def.id}}">
                            <img 
                                class="img-fluid" 
                                alt="{{def.nome}}"
                                [src]="def.imagem"
                                onerror="this.src='assets/img/jogadores/no-photo.png'"
                            />
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{def.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0" [ngClass]="def.numero ? 'align-name-number' : ''">{{def.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MÉDIOS -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">MÉDIOS</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">MÉDIOS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let med of medios">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{med.nome | uppercase}}</p>
                        <p class="posJog m-0">{{med.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img 
                            class="img-fluid position-absolute positionFixedJogador w-75" 
                            alt="{{med.nome}}"
                            [src]="med.imagem"
                            onerror="this.src='assets/img/jogadores/no-photo.png'"
                        >
                        <p class="numJog">{{med.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center  mb-4" *ngFor="let med of medios">
                        <div class="bgJogador" id="jogador{{med.id}}">
                            <img 
                                class="img-fluid" 
                                alt="{{med.nome}}" 
                                [src]="med.imagem"
                                onerror="this.src='assets/img/jogadores/no-photo.png'"
                            >
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{med.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0" [ngClass]="med.numero ? 'align-name-number' : ''">{{med.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- AVANÇADOS -->
    <div class="container-fluid py-5">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">AVANÇADOS</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">AVANÇADOS</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let ata of avancados">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{ata.nome | uppercase}}</p>
                        <p class="posJog m-0">{{ata.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img 
                            class="img-fluid position-absolute positionFixedJogador w-75" 
                            alt="{{ata.nome}}"
                            [src]="ata.imagem"
                            onerror="this.src='assets/img/jogadores/no-photo.png'"
                        >
                        <p class="numJog">{{ata.numero}}</p>
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center  mb-4" *ngFor="let ata of avancados">
                        <div class="bgJogador" id="jogador{{ata.id}}">
                            <img 
                                class="img-fluid" 
                                alt="{{ata.nome}}" 
                                [src]="ata.imagem"
                                onerror="this.src='assets/img/jogadores/no-photo.png'"
                            >
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <div class="player-item-number">
                                    <span>{{ata.numero}}</span>
                                </div>
                                <p class="text-center player-item-name mb-0" [ngClass]="ata.numero ? 'align-name-number' : ''">{{ata.nome | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


     <!-- Staff -->
    <div class="container-fluid py-5 bg1">
        <div class="row">
            <div
                class="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-end remove-pos">
                <p class="labelPositions">STAFF</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center d-block d-lg-none">
                <p class="labelPositions">STAFF</p>
            </div>
            <!-- MOBILE -->
            <div class="container d-flex flex-column d-md-none px-3" *ngFor="let staff_list of staff">
                <div class="row imgFit">
                    <div class="col-5 d-flex flex-column justify-content-center">
                        <p class="namJog m-0">{{staff_list.nome | uppercase}}</p>
                        <p class="posJog m-0">{{staff_list.posicao | uppercase}}</p>
                    </div>
                    <div class="col-7 cropImg d-flex flex-column position-relative">
                        <img 
                            class="img-fluid position-absolute positionFixedJogador w-75" 
                            alt="{{staff_list.nome}}"
                            [src]="staff_list.imagem"
                            onerror="this.src='assets/img/jogadores/no-photo.png'"
                        >
                    </div>
                </div>
            </div>
            <!-- DESKTOP -->
            <div class="col-lg-8 d-none d-md-flex flex-column">
                <div class="row d-flex justify-content-center ml-xl-5">
                    <div class="col-md-6 col-lg-6 col-xl-4 text-center  mb-4" *ngFor="let staff_list of staff">
                        
                        <div class="bgJogador position-relative" id="jogador{{staff_list.id}}">
                            <img 
                                class="img-fluid" 
                                alt="{{staff_list.nome}}" 
                                [src]="staff_list.imagem"
                                onerror="this.src='assets/img/jogadores/no-photo.png'"
                            >
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="player-item-info d-flex align-items-center p-0">
                                <p class="text-center player-item-name mb-0">{{staff_list.nome | uppercase}}</p>
                            </div>
                            <div class="tecnico-label shadow">
                                <p class="tecnico-nome m-0">{{staff_list.posicao | uppercase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>