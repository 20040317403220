
<div class="row smart-scroll d-flex d-md-none align-items-center" [ngClass]="bg">
  <div class="col-8 d-flex align-items-center">
    <div class="{{cdtLogo}}" width="45px" height="50px" [routerLink]="['/']"></div>
  </div>
  <div class="col-4 d-flex justify-content-end">
    <img src="assets/img/menu.svg" width="25px" height="25px" alt="menu" class="mobile-buttons" (click)="state.sidebar = true; state.appear = true">
  </div>
</div>
<app-sidebar class="d-md-block" [ngClass]="state.sidebar ? '' : 'd-none'"></app-sidebar>
<div class="fix-sizesidebar">
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<div *ngIf="showBtn" class="button-top d-flex justify-content-center align-items-center" (click)="pageTop()">
  <svg class="arrow-scrolltop" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14.5 14.5"
    style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve">
    <path d="M14.3,10.3c0,0.3-0.1,0.5-0.3,0.7c-0.4,0.4-1,0.4-1.4,0L7.3,5.7L2,11c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l6-6
            	c0.4-0.4,1-0.4,1.4,0l6,6C14.2,9.8,14.3,10,14.3,10.3z" />
  </svg>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{notificationData.title}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert" *ngIf="notificationData.type == 'error'">
      {{notificationData.message}}
    </div>
    <div class="alert alert-success" role="alert" *ngIf="notificationData.type == 'success'">
      {{notificationData.message}}
    </div>
  </div>
</ng-template>


<app-popup-init></app-popup-init>
