<div class="container-fluid bg1 border-bottom">
    <div class="container">
        <div class="row hRow2" id="barra">
            <div id="tenis" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/tenis']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">TÉNIS</span>
            </div>
            <div id="basq" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/basquetebol']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">BASQUETEBOL</span>
            </div>
            <div id="atl" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/atletismo']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">ATLETISMO</span>
            </div>
            <div id="hip" class="col-8 col-md-2 col-lg-2 border-left outline"
                [routerLink]="['/modalidades/hipismo']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="tabs mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">HIPISMO</span>
            </div>
            <div id="vet" class="col-8 col-md-2 col-lg-2 border-left border-right outline"
                [routerLink]="['/modalidades/veteranos']" routerLinkActive="{{activeLink}}" [ngClass]="activeLink == 'active-link-2' ? 'centerTabs2' : 'centerTabs'">
                <span class="mx-auto" [ngClass]="activeLink == 'active-link-2' ? 'tabs2' : 'tabs'">VETERANOS</span>
            </div>
        </div>
    </div>
</div>
