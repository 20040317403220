import { trigger, transition, style, animate } from '@angular/animations';
import { UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DepartamentoTecnicosStaff, TecnicoStaff } from 'src/app/interfaces/equipa-tecnica';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-formacao-estrutura',
  templateUrl: './formacao-estrutura.component.html',
  styleUrls: ['./formacao-estrutura.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
    ])
  ]
})
export class FormacaoEstruturaComponent implements OnInit {

  public departamentos: Array<DepartamentoTecnicosStaff> = [];
  public loading: boolean = false;

  constructor(
    private dataService: DataService,
    private upperCasePipe: UpperCasePipe
  ) {
    this.loading = true;
    this.dataService.getFormacaoStaff().toPromise().then((tecnicos: Array<TecnicoStaff>) => {
      tecnicos = tecnicos.sort((a: TecnicoStaff, b: TecnicoStaff) => a.id < b.id ? -1 : 1);
      const departamentosUnique: Array<string> = tecnicos.map((tecnico: TecnicoStaff) => tecnico.departamento).filter((value, index, self) => self.indexOf(value) === index);
      this.departamentos = departamentosUnique.map((departamento: string) => { 
        const nomeUpper: string = this.upperCasePipe.transform(departamento);
        return {
          nome: nomeUpper,
          nomeInnerHTML: nomeUpper.replace(" ", "<BR>"),
          tecnicos: tecnicos.filter(tecnico => tecnico.departamento === departamento)
        }
      });
      this.loading = false;
    });
  }

  ngOnInit(): void {
  }
}
